import { styled } from '../../stitches.config';

export const IconButton = styled('button', {
  // Reset
  alignItems: 'center',
  appearance: 'none',
  borderWidth: '0',
  transition: 'all 0.2s linear',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexShrink: 0,
  fontFamily: 'inherit',
  fontSize: '1rem',
  justifyContent: 'center',
  lineHeight: '1',
  outline: 'none',
  borderRadius: '$full',
  padding: '$1',
  textDecoration: 'none',
  userSelect: 'none',
  WebkitTapHighlightColor: 'transparent',
  color: '$gray500',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },
  backgroundColor: '$ghostwhite',
  border: '1px solid $gray50',

  '&:active': {
    backgroundColor: '$gray100',
  },
  '&:focus': {
    borderColor: '$gray50',
    boxShadow: '0 0 0 1px $gray50',
  },
  '@hover': {
    '&:hover:not([disabled])': {
      filter: 'brightness(85%)',
      cursor: 'pointer',
    },
  },
  '&:disabled': {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.40)',
    border: '1px solid #d9d9d9',
    cursor: 'not-allowed',
  },

  variants: {
    size: {
      sm: {
        height: '$5',
        width: '$5',
      },
      md: {
        height: '$6',
        width: '$6',
      },
      lg: {
        height: '$7',
        width: '$7',
      },
      xl: {
        height: '$8',
        width: '$8',
      },
    },
    variant: {
      ghost: {
        backgroundColor: 'transparent',
        borderWidth: '0',
        '@hover': {
          '&:hover:not([disabled])': {
            backgroundColor: '$metal200',
          },
        },
      },
      raised: {
        boxShadow:
          '0 0 transparent, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
        '@hover': {
          '&:hover': {
            boxShadow:
              '0 0 transparent, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
          },
        },
        '&:focus': {
          borderColor: '$slate8',
          boxShadow:
            '0 0 0 1px $colors$slate8, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
        },
        '&:active': {
          backgroundColor: '$slate4',
        },
      },
    },
    // state: {
    //   active: {
    //     backgroundColor: "$slate4",
    //     boxShadow: "inset 0 0 0 1px hsl(206,10%,76%)",
    //     "@hover": {
    //       "&:hover": {
    //         boxShadow: "inset 0 0 0 1px hsl(206,10%,76%)",
    //       },
    //     },
    //     "&:active": {
    //       backgroundColor: "$slate4",
    //     },
    //   },
    //   waiting: {
    //     backgroundColor: "$slate4",
    //     boxShadow: "inset 0 0 0 1px hsl(206,10%,76%)",
    //     "@hover": {
    //       "&:hover": {
    //         boxShadow: "inset 0 0 0 1px hsl(206,10%,76%)",
    //       },
    //     },
    //     "&:active": {
    //       backgroundColor: "$slate4",
    //     },
    //   },
    // },
  },
  defaultVariants: {
    size: 'md',
    variant: 'ghost',
  },
});
