import { useFormContext } from 'react-hook-form';
import Modal from '../../../../../components/modal/Modal';
import cancelImage from '../../../../../../assets/images/cancel-session.png';
import { Flex, Typography } from '../../../../../styledComponents';
import { useCancelMeeting } from '../../../../../../utilities/hooks/queryHooks/caseNotes/UseCancelMeeting';
import { CaseNoteFormTypes } from '../../../../../../shared/types/CaseNoteForm';
import useTracking from '../../../../../../utilities/hooks/UseTracking';
import { CaseNoteFormEvent } from '../../../../../../shared/constants/events/CaseNoteForm';

const MeetingCancellationModal = ({
  open,
  meetingId,
  handleClose,
}: {
  open: boolean;
  meetingId: string;
  handleClose: () => void;
}) => {
  const { watch, setValue } = useFormContext<CaseNoteFormTypes>();
  const { track } = useTracking<CaseNoteFormEvent>();

  const onCancelMeetingSuccess = () => {
    const existingMeetingData = watch('meetingData.data.meeting');
    const newMeetingData = existingMeetingData.filter(
      ({ id }) => id !== meetingId,
    );
    if (newMeetingData.length === 0) {
      setValue('meetingData', null);
    } else {
      setValue('meetingData.data.meeting', newMeetingData);
    }
    handleClose();
  };

  const cancelMeeting = useCancelMeeting(onCancelMeetingSuccess);

  const confirmCancel = () => {
    const meetingData = watch('meetingData');
    if (meetingData && meetingData.data) {
      track('remove_booking', {
        eventAction: 'click',
        eventCategory: 'remove_booking_confirm',
        eventLabel: 'remove_booking_confirm',
      });
      cancelMeeting.mutate({
        meetingId,
        userId: watch('userId'),
      });
    }
  };
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleSubmit={confirmCancel}
      title="Remove this booking?"
      confirmButtonText="Remove"
      confirmButtonColor="red"
    >
      <Flex align="center" justify="center" direction="column" gap="5">
        <img src={cancelImage} alt="cancel-confirm-x" width="150" />
        <Typography size="lg">
          Are you sure you want to continue? You will have to book again.
        </Typography>
      </Flex>
    </Modal>
  );
};

export default MeetingCancellationModal;
