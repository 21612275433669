export const productionHostname = 'helpline.intellect.co';

export const gotoRoute = (path: string) => {
  window.location.href = path;
};

export const refreshApp = () => {
  window.location.reload();
};

export const openNewTab = (path: string) => {
  setTimeout(() => {
    window.open(`${window.location.href}${path}`);
  });
};

export const isProduction = () =>
  window.location.hostname === productionHostname;
