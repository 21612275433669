import { Link } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { CommonEvent } from '../../../shared/constants/events/Common';
import useTracking from '../../../utilities/hooks/UseTracking';
import Container from '../../components/container/Container';
import { Button, Flex, Heading } from '../../styledComponents';

export const ResponderHome = () => {
  const { track } = useTracking<CommonEvent>();
  return (
    <Container>
      <Flex
        align="center"
        direction="column"
        css={{ height: '50vh', width: '100%' }}
      >
        <Heading
          size="lg"
          css={{
            m: '$7 0 $3',
          }}
        >
          Record your client's thoughts
        </Heading>
        <Heading
          size="md"
          color="gray500"
          css={{
            maxWidth: '50%',
            textAlign: 'center',
            fontWeight: '400',
            mb: '$5',
          }}
        >
          Start adding session case notes and suggest wellbeing measures to your
          clients
        </Heading>
        <Link
          to={`${PathConfig.responder}/${PathConfig.newCaseNote}`}
          onClick={() =>
            track('add_new_case', {
              eventAction: 'click',
              eventCategory: 'add_new_case',
              eventLabel: 'add_new_case',
            })
          }
        >
          <Button id="start-session" size="lg">
            Add new case notes
          </Button>
        </Link>
      </Flex>
    </Container>
  );
};
