import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { forwardRef } from 'react';
import { CSS, styled, VariantProps } from '../../stitches.config';

export const RadioGroup = styled(RadioGroupPrimitive.Root, {
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

const RadioWrapper = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: 'white',
  width: 25,
  height: 25,
  borderRadius: '100%',
  boxShadow: '0 2px 10px $gray900',
  border: '1px solid $gray300',
  '&:hover': { backgroundColor: '$gray200' },
  '&:focus': { boxShadow: '0 0 0 2px black' },
});

const RadioGroupIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    width: 11,
    height: 11,
    borderRadius: '50%',
    backgroundColor: '$gray900',
  },
});

type RadioPrimitiveProps = React.ComponentProps<
  typeof RadioGroupPrimitive.Item
>;
type RadioVariants = VariantProps<typeof RadioWrapper>;
type RadioProps = RadioPrimitiveProps & RadioVariants & { css?: CSS };

export const RadioGroupItem = forwardRef<
  React.ElementRef<typeof RadioWrapper>,
  RadioProps
>((props, forwardedRef) => (
  <RadioWrapper {...props} ref={forwardedRef}>
    <RadioGroupIndicator />
  </RadioWrapper>
));
