import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { RiskColorVariants } from '../../../../../shared/constants/Risk';
import { CaseNoteFormTypes } from '../../../../../shared/types/CaseNoteForm';
import { AssessmentPayload } from '../../../../../shared/types/Session';
import { useGetAssessmentQuestions } from '../../../../../utilities/hooks/queryHooks/session/UseGetAssessmentQuestions';
import {
  Flex,
  Tag,
  Typography,
  Heading,
  Label,
  Box,
  Checkbox,
} from '../../../../styledComponents';

export const AssessmentQuestionViewer = () => {
  const { watch, setValue } = useFormContext<CaseNoteFormTypes>();

  const riskId = watch('risk');

  const { data: assessmentQuestions, isSuccess } =
    useGetAssessmentQuestions(riskId);

  useEffect(() => {
    if (assessmentQuestions) {
      setValue(
        'assessment',
        assessmentQuestions.map((question) => ({
          questionId: question.id,
          response: question.type === 'checkbox' ? false : '',
          isRequired: question.isRequired,
        })),
      );
    }
  }, [assessmentQuestions, setValue, riskId]);

  return (
    <>
      <Flex
        css={{
          border: '1px solid $gray300',
          borderRadius: '$3',
          backgroundColor: 'white',
          padding: '$5',
          marginTop: '$2',
        }}
        align="center"
        gap="6"
      >
        <Tag variant={RiskColorVariants[riskId]} css={{ p: '$5 $3', br: '$2' }}>
          <HiOutlineSpeakerphone size={24} />
        </Tag>
        <Typography size="md">
          Inform your supervisor about the client's condition
        </Typography>
      </Flex>
      <Box css={{ px: '$3', mt: '$5' }}>
        <Heading size="sm" css={{ fontWeight: 'bold' }}>
          To continue, please confirm the following.
        </Heading>
      </Box>
      <Box css={{ px: '$7' }}>
        {!!watch('assessment') &&
          isSuccess &&
          watch('assessment').map(
            (question: AssessmentPayload, index: number) =>
              assessmentQuestions?.[index]?.type === 'checkbox' ? (
                <Controller
                  key={question.questionId}
                  name="assessment"
                  defaultValue={watch('assessment')[index].response}
                  render={({ field: { onChange, value } }) => (
                    <Flex gap="2" align="center" css={{ my: '$5' }}>
                      <Checkbox
                        onClick={() =>
                          onChange([
                            ...value.slice(0, index),
                            {
                              ...value[index],
                              response: !value[index].response,
                            },
                            ...value.slice(index + 1),
                          ])
                        }
                        checked={value[index].response}
                        id={`assessment-${question.questionId}`}
                      />
                      <Label
                        htmlFor={`assessment-${question.questionId}`}
                        size="md"
                      >
                        {assessmentQuestions?.[index]?.question ??
                          'Assessment question unavailable'}{' '}
                        {assessmentQuestions?.[index]?.isRequired ? (
                          <span style={{ color: 'red' }}>*</span>
                        ) : null}
                      </Label>
                    </Flex>
                  )}
                />
              ) : null,
          )}
      </Box>
    </>
  );
};
