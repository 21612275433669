import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IoCloseOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { PathConfig } from '../../../../config/PathConfig';
import { AnimationSpeeds } from '../../../../shared/constants/Animations';
import { CareNavCallReasons } from '../../../../shared/constants/CareNav';
import { CareNavForm } from '../../../../shared/types/CareNavForm';
import { useUpdateSession } from '../../../../utilities/hooks/queryHooks/caseNotes/UseUpdateSession';
import FileDropZone from '../../../components/fileDropZone/FileDropZone';
import FormSection from '../../../components/formSection/FormSection';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Label,
  Select,
  TextArea,
  Tooltip,
  Typography,
} from '../../../styledComponents';

const MotionBox = motion(Box);

const CareNavNote = () => {
  const navigate = useNavigate();
  const [note, setNote] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CareNavForm>();

  const { mutate: updateSession } = useUpdateSession(() => {
    navigate(PathConfig.careNavigator);
  });

  const saveCareNavNotes = (status: any) => {
    const [id, sessionId] = watch(['id', 'sessionId']);
    if (id && sessionId) {
      updateSession({
        status,
        clientId: id,
        sessionId,
        sessionType: '1',
        issue: watch('reason'),
        caseNotes: note,
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString(),
        risk: 4,
        meetingConsent: 'no',
        file: files?.[0],
      });
    }
  };

  return (
    <FormSection title="Notes" subtitle="" defaultOpen>
      <Flex direction="column" gap="2">
        <Label htmlFor="reason">
          Call reason
          <Controller
            name="reason"
            defaultValue={watch('reason')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                options={CareNavCallReasons.map((r) => ({
                  label: r,
                  value: r,
                }))}
                selected={value}
                onChange={onChange}
                {...rest}
              />
            )}
          />
          <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
            {errors.reason?.message?.toString()}
          </Typography>
        </Label>

        <Label htmlFor="Note">
          Note
          <TextArea
            css={{ mt: '$2' }}
            id="care-nav-notes"
            value={note}
            onChange={(event: any) => setNote(event.target.value)}
          />
        </Label>
        <Label htmlFor="Attachment">
          Attachment (Optional)
          <AnimatePresence>
            {!files?.length && (
              <MotionBox
                key="file-dropzone"
                initial={{
                  marginTop: 0,
                  height: 0,
                  opacity: 0,
                }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                  transition: {
                    opacity: {
                      duration: AnimationSpeeds.Medium,
                      delay: 0.3,
                    },
                  },
                }}
                exit={{
                  marginTop: 0,
                  height: 0,
                  opacity: 0,
                  transition: {
                    height: {
                      duration: AnimationSpeeds.Fast,
                      delay: 0.2,
                    },
                    opacity: {
                      duration: AnimationSpeeds.Fast,
                    },
                  },
                }}
              >
                <FileDropZone
                  files={files}
                  setFiles={setFiles}
                  css={{ mt: '$2' }}
                  accept={{
                    'image/jpeg': ['.jpeg', '.png'],
                    'application/pdf': ['.pdf'],
                  }}
                  maxSize={5000000}
                  maxFiles={1}
                  subTitle="Supported files: JPGs,PNGs, PDFs (5MB max)"
                />
              </MotionBox>
            )}
            {files?.length && (
              <Flex justify="between" align="center">
                <Typography>{files?.[0]?.name}</Typography>
                <Tooltip label="Remove file">
                  <IconButton type="button" onClick={() => setFiles([])}>
                    <IoCloseOutline size={18} />
                  </IconButton>
                </Tooltip>
              </Flex>
            )}
          </AnimatePresence>
        </Label>
        {watch('reason') ? (
          <Flex
            direction="column"
            align="center"
            css={{
              width: '100%',
              mt: '$5',
            }}
            gap="3"
          >
            <Button size="lg" onClick={() => saveCareNavNotes('1')}>
              Mark as complete
            </Button>
            <Button
              variant="outlined"
              size="lg"
              onClick={() => saveCareNavNotes('0')}
            >
              Save as draft
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </FormSection>
  );
};

export default CareNavNote;
