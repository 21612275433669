import { AnimatePresence } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { IoCloseOutline, IoSyncOutline } from 'react-icons/io5';
import {
  NewEmailUser,
  ExistingEmailUser,
} from '../../../../../shared/types/FetchByEmailUserData';
import { CaseNoteFormTypes } from '../../../../../shared/types/CaseNoteForm';
import { emailRegex } from '../../../../../utilities/common/FormValidatorRegex';
import { useGetUserFromEmail } from '../../../../../utilities/hooks/queryHooks/caseNotes/UseGetUserFromEmail';
import { Label, Button, Input, IconButton } from '../../../../styledComponents';
import { OrgMessageHandler } from './OrgMessageHandler';

const EmailInputV2 = ({ handleReset }: { handleReset: () => void }) => {
  const {
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext<CaseNoteFormTypes>();

  const onUserFetchSuccess = (
    fetchedData: NewEmailUser | ExistingEmailUser,
  ) => {
    if (fetchedData.message.length) {
      setValue('orgMessageHandlerActive', true);
    }
  };

  const {
    data: fetchedUserData,
    isFetching,
    refetch,
  } = useGetUserFromEmail({
    email: watch('email'),
    organisation: watch('organisation'),
    onSuccess: onUserFetchSuccess,
  });

  return (
    <>
      <Label htmlFor="email">
        Email{!watch('clientAnonymous') && ' *'}
        <Input
          defaultValue={watch('email')}
          css={{ margin: 0, flexGrow: 1, marginBottom: '$2' }}
          data-cy="email-input"
          id="email"
          disabled={watch([
            'orgMessageHandlerActive',
            'orgSelected',
            'id',
          ]).some(Boolean)}
          {...register('email', {
            required: !watch('clientAnonymous'),
            pattern: {
              value: emailRegex,
              message: 'Please enter a valid email.',
            },
          })}
          helperText={
            errors.email?.type === 'required'
              ? 'This is required.'
              : errors.email?.message?.toString()
          }
          endElement={
            watch('orgSelected') ? (
              <IconButton
                id="clear-org"
                type="button"
                onClick={handleReset}
                size="sm"
              >
                <IoCloseOutline />
              </IconButton>
            ) : (
              <Button
                id="fetch-by-email"
                variant="ghost"
                size="sm"
                startIcon={<IoSyncOutline />}
                isLoading={isFetching}
                disabled={
                  !!errors.email ||
                  !watch(['email', 'organisation']).every(Boolean) ||
                  !!watch('id') ||
                  watch('orgMessageHandlerActive')
                }
                type="button"
                onClick={() => refetch()}
              >
                Fetch details
              </Button>
            )
          }
        />
      </Label>
      <AnimatePresence>
        {watch('orgMessageHandlerActive') && fetchedUserData ? (
          <OrgMessageHandler fetchedData={fetchedUserData} />
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default EmailInputV2;
