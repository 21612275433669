import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IoSearchOutline } from 'react-icons/io5';
import { rowsPerPageOptions } from '../../../shared/constants/CallLogs';
import { RiskLabels, RiskLevels } from '../../../shared/constants/Risk';
import { CallLogsFilterTypes } from '../../../shared/types/CallLogs';
import { convertIntoComboboxOptions } from '../../../utilities/common/ConvertIntoOptions';
import { SessionsListResponse } from '../../../utilities/hooks/queryHooks/callLogs/useGetSessionsListV2';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import LoadingView from '../../components/loadingView/LoadingView';
import Pagination from '../../components/pagination/Pagination';
import {
  Combobox,
  Flex,
  Grid,
  Input,
  Label,
  Select,
  Switch,
  Typography,
} from '../../styledComponents';

export const CallLogFilters = ({
  sessionsData,
}: {
  sessionsData?: SessionsListResponse;
}) => {
  const {
    data: orgsList,
    dataUpdatedAt,
    isLoading,
  } = useGetOrganisationsList();
  const { watch, control, register, reset, setValue } =
    useFormContext<CallLogsFilterTypes>();

  const handleAnonymousChange = (newValue: boolean) => {
    reset({
      ...watch(),
      anonymous: newValue,
      organisation: newValue ? -1 : undefined,
      email: '',
    });
  };

  useEffect(
    () => {
      setValue('page', 1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...watch(['organisation', 'email', 'risk', 'pageSize']), setValue],
  );

  return (
    <Grid columns="9" gapX="3" align="center" css={{ mb: '$3' }}>
      <Label htmlFor="organisation" css={{ gridColumn: '1 / span 3' }}>
        Organisation
        {isLoading || orgsList ? (
          <Controller
            name="organisation"
            defaultValue={watch('organisation')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Combobox
                /**  Temporary solution to re render once value changes
                 *  Have to find out way to clear inputValue of
                 * combobox when value changes
                 */
                key={`${dataUpdatedAt} ${watch('anonymous')}`}
                placeholder="Select organisation"
                options={convertIntoComboboxOptions(
                  orgsList ?? [],
                  'name',
                  'id',
                )}
                selectedValue={value?.toString()}
                onOptionSelect={(newOption) =>
                  onChange(newOption ? parseInt(newOption, 10) : newOption)
                }
                disabled={watch('anonymous')}
                {...rest}
              />
            )}
          />
        ) : (
          <LoadingView />
        )}
      </Label>
      <Label htmlFor="email" css={{ gridColumn: '4 / span 3' }}>
        Email
        <Input
          {...register('email')}
          placeholder="Search emails"
          startElement={<IoSearchOutline size={20} />}
          defaultValue={watch('email') || ''}
          disabled={watch('anonymous')}
        />
      </Label>
      <Label htmlFor="risk" css={{ gridColumn: '7 / span 2', height: '100%' }}>
        Risk level
        <Controller
          name="risk"
          defaultValue={watch('risk')}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              multiple
              options={Object.entries(RiskLevels).map(([_label, riskValue]) => ({
                label: RiskLabels[riskValue],
                value: riskValue,
              }))}
              selected={value || []}
              onChange={onChange}
              {...rest}
            />
          )}
        />
      </Label>
      <Controller
        name="orgAnonymous"
        render={({ field: { value, onBlur } }) => (
          <Switch
            label="Anonymous"
            onBlur={onBlur}
            checked={value}
            onCheckedChange={(newValue) => handleAnonymousChange(newValue)}
          />
        )}
      />
      <Flex align="center" gap="1" css={{ gridColumn: '7 / span 2' }}>
        <Typography css={{ width: 'fit-content' }}>Results per page</Typography>
        <Controller
          name="pageSize"
          defaultValue={watch('pageSize')}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              options={rowsPerPageOptions.map((row) => ({
                label: row.toString(),
                value: row,
              }))}
              selected={value}
              onChange={onChange}
              {...rest}
            />
          )}
        />
      </Flex>
      <div style={{ gridColumn: '9 / span 3' }}>
        {sessionsData ? (
          <Pagination
            currentPage={parseInt(sessionsData.page, 10)}
            onPageChange={(newPage: number) => setValue('page', newPage)}
            pageSize={parseInt(sessionsData.pageSize, 10)}
            totalCount={sessionsData.total}
            siblingCount={1}
          />
        ) : null}
      </div>
    </Grid>
  );
};
