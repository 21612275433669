import { useQuery } from '@tanstack/react-query';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import { UserProvider } from '../../../../shared/types/CareNav';

export const useGetUserProviderData = (userId?: string) =>
  useQuery<UserProvider>(
    [QueryKeys.getUserProviderData, userId],
    () =>
      doGet(
        ApiConfig.api.sessions.getProviderSessionDetails.replace(
          '{{userId}}',
          userId || '',
        ),
      ).then((res) => res.data.data),
    {
      enabled: !!userId,
    },
  );
