export enum AnimationSpeeds {
  Fast = 0.25,
  Medium = 0.35,
  Slow = 0.5,
}

export const ContainerVariants = {
  defaultHidden: {
    opacity: 0,
  },
  hiddenX: {
    x: '100vw',
    opacity: 0,
  },
  hiddenY: {
    y: '100vh',
    opacity: 0,
  },
  slideX: {
    x: '0',
    opacity: 1,
    transition: { ease: 'easeIn', duration: AnimationSpeeds.Medium },
  },
  slideY: {
    y: '0',
    opacity: 1,
    transition: { ease: 'easeIn', duration: AnimationSpeeds.Medium },
  },
  defaultAnimate: {
    opacity: 1,
    transition: { ease: 'easeOut', duration: AnimationSpeeds.Medium },
  },
  exitX: {
    x: '100vw',
    opacity: 0,
    transition: { ease: 'easeOut', duration: AnimationSpeeds.Slow },
  },
  exitY: {
    y: '100vh',
    opacity: 0,
    transition: { ease: 'easeOut', duration: AnimationSpeeds.Slow },
  },
  defaultExit: {
    opacity: 0,
    transition: {
      duration: 0.15,
      type: 'tween',
    },
  },
};
