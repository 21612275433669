import { useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { TbPhonePlus } from 'react-icons/tb';
import { RiskColorVariants } from '../../../../../../shared/constants/Risk';
import { CaseNoteFormTypes } from '../../../../../../shared/types/CaseNoteForm';
import { useCreateClientAndSession } from '../../../../../../utilities/hooks/queryHooks/client/UseCreateClient';
import { useGetClientECP } from '../../../../../../utilities/hooks/queryHooks/client/UseGetAndCreateECP';
import ManageECPModal from './ManageECPModal';
import ViewECPModal from './ViewECPModal';
import {
  Flex,
  Tag,
  Typography,
  Button,
  Tooltip,
} from '../../../../../styledComponents';
import useTracking from '../../../../../../utilities/hooks/UseTracking';
import { CaseNoteFormEvent } from '../../../../../../shared/constants/events/CaseNoteForm';

export const ECPViewer = () => {
  const { watch } = useFormContext<CaseNoteFormTypes>();

  const { track } = useTracking<CaseNoteFormEvent>();

  const [showECPModal, setShowECPModal] = useState(false);

  const {
    data: ecpData,
    isLoading: isECPDataLoading,
    fetchStatus: ecpFetchStatus,
  } = useGetClientECP(watch('userId'));

  const {
    isLoading: isClientAndSessionCreateLoading,
    mutate: mutateClientAndSession,
  } = useCreateClientAndSession();

  const [
    name,
    location,
    organisation,
    email,
    phone,
    tenure,
    age,
    employeeId,
    riskId,
  ] = watch([
    'name',
    'location',
    'organisation',
    'email',
    'phone',
    'tenure',
    'age',
    'employeeId',
    'risk',
  ]);

  const nonNullValues = Object.fromEntries(
    Object.entries({
      name,
      email,
      phone,
      location,
      organisation,
      tenure,
      age,
      employeeId,
    }).filter(([_, v]) => !!v),
  );

  const canUserFetchECP = useMemo(
    () =>
      !!watch('userId') ||
      ['name', 'email', 'organisation', 'phone', 'tenure'].every(
        (key) => key in nonNullValues,
      ),
    [nonNullValues, watch],
  );

  const getECPButtonText = () => {
    if (!watch('userId') || (ecpData && !ecpData.length)) {
      return 'Add contact';
    }

    return 'View';
  };

  const onECPButtonClick = () => {
    if (!watch('userId')) {
      // User does not exist, will create client and session

      mutateClientAndSession(nonNullValues);
    }

    if (getECPButtonText() === 'View') {
      track('view_emergency_contact', {
        eventAction: 'click',
        eventCategory: 'view_emergency_contact',
        eventLabel: 'view_emergency_contact',
      });
    }

    setShowECPModal(true);
  };

  const getECPButtonTooltip = () => {
    if (!canUserFetchECP) {
      return 'Client information is necessary to fetch emergency contacts.';
    }

    if (!watch('userId') || (ecpData && !ecpData.length)) {
      return "Click to add the client's emergency contacts.";
    }

    return "Click to view the client's emergency contacts.";
  };

  return (
    <>
      <Flex
        css={{
          border: '1px solid $gray300',
          borderRadius: '$3',
          backgroundColor: 'white',
          padding: '$5',
          marginTop: '$2',
        }}
        align="center"
        gap="6"
      >
        <Tag variant={RiskColorVariants[riskId]} css={{ p: '$5 $3', br: '$2' }}>
          <TbPhonePlus size={24} />
        </Tag>

        <Flex align="center" gap="3" css={{ flexGrow: 1 }}>
          <Typography size="md">Client's emergency contact</Typography>

          {!watch('userId') || (ecpData && !ecpData.length) ? (
            <Tag variant="warning" shape="pill">
              Pending
            </Tag>
          ) : null}
        </Flex>
        <Tooltip label={getECPButtonTooltip()}>
          <Button
            variant="outlined"
            type="button"
            onClick={() => onECPButtonClick()}
            disabled={!canUserFetchECP}
            isLoading={
              isClientAndSessionCreateLoading ||
              (isECPDataLoading && ecpFetchStatus !== 'idle')
            }
            loadingText="Fetching..."
          >
            {getECPButtonText()}
          </Button>
        </Tooltip>
      </Flex>

      {ecpData ? (
        ecpData.length ? (
          <ViewECPModal
            ecpData={ecpData}
            handleClose={() => setShowECPModal(false)}
            open={showECPModal}
          />
        ) : (
          <ManageECPModal
            userId={watch('userId')}
            ecpData={ecpData}
            handleClose={() => setShowECPModal(false)}
            open={showECPModal}
          />
        )
      ) : null}
    </>
  );
};
