import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet, doPost } from '../../../../shared/service/NetworkClient';
import { ClientECP } from '../../../../shared/types/Client';

export const useGetClientECP = (userId: string | undefined) =>
  useQuery<ClientECP[]>(
    [QueryKeys.getClientECP, userId],
    () =>
      doGet(
        ApiConfig.api.clients.clientECP.replace('{{userId}}', userId || ''),
      ).then((res) => res.data.data),
    { enabled: !!userId },
  );

export const useCreateClientECP = (userId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (ecpData: Omit<ClientECP, 'userId'>[]) =>
      Promise.all(
        ecpData.map((data) =>
          doPost(
            ApiConfig.api.clients.clientECP.replace('{{userId}}', userId),
            data,
          ),
        ),
      ),
    {
      onSuccess: (_data, variables) => {
        onSuccess?.();
        queryClient.invalidateQueries([QueryKeys.getClientECP]);
        toast.success(
          `Emergency contact${variables.length > 1 ? 's have' : ' has'} been added successfully!`,
        );
      },
      onError: () => {
        toast.error('An error occurred while cancelling this meeting.');
      },
    },
  );
};
