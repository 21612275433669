import { IoAlertCircleOutline } from 'react-icons/io5';
import {
  CaseNoteFormMeetingData,
  ProviderMeetingDetails,
} from '../../../../../../shared/types/Session';
import {
  Typography,
  Heading,
  Flex,
  Box,
} from '../../../../../styledComponents';
import { MeetingCard } from './MeetingCard';
import f2fImage from '../../../../../../assets/images/f2fMeeting.png';

export const MeetingsList = ({
  meetingData,
  startMeetingCancel,
}: {
  meetingData: CaseNoteFormMeetingData;
  startMeetingCancel: (meetingId: string) => void;
}) => (
  <>
    <Typography size="lg" color="gray500" css={{ my: '$3' }}>
      Successfully booked a session with your client
    </Typography>

    {meetingData.type === 'virtual' ? (
      meetingData.data ? (
        <Flex direction="column" gap="2" css={{ mb: '$3' }}>
          {meetingData.data.meeting?.map((data) => (
            <MeetingCard
              cardData={data as ProviderMeetingDetails}
              startMeetingCancel={startMeetingCancel}
            />
          ))}
        </Flex>
      ) : (
        <Box
          css={{
            mb: '$3',
            border: '1px solid $gray300',
            borderRadius: '$3',
            backgroundColor: 'white',
            padding: '$5',
          }}
        >
          <Heading
            size="md"
            color="gray500"
            css={{ display: 'flex', alignItems: 'center', gap: '$3' }}
          >
            <IoAlertCircleOutline size={24} />
            Meeting data unavailable
          </Heading>
        </Box>
      )
    ) : (
      <Box
        css={{
          mt: '$4',
          border: '1px solid $gray300',
          borderRadius: '$3',
          backgroundColor: 'white',
          padding: '$5',
          marginTop: '$5',
        }}
      >
        <Flex gap="8" align="center">
          <img src={f2fImage} alt="f2f-meeting-clock" width="100" />
          <Box>
            <Heading color="gray900" size="lg">
              F2F session request raised
            </Heading>
            <Typography size="lg" color="gray500" css={{ mt: '$2' }}>
              It usually takes 3-5 business days for us to find the right
              provider for your client.
            </Typography>
          </Box>
        </Flex>
      </Box>
    )}
  </>
);
