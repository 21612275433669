import { useQuery } from '@tanstack/react-query';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { RiskLevels } from '../../../../shared/constants/Risk';
import { doGet } from '../../../../shared/service/NetworkClient';
import {
  AssessmentQuestion,
  CaseNoteSession,
} from '../../../../shared/types/Session';

export const useGetAssessmentQuestions = (riskId: CaseNoteSession['risk']) =>
  useQuery<AssessmentQuestion[]>(
    [QueryKeys.getAssessmentQuestions],
    () =>
      doGet(ApiConfig.api.sessions.getAssessmentQuestions).then(
        (res) => res.data.data,
      ),
    {
      select(data) {
        return data.filter((question) => question.risk === String(riskId));
      },
      enabled: [RiskLevels.High, RiskLevels.HighCritical].some(
        (r) => r === riskId,
      ),
    },
  );
