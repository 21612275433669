import { Toaster } from 'react-hot-toast';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './App.css';
import Login from './view/screens/login/Login';
import { ToasterStyles } from './config/ToastTheme';
import ProtectedLayout from './view/ProtectedLayout';
import CaseNoteFormBase from './view/screens/caseNotesForm/CaseNoteFormBase';
import SessionDetail from './view/screens/sessionDetail/SessionDetail';
import CareNavUserDetail from './view/screens/careNav/careNavForm/CareNavFormBase';
import CareNavOverview from './view/screens/careNav/overview/CareNavOverview';
import { ResponderHome } from './view/screens/responderHome/ResponderHome';
import CallLogs from './view/screens/callLogs/CallLogs';
import useAuth from './utilities/hooks/UseAuth';
import { PathConfig } from './config/PathConfig';
import { EditSession } from './view/screens/EditSession/EditSessionFormBase';

const App = () => {
  const location = useLocation();
  const { user } = useAuth();
  return (
    <>
      <Toaster position="top-right" gutter={8} toastOptions={ToasterStyles} />
      <ReactQueryDevtools initialIsOpen={false} />

      <AnimatePresence mode="wait" initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route element={<ProtectedLayout />}>
            <Route
              path={`${PathConfig.responder}/*`}
              element={
                user?.addOns?.isCareNavigator ? <Navigate to="/" /> : <Outlet />
              }
            >
              <Route path="" element={<ResponderHome />} />
              <Route path={PathConfig.callLogs} element={<CallLogs />} />
              <Route
                path={PathConfig.newCaseNote}
                element={<CaseNoteFormBase />}
              />
            </Route>

            <Route
              path={PathConfig.sessionDetail}
              element={<SessionDetail />}
            />

            <Route
              path={PathConfig.sessionDetail + PathConfig.editSession}
              element={<EditSession />}
            />

            <Route
              path={`${PathConfig.careNavigator}/*`}
              element={
                user?.addOns?.isCareNavigator ? <Outlet /> : <Navigate to="/" />
              }
            >
              <Route path="" element={<CareNavOverview />} />
              <Route
                path={PathConfig.newCaseNote}
                element={<CareNavUserDetail />}
              />
            </Route>
            <Route
              path="/"
              element={
                user?.addOns?.isCareNavigator ? (
                  <Navigate to={PathConfig.careNavigator} />
                ) : (
                  <Navigate to={PathConfig.responder} />
                )
              }
            />
          </Route>

          <Route path={PathConfig.login} element={<Login />} />

          <Route path="*" element={<Navigate to={PathConfig.login} />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default App;
