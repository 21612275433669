import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { IoAdd, IoRemoveCircleOutline } from 'react-icons/io5';
import { Fragment } from 'react';
import Modal from '../../../../../components/modal/Modal';
import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Label,
  Select,
  Typography,
} from '../../../../../styledComponents';
import { ClientECP } from '../../../../../../shared/types/Client';
import { noWhiteSpaceRegex } from '../../../../../../utilities/common/FormValidatorRegex';
import { UserECPRelations } from '../../../../../../shared/constants/ECP';
import { theme } from '../../../../../../stitches.config';
import { useCreateClientECP } from '../../../../../../utilities/hooks/queryHooks/client/UseGetAndCreateECP';
import useTracking from '../../../../../../utilities/hooks/UseTracking';
import { CaseNoteFormEvent } from '../../../../../../shared/constants/events/CaseNoteForm';

type ECPModalTypes = {
  contacts: ClientECP[];
};

const ManageECPModal = ({
  userId,
  ecpData,
  handleClose,
  open,
}: {
  ecpData: ClientECP[];
  userId: string;
  open: boolean;
  handleClose: () => void;
}) => {
  const {
    watch,
    register,
    control,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<ECPModalTypes>({
    mode: 'onTouched',
    defaultValues: {
      contacts: ecpData.length
        ? [...ecpData]
        : [{ name: '', relation: '', contact: '', consent: false }],
    },
  });

  const { track } = useTracking<CaseNoteFormEvent>();

  const { mutate: mutateECP, isLoading: isECPMutating } = useCreateClientECP(
    userId,
    () => handleClose(),
  );

  const shouldAllowSavingECP = !Object.keys(errors).length && isValid;

  const handleSubmit = () => {
    track('add_emergency_contact', {
      eventAction: 'click',
      eventCategory: 'save_information',
      eventLabel: 'save_information',
    });
    mutateECP(watch('contacts'));
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      confirmButtonDisabled={!shouldAllowSavingECP}
      confirmLoading={isECPMutating}
      title="Add emergency contact"
      subtitle="Ensure you have two emergency contacts in case we can’t reach one of them"
      confirmButtonText="Save"
      showXButtonToCloseInstead
    >
      {watch('contacts').map((contact, index) => (
        <Fragment key={contact.userId || `ecp-contact-${index}`}>
          {index ? (
            <hr
              style={{
                color: theme.colors.gray500.value,
                padding: '0.25rem 0',
              }}
            />
          ) : null}

          <Flex justify="end">
            {index && !contact.userId ? (
              <Button
                type="button"
                onClick={() => {
                  setValue(
                    'contacts',
                    watch('contacts').filter((_c, i) => i !== index),
                  );
                  trigger();
                }}
                startIcon={<IoRemoveCircleOutline size={12} />}
                variant="ghost"
                size="sm"
              >
                Remove
              </Button>
            ) : null}
          </Flex>

          <Grid columns={2} gapX="2">
            <Label
              htmlFor="name"
              css={{
                gridColumn: '1 / span 2',
              }}
            >
              Emergency contact name *
              <Input
                defaultValue={contact.name}
                id="name"
                {...register(`contacts.${index}.name`, {
                  required: true,
                  pattern: {
                    value: noWhiteSpaceRegex,
                    message: 'No trailing or leading spaces.',
                  },
                })}
                helperText={
                  errors.contacts?.[index]?.name?.type === 'required'
                    ? 'This is required.'
                    : errors.contacts?.[index]?.message?.toString()
                }
              />
            </Label>
            <Label htmlFor="phone">
              Phone number *
              <Box
                css={{
                  border: '1px solid $gray300',
                  borderRadius: '0.5rem',
                  mt: '$2',
                  backgroundColor: '$gray100',
                }}
              >
                <Controller
                  name={`contacts.${index}.contact`}
                  control={control}
                  rules={{
                    required: true,
                    minLength: {
                      value: 8,
                      message:
                        'Phone number should have a minimum of 8 digits.',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <PhoneInput
                      placeholder=""
                      inputProps={{
                        ref,
                      }}
                      inputClass="phone-input"
                      country="sg"
                      {...rest}
                    />
                  )}
                />
              </Box>
            </Label>
            <Label htmlFor="relation">
              Relationship
              <Controller
                name={`contacts.${index}.relation`}
                defaultValue={contact.relation}
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value, ...rest } }) => (
                  <Select
                    options={Object.entries(UserECPRelations).map(
                      ([label, val]) => ({ label, value: val }),
                    )}
                    selected={value}
                    onChange={onChange}
                    {...rest}
                  />
                )}
              />
              <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                {errors.contacts?.[index]?.message?.toString()}
              </Typography>
            </Label>
          </Grid>
        </Fragment>
      ))}

      {watch('contacts').length >= 2 ? null : (
        <button
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderRadius: '8px',
            border: '1px solid #DDE4F0',
            background: '#FBFCFE',
            padding: '1rem 1.5rem',
            textAlign: 'left',
            fontFamily: 'inherit',
            color: theme.colors.gray700.value,
            fontWeight: 500,
            gap: '0.25rem',
            cursor: 'pointer',
            marginTop: '1.5rem',
          }}
          type="button"
          onClick={() =>
            setValue('contacts', [
              ...watch('contacts'),
              { name: '', relation: '', contact: '', consent: false },
            ])
          }
        >
          <IoAdd size={18} fontWeight={700} />
          Add emergency contact
        </button>
      )}
    </Modal>
  );
};

export default ManageECPModal;
