import { AxiosRequestConfig } from 'axios';
import { StorageItems } from '../../shared/constants/App';
import { User } from '../../shared/types/User';
import { getItemFromLocalStorage } from '../common/Storage';

export const onRequestFulfilled = (request: AxiosRequestConfig<any>) => {
  console.log('%c Request: ', 'background: #00f; color: #fff', request);

  return request;
};

export const attachAuthHeader = (config: AxiosRequestConfig<any>) => {
  const cfg = { ...config } as AxiosRequestConfig<any>;
  cfg.headers = config.headers ?? {};
  const user = getItemFromLocalStorage(StorageItems.UserInfo, 'object') as User;
  if (user && user.token) {
    cfg.headers.Authorization = user.token;
  }

  return cfg;
};

export const onRequestRejected = (error: any) => Promise.reject(error);
