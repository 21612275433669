import { Controller, useFormContext } from 'react-hook-form';
import { IoInformationCircleOutline } from 'react-icons/io5';
import PhoneInput from 'react-phone-input-2';
import { AgeOptions, TenureOptions } from '../../../shared/constants/Session';
import { EditSessionFormTypes } from '../../../shared/types/CaseNoteForm';
import {
  convertIntoComboboxOptions,
  convertIntoOptions,
} from '../../../utilities/common/ConvertIntoOptions';
import { emailRegex } from '../../../utilities/common/FormValidatorRegex';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { useGetRegionsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetRegionsList';
import FormSection from '../../components/formSection/FormSection';
import {
  Box,
  Combobox,
  Flex,
  Grid,
  IconButton,
  Input,
  Label,
  Select,
  Tooltip,
  Typography,
} from '../../styledComponents';

export const EditClientInformation = () => {
  const { watch, register, control } = useFormContext<EditSessionFormTypes>();

  const { data: regionsList } = useGetRegionsList();
  const { data: organisationsList, dataUpdatedAt } = useGetOrganisationsList();

  return (
    <FormSection
      title="Edit Client information"
      subtitle="Previously entered client information"
      defaultOpen
    >
      <Grid align="center" columns={2} gapX="5" gapY="1">
        <Label
          htmlFor="phone"
          css={{
            gridColumn: '1 / span 2',
            mb: '$2',
          }}
        >
          Phone number
          <Box
            css={{
              border: '1px solid $gray300',
              borderRadius: '0.5rem',
              mt: '$2',
              backgroundColor: '$gray100',
            }}
          >
            <Controller
              name="phone"
              control={control}
              rules={{
                minLength: {
                  value: 8,
                  message: 'Phone number should have a minimum of 8 digits.',
                },
              }}
              render={({ field: { ref, ...rest } }) => (
                <PhoneInput
                  placeholder=""
                  inputProps={{
                    ref,
                  }}
                  inputClass="phone-input"
                  country="sg"
                  {...rest}
                />
              )}
            />
          </Box>
        </Label>
        <Label
          htmlFor="email"
          css={{
            gridColumn: '1 / span 2',
          }}
        >
          Email
          <Input
            disabled
            defaultValue={watch('email')}
            id="email"
            {...register('email', {
              pattern: {
                value: emailRegex,
                message: 'Please enter a valid email.',
              },
            })}
          />
        </Label>
        <Label htmlFor="name">
          Name
          <Input defaultValue={watch('name')} id="name" {...register('name')} />
        </Label>
        <Label htmlFor="age">
          Age
          <Controller
            name="age"
            defaultValue={watch('age')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                options={AgeOptions}
                selected={value}
                onChange={onChange}
                {...rest}
              />
            )}
          />
        </Label>
        <Label htmlFor="organisation">
          Organisation *
          <Controller
            name="organisation"
            defaultValue={watch('organisation')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Combobox
                disabled
                key={`${dataUpdatedAt}-${watch('organisation')}`}
                options={convertIntoComboboxOptions(
                  organisationsList ?? [],
                  'name',
                  'id',
                )}
                selectedValue={value?.toString()}
                onOptionSelect={(newOption) =>
                  onChange(newOption ? parseInt(newOption, 10) : newOption)
                }
                {...rest}
              />
            )}
          />
        </Label>
        <Label htmlFor="location">
          Location *
          <Controller
            name="location"
            defaultValue={watch('location')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                data-cy="location-select"
                options={convertIntoOptions(
                  regionsList ?? [],
                  'translation',
                  'region',
                )}
                selected={value}
                onChange={onChange}
                disabled={!regionsList}
                {...rest}
              />
            )}
          />
        </Label>
        <Label htmlFor="employeeId">
          <Flex align="center" gap="1">
            <Typography css={{ fontWeight: '500' }}>
              Identification Number
            </Typography>
            <Tooltip label="Eg. Employee ID, Staff ID, Matriculation ID">
              <IconButton type="button" size="sm">
                <IoInformationCircleOutline />
              </IconButton>
            </Tooltip>
          </Flex>
          <Input id="employeeId" {...register('employeeId')} />
        </Label>
        <Label htmlFor="tenure">
          Tenure *
          <Controller
            name="tenure"
            defaultValue={watch('tenure')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                data-cy="tenure-select"
                options={TenureOptions}
                selected={value}
                onChange={onChange}
                {...rest}
              />
            )}
          />
        </Label>
      </Grid>
    </FormSection>
  );
};
