import mixpanel from 'mixpanel-browser';

import { EventProperties, MixpanelEvent } from '../../shared/types/Analytics';
import { isProduction } from '../common/Path';

export type TrackFunction<T extends MixpanelEvent> = (
  event: T['EventNameType'][number],
  properties: EventProperties<
    T['EventCategoryType'][number],
    T['EventLabelType'][number]
  >,
) => void;

interface UseTrackingReturns<T extends MixpanelEvent> {
  track: TrackFunction<T>;
  user: {
    initialize: (userId: string) => void;
    setProperties: (
      properties: Record<string, string>,
      setMode?: 'once' | 'overwrite' | 'append',
    ) => void;
  };
}

function useTracking<T extends MixpanelEvent>(): UseTrackingReturns<T> {
  const track = (
    event: T['EventNameType'][number],
    properties: EventProperties<
      T['EventCategoryType'][number],
      T['EventLabelType'][number]
    >,
  ) => {
    if (!isProduction()) {
      console.log({ event, properties });
    }

    mixpanel.track(event, properties);
  };

  const initializeUser = (userId: string) => {
    mixpanel.identify(userId);
  };

  const setUserProperties = (
    properties: Record<string, string>,
    setMode: 'once' | 'overwrite' | 'append' = 'once',
  ) => {
    switch (setMode) {
      case 'once':
        mixpanel.people.set_once(properties);
        break;

      case 'overwrite':
        mixpanel.people.set(properties);
        break;

      case 'append':
        mixpanel.people.append(properties);
        break;

      default:
        mixpanel.people.set_once(properties);
    }
  };

  return {
    track,
    user: { initialize: initializeUser, setProperties: setUserProperties },
  };
}

export default useTracking;
