import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  TenureOptions,
  NoOrganisationCode,
} from '../../../../shared/constants/Session';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';

import FormSection from '../../../components/formSection/FormSection';
import {
  Flex,
  Grid,
  Input,
  Label,
  Tag,
  Typography,
  Select,
  Tooltip,
  IconButton,
  Checkbox,
  Combobox,
} from '../../../styledComponents';
import {
  convertIntoComboboxOptions,
  convertIntoOptions,
} from '../../../../utilities/common/ConvertIntoOptions';
import { useGetRegionsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetRegionsList';
import { useGetOrganisationsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';

const EnterOrgInformation = () => {
  const {
    control,
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useFormContext<CaseNoteFormTypes>();

  const handleOrgAnonymousChange = (value: boolean) => {
    if (!value) {
      reset({
        ...watch(),
        organisation: -1,
        location: 'Did not disclose',
        tenure: 'no-disclose',
      });
    }
  };

  const { data: regionsList } = useGetRegionsList();
  const { data: organisationsList, dataUpdatedAt } = useGetOrganisationsList();

  const [location, age, organisation, tenure, orgAnonymous, id] = watch([
    'location',
    'age',
    'organisation',
    'tenure',
    'orgAnonymous',
    'id',
  ]);

  const doesNotWantToDiscloseOrgData =
    organisation === -1 &&
    location === 'Did not disclose' &&
    tenure === 'no-disclose';

  const allDataAdded = [organisation, location, tenure].every(Boolean);

  // Update isMinor whenever location or age change
  useEffect(() => {
    if (allDataAdded) {
      if (doesNotWantToDiscloseOrgData) { 
        if (!orgAnonymous) {
          setValue('orgAnonymous', true);
        }
      } else if (orgAnonymous) {
        setValue('orgAnonymous', false);
      }
    }
  }, [allDataAdded, doesNotWantToDiscloseOrgData, orgAnonymous, setValue]);

  useEffect(() => {
    if (age && age !== 'NA') {
      const numAge = parseInt(age, 10);
      const regionToFind = regionsList?.find(
        (region) => region.region === location,
      );

      if (regionToFind) {
        if (numAge >= regionToFind.ageMin && numAge <= regionToFind.ageMax) {
          setValue('isMinor', true);
        } else if (watch('isMinor')) {
          setValue('isMinor', false);
        }
      }
    }
  }, [age, location, regionsList, setValue, watch]);

  const organisationNote = organisationsList?.find(
    (org) => org.id === organisation,
  );

  const getOrgCredits = (type: '0' | '1') => {
    const currentOrgCredit = organisationsList
      ?.find((org) => org.id === organisation)
      ?.credits.find((credit) => credit.type === type);

    let creditText = null;

    if (currentOrgCredit) {
      const { credits, creditPerUser } = currentOrgCredit;
      if (credits === null) {
        if (creditPerUser === null) {
          creditText = 'Unlimited';
        } else if (creditPerUser >= 0) {
          creditText = `${creditPerUser} credit${creditPerUser === 1 ? '' : 's'} per user`;
        }
      } else if (credits >= 0) {
        if (creditPerUser === null) {
          creditText = `${credits} credit pool`;
        } else if (creditPerUser >= 0) {
          creditText = `${credits} credit pool (${creditPerUser} credit${creditPerUser === 1 ? '' : 's'} per user)`;
        }
      }
    }

    if (creditText) {
      return `${type === '0' ? 'Coaching' : 'Clinical'}: ${creditText}`;
    }
    return creditText;
  };

  return (
    <FormSection
      title="Enter Organisation information"
      subtitle="Enter your org information to get started with the session"
      defaultOpen
      statusTag={
        orgAnonymous ? (
          <Tag variant="gray" shape="pill">
            Anonymous
          </Tag>
        ) : allDataAdded && // If some fields are not filled OR an error exists for a field.
          !Object.keys(errors).some((key) =>
            ['organisation', 'location', 'tenure'].includes(key),
          ) ? (
          <Tag variant="success" shape="pill">
            Done
          </Tag>
        ) : (
          <Tag variant="warning" shape="pill">
            Pending
          </Tag>
        )
      }
    >
      <Grid align="center" columns={2} gapX="5" gapY="1">
        <Label htmlFor="organisation">
          Organisation *
          <Controller
            name="organisation"
            defaultValue={organisation}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Combobox
                key={`${dataUpdatedAt}-${organisation}`}
                options={convertIntoComboboxOptions(
                  organisationsList ?? [],
                  'name',
                  'id',
                )}
                selectedValue={value?.toString()}
                onOptionSelect={(newOption) =>
                  onChange(newOption ? parseInt(newOption, 10) : newOption)
                }
                disabled={
                  !!id || !organisationsList || watch('orgMessageHandlerActive')
                }
                {...rest}
              />
            )}
            rules={{
              required: {
                value: !orgAnonymous,
                message: 'This is required.',
              },
            }}
          />
          <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
            {errors.organisation?.message?.toString()}
          </Typography>
          {organisation === NoOrganisationCode && (
            <Typography color="darkRed" size="xs" css={{ mb: '$3' }}>
              Warning: A session cannot be booked for a user without an
              organisation.
            </Typography>
          )}
        </Label>
        <Label htmlFor="location">
          Location *
          <Controller
            name="location"
            defaultValue={location}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                data-cy="location-select"
                options={convertIntoOptions(
                  regionsList ?? [],
                  'translation',
                  'region',
                )}
                selected={value}
                onChange={onChange}
                disabled={!!id || !!watch('isDependent') || !regionsList}
                {...rest}
              />
            )}
            rules={{
              required: {
                value: !orgAnonymous,
                message: 'This is required.',
              },
            }}
          />
          <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
            {errors.location?.message?.toString()}
          </Typography>
        </Label>
        <Label htmlFor="employeeId">
          <Flex align="center" gap="1">
            <Typography css={{ fontWeight: '500' }}>
              Identification Number
            </Typography>
            <Tooltip label="Eg. Employee ID, Staff ID, Matriculation ID">
              <IconButton type="button" size="sm">
                <IoInformationCircleOutline />
              </IconButton>
            </Tooltip>
          </Flex>
          <Input id="employeeId" disabled={!!id} {...register('employeeId')} />
        </Label>
        <Label htmlFor="tenure">
          Tenure *
          <Controller
            name="tenure"
            defaultValue={tenure}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                data-cy="tenure-select"
                options={TenureOptions}
                selected={value}
                onChange={onChange}
                disabled={!!id}
                {...rest}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'This is required.',
              },
            }}
          />
          <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
            {errors.tenure?.message?.toString()}
          </Typography>
        </Label>
        {organisation && organisation !== NoOrganisationCode ? (
          <Label htmlFor="org-benefits" css={{ gridColumn: '1 / span 2' }}>
            Organisation Benefits
            <Input
              id="org-benefits"
              disabled
              value={[getOrgCredits('0'), getOrgCredits('1')]
                .filter(Boolean)
                .join(' | ')}
              css={{ width: '100%' }}
            />
          </Label>
        ) : null}
      </Grid>

      <Controller
        name="isDependent"
        render={({ field: { value, onBlur, onChange } }) => (
          <Flex gap="2" align="center" css={{ my: '$3' }}>
            <Checkbox
              onClick={() => onChange(!value)}
              onBlur={onBlur}
              checked={value}
              disabled={!!id || !!watch('dependentId')}
              id="isDependent"
            />
            <Label htmlFor="isDependent">Client is a dependent</Label>
          </Flex>
        )}
      />

      <Flex gap="2" align="center" css={{ my: '$3' }}>
        <Checkbox
          checked={watch('isMinor')}
          disabled={!!id || !!watch('isChild')}
          id="isMinor"
          css={{ cursor: 'not-allowed' }}
        />
        <Label htmlFor="isMinor">Client is a minor</Label>
      </Flex>
      <Controller
        name="orgAnonymous"
        render={({ field: { value, onBlur } }) => (
          <Flex gap="2" align="center" css={{ mt: '$3' }}>
            <Checkbox
              onClick={() => handleOrgAnonymousChange(value)}
              onBlur={onBlur}
              checked={value}
              disabled={!!id || doesNotWantToDiscloseOrgData}
              id="orgAnonymous"
            />
            <Label htmlFor="orgAnonymous">
              Client doesn’t want to share further information
            </Label>
          </Flex>
        )}
      />
      {organisationNote?.eapNote.length ? (
        <Flex
          align="start"
          gap="3"
          css={{
            backgroundColor: '$gray75',
            br: '$2',
            p: '$2',
            border: '1px solid $gray200',
            color: '$gray600',
            mt: '$5',
            fontSize: '$2',
          }}
        >
          <IoInformationCircleOutline size={22} />
          {organisationNote.eapNote.join(', ')}
        </Flex>
      ) : null}
    </FormSection>
  );
};

export default EnterOrgInformation;
