export const HandHoldingHeartIcon = ({
  color,
  fill,
}: {
  color: string;
  fill: string;
}) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ minWidth: '50px', minHeight: '50px' }}
  >
    <rect width="32" height="32" rx="4" fill={fill} />
    <path
      d="M11.2 22.4641H13.2882C13.5605 22.4641 13.8311 22.4966 14.0951 22.5616L16.3015 23.0998C16.7803 23.2169 17.279 23.2282 17.7628 23.1339L20.2024 22.6576C20.8468 22.5316 21.4397 22.2218 21.9043 21.7682L23.6303 20.083C24.1232 19.6026 24.1232 18.823 23.6303 18.3417C23.1865 17.9084 22.4838 17.8596 21.9817 18.2271L19.9701 19.7001C19.682 19.9115 19.3315 20.0253 18.9709 20.0253H17.0284L18.2649 20.0252C18.9618 20.0252 19.5263 19.4741 19.5263 18.7936V18.5473C19.5263 17.9823 19.1325 17.4897 18.5713 17.3531L16.6629 16.8873C16.3523 16.8117 16.0342 16.7735 15.7145 16.7735C14.9427 16.7735 13.5455 17.4149 13.5455 17.4149L11.2 18.3994M8 18.0582L8 22.7153C8 23.165 8 23.3898 8.08719 23.5616C8.16389 23.7127 8.28628 23.8355 8.43681 23.9125C8.60794 24 8.83196 24 9.28 24H9.92C10.368 24 10.5921 24 10.7632 23.9125C10.9137 23.8355 11.0361 23.7127 11.1128 23.5616C11.2 23.3898 11.2 23.165 11.2 22.7153V18.0582C11.2 17.6085 11.2 17.3837 11.1128 17.2119C11.0361 17.0608 10.9137 16.938 10.7632 16.861C10.5921 16.7735 10.368 16.7735 9.92 16.7735H9.28C8.83196 16.7735 8.60794 16.7735 8.43681 16.861C8.28628 16.938 8.16389 17.0608 8.08719 17.2119C8 17.3837 8 17.6085 8 18.0582ZM20.1531 9.2196C19.6756 8.21684 18.5749 7.6856 17.5043 8.19835C16.4338 8.7111 15.9778 9.92709 16.426 10.9946C16.703 11.6543 17.4966 12.9354 18.0625 13.8179C18.2716 14.1439 18.3761 14.307 18.5288 14.4023C18.6598 14.4842 18.8237 14.5282 18.9779 14.5231C19.1577 14.5172 19.3294 14.4284 19.6729 14.251C20.6026 13.7707 21.9281 13.0595 22.4967 12.6272C23.4166 11.9277 23.6445 10.6419 22.9557 9.66442C22.267 8.68697 21.0661 8.59079 20.1531 9.2196Z"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
