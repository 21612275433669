import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

import useAuth from '../../../utilities/hooks/UseAuth';
import wordmark from '../../../assets/images/wordmark.svg';
import LoginRightBox from './LoginRightBox';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Typography,
} from '../../styledComponents';
import Container from '../../components/container/Container';
import { useLogin } from '../../../utilities/hooks/queryHooks/Login/UseLogin';
import {
  emailRegex,
  noWhiteSpaceRegex,
} from '../../../utilities/common/FormValidatorRegex';
import useTracking from '../../../utilities/hooks/UseTracking';
import { CommonEvent } from '../../../shared/constants/events/Common';
import { PathConfig } from '../../../config/PathConfig';

type LoginFormData = {
  inputs: {
    email: string;
    password: string;
  };
  passwordVisible: boolean;
};

const Login = () => {
  const {
    getValues,
    register,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<LoginFormData>({
    defaultValues: {
      inputs: {
        email: '',
        password: '',
      },
      passwordVisible: false,
    },
    mode: 'onTouched',
  });

  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const { track } = useTracking<CommonEvent>();

  const onLoginSuccess = () => {
    track('login', {
      eventAction: 'click',
      eventCategory: 'login',
      eventLabel: 'login',
    });
  };

  const { isLoading, mutate } = useLogin(onLoginSuccess);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate(getValues('inputs'));
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (user?.addOns?.isCareNavigator) {
        navigate(PathConfig.careNavigator);
      } else {
        navigate(PathConfig.responder);
      }
    }
  }, [isLoggedIn, navigate, user?.addOns?.isCareNavigator]);

  return (
    <Container
      css={{
        padding: 0,
        height: '100%',
        display: 'grid',
        '& .child': {
          padding: '5rem 10rem',
        },
        '@sm': {
          gridTemplateColumns: '1fr',
        },
        '@md': {
          gridTemplateColumns: '1fr 1fr',
        },
      }}
    >
      <div className="child">
        <img alt="Intellect Wordmark" src={wordmark} width={120} />
        <Box>
          <Heading
            size="xl"
            css={{
              fontWeight: 600,
              fontSize: '2.5em',
              m: '$7 0 $3',
              '@xl': {
                mt: '8rem',
              },
            }}
          >
            Login
          </Heading>
          <Heading size="sm" css={{ mb: '$7' }}>
            Welcome back, please enter your details
          </Heading>
          <form onSubmit={handleSubmit}>
            <Flex direction="column">
              <Typography size="xs" color="gray">
                Email
              </Typography>
              <Input
                {...register('inputs.email', {
                  required: {
                    value: true,
                    message: 'This is required.',
                  },
                  pattern: {
                    value: noWhiteSpaceRegex,
                    message: 'No trailing or leading spaces.',
                  },
                  validate: {
                    isEmail: (value) => !!emailRegex.test(value),
                  },
                })}
                variant="flat"
                autoFocus
                helperText={
                  errors.inputs?.email?.type === 'isEmail'
                    ? 'Please enter a valid email.'
                    : errors.inputs?.email?.message?.toString()
                }
                id="email"
              />
              <Typography size="xs" color="gray">
                Password
              </Typography>
              <Flex align="center">
                <Input
                  {...register('inputs.password', {
                    required: {
                      value: true,
                      message: 'This is required.',
                    },
                  })}
                  variant="flat"
                  type={watch('passwordVisible') ? 'text' : 'password'}
                  helperText={errors.inputs?.password?.message?.toString()}
                  css={{ flexGrow: 1 }}
                  id="password"
                />
                <IconButton
                  id="show-hide-password"
                  type="button"
                  onClick={() =>
                    setValue('passwordVisible', !getValues('passwordVisible'))
                  }
                  variant="ghost"
                >
                  {watch('passwordVisible') ? (
                    <IoEyeOffOutline size={18} />
                  ) : (
                    <IoEyeOutline size={18} />
                  )}
                </IconButton>
              </Flex>
              <Button
                id="login"
                type="submit"
                size="md"
                color="darkMetal"
                disabled={!isValid}
                isLoading={isLoading}
                loadingText="Signing you in..."
                css={{
                  mt: '$6',
                  padding: '1.5rem',
                }}
              >
                Login
              </Button>
            </Flex>
          </form>
        </Box>
      </div>
      <LoginRightBox />
    </Container>
  );
};

export default Login;
