import { useQuery } from '@tanstack/react-query';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';

export const useGetProviderConfig = () =>
  useQuery<any>(
    [QueryKeys.getProviderConfig],
    () =>
      doGet(ApiConfig.api.clients.getProviderConfig).then(
        (res) => res.data.data,
      ),
    {
      cacheTime: 30 * 60 * 1000,
    },
  );
