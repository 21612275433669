import { CSS, theme } from '../../stitches.config';

const COLOR_PATTERN = '$--clr--';

export const generateColorPaletteVariants = (css: CSS) => {
  const cssString = JSON.stringify(css);

  return Object.values(theme.colors).reduce((prev, color) => {
    // if (!substringToReplace?.length) throw Error("No Color Token provided.");

    const replacedSubstring = cssString.replaceAll(
      COLOR_PATTERN,
      `$${color.token}`,
    );
    // const replacedString = cssString.replace(
    //   substringToReplace[0],
    //   replacedSubstring
    // );

    return { ...prev, [color.token]: JSON.parse(replacedSubstring) };
  }, {}) as Record<keyof typeof theme.colors, CSS>;
};
