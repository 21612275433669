import { format } from 'date-fns';
import { ProviderMeetingDetails } from '../../../../../../shared/types/Session';
import {
  Heading,
  Flex,
  Typography,
  Box,
  Button,
} from '../../../../../styledComponents';

export const MeetingCard = ({
  cardData,
  startMeetingCancel,
}: {
  cardData: ProviderMeetingDetails | ProviderMeetingDetails['meeting'];
  startMeetingCancel: (meetingId: string) => void;
}) => {
  const meetingDetails = cardData as ProviderMeetingDetails; // can cast because of same structure

  return (
    <Box
      key={meetingDetails.id}
      css={{
        border: '1px solid $gray300',
        borderRadius: '$3',
        backgroundColor: 'white',
        padding: '$5',
      }}
    >
      <Heading size="lg" css={{ fontWeight: 'bold' }}>
        {meetingDetails.providerName ?? 'Provider name unavailable'}
      </Heading>
      <Flex gap="5" css={{ mt: '$6' }} align="center">
        <Box>
          <Typography size="sm" color="gray500">
            Start time
          </Typography>
          <Typography size="lg" css={{ fontWeight: '600', mt: '$1' }}>
            {format(
              new Date(Number(meetingDetails.scheduledStartTime) * 1000),
              'dd MMM yy, h:mm aaa',
            )}
          </Typography>
        </Box>
        <Box css={{ flexGrow: 1 }}>
          <Typography size="sm" color="gray500">
            End time
          </Typography>
          <Typography size="lg" css={{ fontWeight: '600', mt: '$1' }}>
            {format(
              new Date(Number(meetingDetails.scheduledEndTime) * 1000),
              'dd MMM yy, h:mm aaa',
            )}
          </Typography>
        </Box>
        <Button
          id="medium-risk-show-meeting-cancel-modal"
          type="button"
          variant="outlined"
          onClick={() => startMeetingCancel(meetingDetails.id)}
        >
          Remove booking
        </Button>
      </Flex>
    </Box>
  );
};
