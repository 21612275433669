import { Fragment, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { TbPhonePlus } from 'react-icons/tb';
import { PrsentingIssues } from '../../../shared/constants/PresentingIssues';
import { LearnMoreRiskAssessmentLink } from '../../../shared/constants/Resources';
import {
  RiskColorVariants,
  RiskContentData,
  RiskLabels,
  RiskLevels,
} from '../../../shared/constants/Risk';
import { EditSessionFormTypes } from '../../../shared/types/CaseNoteForm';
import {
  AssessmentPayload,
  CaseNoteSession,
} from '../../../shared/types/Session';
import { theme } from '../../../stitches.config';
import { useGetClientECP } from '../../../utilities/hooks/queryHooks/client/UseGetAndCreateECP';
import { useGetAssessmentQuestions } from '../../../utilities/hooks/queryHooks/session/UseGetAssessmentQuestions';
import FormSection from '../../components/formSection/FormSection';
import {
  Box,
  Button,
  Checkbox,
  Combobox,
  Flex,
  Heading,
  Label,
  RadioGroup,
  RadioGroupItem,
  Tag,
  Typography,
} from '../../styledComponents';
import { HandHoldingHeartIcon } from '../caseNotesForm/enterSessionNotes/riskLevelsView/bookASession/HandHoldingHeartIcon';
import ManageECPModal from '../caseNotesForm/enterSessionNotes/riskLevelsView/emergencyContacts/ManageECPModal';
import ViewECPModal from '../caseNotesForm/enterSessionNotes/riskLevelsView/emergencyContacts/ViewECPModal';

export const EditSessionNotes = ({
  sessionData,
}: {
  sessionData: CaseNoteSession;
}) => {
  const { watch, control, setValue } = useFormContext<EditSessionFormTypes>();

  const [showECPModal, setShowECPModal] = useState(false);

  const riskId = watch('risk');

  const {
    data: ecpData,
    isLoading: isECPDataLoading,
    fetchStatus: ecpFetchStatus,
  } = useGetClientECP(sessionData.client.userId);

  const { data: assessmentQuestions, isSuccess } =
    useGetAssessmentQuestions(riskId);

  useEffect(() => {
    if (assessmentQuestions) {
      setValue(
        'assessment',
        assessmentQuestions.map((question) => {
          const answerToFind = sessionData.sessionQuestionResponses.find(
            (ans) => ans.questionId === question.id,
          );
          return {
            questionId: question.id,
            response: answerToFind?.response === '1',
            isRequired: question.isRequired,
          };
        }),
      );
    }
  }, [
    assessmentQuestions,
    setValue,
    riskId,
    sessionData.sessionQuestionResponses,
  ]);

  const getECPButtonText = () => {
    if (ecpData && !ecpData.length) {
      return 'Add contact';
    }

    return 'View';
  };

  return (
    <FormSection
      title="Edit session notes"
      subtitle="Previously entered session notes"
      defaultOpen
    >
      <Label htmlFor="issue" css={{ width: '100%' }}>
        Presenting issue *
        <Controller
          name="issue"
          defaultValue={watch('issue')}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Combobox
              categorized
              options={PrsentingIssues}
              selectedValue={value}
              onOptionSelect={onChange}
              {...rest}
            />
          )}
        />
      </Label>
      <Box css={{ backgroundColor: '$gray50', p: '$5', br: '$3', mt: '$4' }}>
        <Heading size="sm" css={{ mb: '$3', fontWeight: 'bold' }}>
          Risk Assessment Category
        </Heading>
        <Controller
          name="risk"
          render={({ field: { onChange, value } }) => (
            <RadioGroup value={value}>
              {Object.values(RiskLevels).map((riskValue) => (
                <Fragment key={RiskLabels[riskValue]}>
                  <RadioGroupItem
                    value={String(riskValue)}
                    checked={riskValue === value}
                    id={RiskLabels[riskValue]}
                    onClick={() => onChange(riskValue)}
                  />
                  <Label css={{ pr: '$4' }} htmlFor={RiskLabels[riskValue]}>
                    {RiskLabels[riskValue]}
                  </Label>
                </Fragment>
              ))}
            </RadioGroup>
          )}
        />

        {[RiskLevels.High, RiskLevels.HighCritical].some(
          (r) => r === riskId,
        ) ? (
          <Box
            css={{
              border: '1px solid $gray300',
              borderRadius: '$3',
              backgroundColor: 'white',
              padding: '$5',
              marginTop: '$5',
            }}
          >
            <Box>
              <Heading size="md" css={{ fontWeight: 'bold', mb: '$1' }}>
                {RiskContentData[riskId].title}
              </Heading>
              <Typography size="lg" color="gray500">
                Complete the following action
              </Typography>
            </Box>
            <Flex
              css={{
                border: '1px solid $gray300',
                borderRadius: '$3',
                backgroundColor: 'white',
                padding: '$5',
                marginTop: '$5',
              }}
              align="center"
              gap="6"
            >
              <HandHoldingHeartIcon
                color={theme.colors[RiskContentData[riskId].color.inner].value}
                fill={theme.colors[RiskContentData[riskId].color.outer].value}
              />
              <Typography size="md">
                Get the advice you need. Check the latest Intellect resources
                for next steps.{' '}
                <a // eslint-disable-line react/jsx-no-target-blank
                  id={`${RiskContentData[riskId].title.replace(' ', '-')}-resource-link`}
                  href={LearnMoreRiskAssessmentLink}
                  target="_blank"
                  style={{
                    color: theme.colors.lightBlue.value,
                    fontWeight: 500,
                  }}
                >
                  Learn more
                </a>
              </Typography>
            </Flex>

            <Flex
              css={{
                border: '1px solid $gray300',
                borderRadius: '$3',
                backgroundColor: 'white',
                padding: '$5',
                marginTop: '$2',
              }}
              align="center"
              gap="6"
            >
              <Tag
                variant={RiskColorVariants[riskId]}
                css={{ p: '$5 $3', br: '$2' }}
              >
                <TbPhonePlus size={24} />
              </Tag>
              <Flex align="center" gap="3" css={{ flexGrow: 1 }}>
                <Typography size="md">Client's emergency contact</Typography>

                {ecpData && !ecpData.length ? (
                  <Tag variant="warning" shape="pill">
                    Pending
                  </Tag>
                ) : null}
              </Flex>

              <Button
                variant="outlined"
                type="button"
                onClick={() => setShowECPModal(true)}
                isLoading={isECPDataLoading && ecpFetchStatus !== 'idle'}
                loadingText="Fetching..."
              >
                {getECPButtonText()}
              </Button>
            </Flex>

            <Flex
              css={{
                border: '1px solid $gray300',
                borderRadius: '$3',
                backgroundColor: 'white',
                padding: '$5',
                marginTop: '$2',
              }}
              align="center"
              gap="6"
            >
              <Tag
                variant={RiskColorVariants[riskId]}
                css={{ p: '$5 $3', br: '$2' }}
              >
                <HiOutlineSpeakerphone size={24} />
              </Tag>
              <Typography size="md">
                Inform your supervisor about the client's condition
              </Typography>
            </Flex>
            <Box css={{ px: '$3', mt: '$5' }}>
              <Heading size="sm" css={{ fontWeight: 'bold' }}>
                To continue, please confirm the following.
              </Heading>
            </Box>
            <Box css={{ px: '$7' }}>
              {!!watch('assessment') &&
                isSuccess &&
                watch('assessment').map(
                  (question: AssessmentPayload, index: number) =>
                    assessmentQuestions?.[index]?.type === 'checkbox' ? (
                      <Controller
                        key={question.questionId}
                        name="assessment"
                        defaultValue={watch('assessment')[index].response}
                        render={({ field: { onChange, value } }) => (
                          <Flex gap="2" align="center" css={{ my: '$5' }}>
                            <Checkbox
                              onClick={() =>
                                onChange([
                                  ...value.slice(0, index),
                                  {
                                    ...value[index],
                                    response: !value[index].response,
                                  },
                                  ...value.slice(index + 1),
                                ])
                              }
                              checked={value[index].response}
                              id={`assessment-${question.questionId}`}
                            />
                            <Label
                              htmlFor={`assessment-${question.questionId}`}
                              size="md"
                            >
                              {assessmentQuestions?.[index]?.question ??
                                'Assessment question unavailable'}
                              {assessmentQuestions?.[index]?.isRequired
                                ? '*'
                                : ''}
                            </Label>
                          </Flex>
                        )}
                      />
                    ) : null,
                )}
            </Box>

            {ecpData ? (
              ecpData.length ? (
                <ViewECPModal
                  ecpData={ecpData}
                  handleClose={() => setShowECPModal(false)}
                  open={showECPModal}
                />
              ) : (
                <ManageECPModal
                  userId={sessionData.client.userId}
                  ecpData={ecpData}
                  handleClose={() => setShowECPModal(false)}
                  open={showECPModal}
                />
              )
            ) : null}
          </Box>
        ) : null}
      </Box>
    </FormSection>
  );
};
