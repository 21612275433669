import { theme } from '../../stitches.config';
import { ValueOf } from '../types/Common';

export const RiskLevels = {
  Low: 0,
  Medium: 1,
  High: 2,
  HighCritical: 4,
  Other: 3,
} as const;

export const RiskColorVariants = {
  0: 'success',
  1: 'warning',
  2: 'purple',
  3: 'gray',
  4: 'error',
} as const satisfies Record<ValueOf<typeof RiskLevels>, string>;

export const RiskLabels = {
  0: 'Low',
  1: 'Medium',
  2: 'High: Non-critical',
  3: 'Other',
  4: 'High: Critical',
} as const satisfies Record<ValueOf<typeof RiskLevels>, string>;

export const RiskContentData = {
  0: {
    title: 'Low risk',
    color: { inner: 'darkGreen', outer: 'lightGreen' },
  },
  1: {
    title: 'Medium risk',
    color: { inner: 'gold', outer: 'lightGold' },
  },
  3: {
    title: 'Other - Non Risk Call',
    color: { inner: 'gray700', outer: 'gray100' },
  },
  2: {
    title: 'High risk: Non-critical',
    color: { inner: 'darkPurple', outer: 'lightPurple' },
  },
  4: {
    title: 'High risk: Critical',
    color: { inner: 'darkRed', outer: 'lightRed' },
  },
} as const satisfies Record<
  ValueOf<typeof RiskLevels>,
  {
    title: string;
    color: {
      inner: keyof typeof theme.colors;
      outer: keyof typeof theme.colors;
    };
  }
>;
