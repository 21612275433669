import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { CaseNoteFormTypes } from '../../../shared/types/CaseNoteForm';
import Container from '../../components/container/Container';
import { Grid, Heading } from '../../styledComponents';
import EnterClientInformation from './enterClientInformation/EnterClientInformation';
import EnterSessionNotes from './enterSessionNotes/EnterSessionNotes';
import FormConfirmation from './formConfirmation/FormConfirmation';
import CaseNoteTimer from './CaseNoteTimer';
import EnterOrgInformation from './enterOrgInformation/EnterOrgInformation';

const CaseNoteFormBase = () => {
  const methods = useForm<CaseNoteFormTypes>({
    defaultValues: {
      id: '',
      userId: '',
      phone: '',
      name: '',
      caseNotes: '',
      clientAnonymous: false,
      orgAnonymous: false,
      doesNotWantToBookSession: false,
      assessment: [],
      startTime: new Date(),
      endTime: new Date(),
      meetingData: null,
      tenure: '',
      age: '',
      isDependent: false,
      isMinor: false,
      orgMessageHandlerActive: false,
      orgSelected: false,
    },
    mode: 'onTouched',
  });

  console.log(methods.watch());

  useEffect(() => {
    methods.setValue(
      'orgAnonymous',
      methods.watch('organisation') === -1 &&
        methods.watch('location') === 'Did not disclose' &&
        methods.watch('tenure') === 'no-disclose',
    );
  }, [methods]);

  return (
    <Container>
      <Grid columns={3} css={{ mb: '$7' }}>
        <div />
        <Heading size="lg" css={{ textAlign: 'center' }}>
          Add case note
        </Heading>
        <CaseNoteTimer />
      </Grid>

      <FormProvider {...methods}>
        <form>
          <EnterClientInformation />
          <EnterOrgInformation />
          <EnterSessionNotes />
        </form>

        <FormConfirmation />
      </FormProvider>
    </Container>
  );
};

export default CaseNoteFormBase;
