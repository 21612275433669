import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import ApiConfig from '../../../../config/ApiConfig';
import { doPost } from '../../../../shared/service/NetworkClient';

export const useCreateComment = (onSuccess: () => void) =>
  useMutation(
    ({ sessionId, note }: { sessionId: string; note: string }) =>
      doPost(
        ApiConfig.api.sessions.addNoteV2.replace('{{sessionId}}', sessionId),
        {
          note,
        },
      ).then((res) => res.data.data),
    {
      onSuccess,
      onError: () => {
        toast.error('An error occurred while adding the comment.');
      },
    },
  );
