import {
  useSelect,
  UseSelectState,
  UseSelectStateChangeOptions,
} from 'downshift';
import { forwardRef } from 'react';
import { Noop } from 'react-hook-form';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import { Option } from '../../shared/types/SelectTypes';
import { CSS, styled } from '../../stitches.config';
import { Checkbox } from './Checkbox';
import { Menu, MenuItem } from './Menu';

type SelectProps = {
  options: Option[];
  disabled?: boolean;
  name?: string;
  onBlur?: Noop;
  menuCss?: CSS;
} & (
  | {
      multiple: true;
      selected: (string | number)[];
      onChange: (selectedOptions: (string | number)[]) => void;

      /** Max number of options that can be selected. */
      maxSelections?: number;
    }
  | {
      multiple?: undefined;
      selected?: string | number;
      onChange: (selectedOption: string | number) => void;
      maxSelections?: never;
    }
);

function itemToString(item: SelectProps['options'][number] | null) {
  return item ? item.label : '';
}
function stateReducer<Item>(
  state: UseSelectState<Item>,
  actionAndChanges: UseSelectStateChangeOptions<Item>,
) {
  const { changes, type } = actionAndChanges;
  switch (type) {
    case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
    case useSelect.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: true, // keep menu open after selection.
        highlightedIndex: state.highlightedIndex,
      };
    default:
      return changes;
  }
}

const SelectBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid $gray300',
  borderRadius: '0.5rem',
  padding: '$2',
  margin: '0.5rem 0',
  cursor: 'pointer',
  fontSize: '$3',
  fontWeight: '400',
  '&:focus': {
    outline: '1px solid $gray300',
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        backgroundColor: '$gray100',
        color: '$gray500',
        cursor: 'not-allowed',
      },
    },
  },
});

export const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      multiple,
      options,
      selected,
      maxSelections,
      onChange,
      disabled,
      menuCss,
      ...rest
    },
    ref,
  ) => {
    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect<SelectProps['options'][number]>({
      items: options,
      itemToString,
      ...(multiple && stateReducer),
      selectedItem: null,
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) {
          return;
        }
        if (multiple) {
          const index = selected.indexOf(selectedItem.value);

          if (index > 0) {
            onChange([
              ...selected.slice(0, index),
              ...selected.slice(index + 1),
            ]);
          } else if (index === 0) {
            onChange([...selected.slice(1)]);
          } else {
            onChange([...selected, selectedItem.value]);
          }
        } else {
          onChange(selectedItem.value);
        }
      },
    });

    const getButtonText = () => {
      if (multiple) {
        if (selected.length) {
          if (selected.length <= 2) {
            return options
              .filter((option) => selected.includes(option.value))
              .map((option) => option.label)
              .join(', ');
          }
          return `${selected.length} options`;
        }
        return 'Select';
      }
      return (
        options.find((option) => option.value === selected)?.label ?? 'Select'
      );
    };

    return (
      <div
        style={{ position: 'relative', width: '100%' }}
        className="select-box-wrapper"
      >
        <SelectBox
          ref={ref}
          {...getToggleButtonProps({ disabled })}
          disabled={disabled}
          {...rest}
        >
          <div style={{ flexGrow: 1 }}>{getButtonText()}</div>
          {isOpen ? (
            <IoChevronUpOutline size={18} />
          ) : (
            <IoChevronDownOutline size={18} />
          )}
        </SelectBox>
        <Menu
          {...getMenuProps()}
          css={{
            position: 'absolute',
            width: '100%',
            marginTop: '-0.1rem',
            maxHeight: '15rem',
            overflowY: 'auto',
            ...(!(isOpen && options?.length) && { display: 'none' }),
            ...menuCss,
          }}
        >
          {isOpen &&
            options.map((item, index) => (
              <MenuItem
                multiple={multiple}
                data-cy={`item-${item.value}`}
                css={{
                  ...(selected === item.value && {
                    backgroundColor: '$gray100',
                  }),
                  ...(highlightedIndex === index && {
                    backgroundColor: '$gray50',
                  }),
                }}
                key={`${item.value}${index}`} // eslint-disable-line react/no-array-index-key
                {...getItemProps({
                  item,
                  index,
                  'aria-selected': multiple
                    ? selected.includes(item.value)
                    : selected === item.value,
                })}
              >
                {multiple ? (
                  <Checkbox
                    checked={
                      multiple
                        ? selected.includes(item.value)
                        : selected === item.value
                    }
                    value={item.value}
                    onChange={() => null}
                  />
                ) : null}

                {item.label}
              </MenuItem>
            ))}
        </Menu>
      </div>
    );
  },
);
