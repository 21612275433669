import { Fragment, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PrsentingIssues } from '../../../../shared/constants/PresentingIssues';
import { CaseNoteMinimumCharacters } from '../../../../shared/constants/Session';
import { AssessmentPayload } from '../../../../shared/types/Session';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';
import FormSection from '../../../components/formSection/FormSection';
import {
  Box,
  RadioGroup,
  RadioGroupItem,
  Label,
  TextArea,
  Heading,
  Tag,
  Typography,
  Flex,
  Combobox,
} from '../../../styledComponents';
import { RiskLabels, RiskLevels } from '../../../../shared/constants/Risk';
import { CaseNoteFormEvent } from '../../../../shared/constants/events/CaseNoteForm';
import useTracking from '../../../../utilities/hooks/UseTracking';
import RiskLevel from './riskLevelsView/RiskLevel';
import MeetingCancellationModal from './riskLevelsView/bookASession/MeetingCancellationModal';

const EnterSessionNotes = () => {
  const {
    register,
    control,
    formState: { errors, touchedFields, dirtyFields },
    watch,
  } = useFormContext<CaseNoteFormTypes>();

  const [selectedMeetingIdToCancel, setSelectedMeetingIdToCancel] =
    useState<string>('');

  const { track } = useTracking<CaseNoteFormEvent>();

  const handleShowCancelMeetingModal = (meetingId: string) => {
    track('remove_booking', {
      eventAction: 'click',
      eventCategory: 'remove_booking',
      eventLabel: 'remove_booking',
    });

    setSelectedMeetingIdToCancel(meetingId);
  };

  return (
    <FormSection
      title="Enter session notes"
      subtitle="Record session notes and recommend coaches for your client"
      statusTag={
        !!watch('caseNotes') &&
        ([RiskLevels.High, RiskLevels.HighCritical].some(
          (r) => r === watch('risk'),
        )
          ? /* If High Risk, check if reqired checkboxes are checked. */
            watch('assessment')
              .filter((answer: AssessmentPayload) => answer.isRequired)
              .every((answer) => answer.response)
          : /* Else if Low or Medium Risk, Either a session should've been booked or the
      'Client does not want to have a coaching session' checkbox must be checked */
            watch('sessionId') || watch('doesNotWantToBookSession')) ? (
          <Tag variant="success" shape="pill">
            Done
          </Tag>
        ) : (
          <Tag variant="warning" shape="pill">
            Pending
          </Tag>
        )
      }
    >
      <Label
        htmlFor="issue"
        css={{
          width: '75%',
        }}
      >
        Presenting issue *
        <Controller
          name="issue"
          defaultValue={watch('issue')}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Combobox
              categorized
              options={PrsentingIssues}
              selectedValue={value}
              onOptionSelect={onChange}
              {...rest}
            />
          )}
          rules={{
            required: {
              value: true,
              message: 'This is required.',
            },
          }}
        />
        <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
          {errors.issue?.message?.toString()}
        </Typography>
      </Label>
      <Flex justify="between" css={{ mb: '$2' }}>
        <Label htmlFor="case-note-text">Note</Label>
        <Typography
          size="sm"
          color={
            watch('caseNotes').length >= CaseNoteMinimumCharacters
              ? 'darkGreen'
              : 'caseNotes' in touchedFields || 'caseNotes' in dirtyFields
                ? 'darkRed'
                : 'gray500'
          }
        >
          {watch('caseNotes').length}/{CaseNoteMinimumCharacters}
        </Typography>
      </Flex>
      <TextArea
        data-cy="text-area-case-notes"
        {...register('caseNotes', {
          required: {
            value: true,
            message: 'This is required.',
          },
          minLength: {
            value: CaseNoteMinimumCharacters,
            message: `Please enter a minimum of ${CaseNoteMinimumCharacters} characters.`,
          },
        })}
        size="md"
        css={{ mt: '$2', height: '20rem' }}
      />
      <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
        {errors.caseNotes?.message?.toString()}
      </Typography>

      <Box css={{ backgroundColor: '$gray50', p: '$5', br: '$3', mt: '$4' }}>
        <Heading size="sm" css={{ mb: '$3', fontWeight: 'bold' }}>
          Risk Assessment Category
        </Heading>
        <Controller
          name="risk"
          render={({ field: { onChange, value } }) => (
            <RadioGroup value={value}>
              {Object.values(RiskLevels).map((riskValue) => (
                <Fragment key={RiskLabels[riskValue]}>
                  <RadioGroupItem
                    value={String(riskValue)}
                    checked={riskValue === value}
                    id={RiskLabels[riskValue]}
                    onClick={() => onChange(riskValue)}
                  />
                  <Label css={{ pr: '$4' }} htmlFor={RiskLabels[riskValue]}>
                    {RiskLabels[riskValue]}
                  </Label>
                </Fragment>
              ))}
            </RadioGroup>
          )}
        />
        {watch('risk') !== undefined && (
          <>
            <RiskLevel
              startMeetingCancel={(meetingId) =>
                handleShowCancelMeetingModal(meetingId)
              }
            />
            <MeetingCancellationModal
              meetingId={selectedMeetingIdToCancel}
              open={!!selectedMeetingIdToCancel}
              handleClose={() => setSelectedMeetingIdToCancel('')}
            />
          </>
        )}
      </Box>
    </FormSection>
  );
};

export default EnterSessionNotes;
