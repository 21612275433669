import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { NoOrganisationCode } from '../../../shared/constants/Session';
import { EditSessionFormTypes } from '../../../shared/types/CaseNoteForm';
import { CaseNoteSession } from '../../../shared/types/Session';
import { useGetSessionData } from '../../../utilities/hooks/queryHooks/session/UseGetSessionData';
import Container from '../../components/container/Container';
import LoadingView from '../../components/loadingView/LoadingView';
import { Grid, Heading } from '../../styledComponents';
import { EditClientInformation } from './EditClientInformation';
import { EditFormConfirmation } from './EditFormConfirmation';
import { EditSessionNotes } from './EditSessionNotes';

export const EditSession = () => {
  const methods = useForm<EditSessionFormTypes>({
    defaultValues: {
      phone: '',
      name: '',
      tenure: '',
      age: '',
      assessment: [],
    },
    mode: 'onTouched',
  });

  const navigate = useNavigate();

  const { sessionId } = useParams();

  console.log(methods.watch());

  useEffect(() => {
    if (!sessionId) {
      navigate(PathConfig.callLogs);
    }
  }, [navigate, sessionId]);

  const onSessionDetailFetchSuccess = (data: CaseNoteSession) => {
    methods.reset({
      ...data.client,
      organisation: +(data.client.organisation || NoOrganisationCode),
      issue: data.issue,
      risk: data.risk,
      sessionId: data.id,
      assessment: data.sessionQuestionResponses,
    });
  };
  const { data: sessionData, isLoading: isSessionDetailsLoading } =
    useGetSessionData(sessionId, true, onSessionDetailFetchSuccess);

  if (isSessionDetailsLoading || !sessionData) {
    return <LoadingView />;
  }

  return (
    <Container>
      <Grid columns={3} css={{ mb: '$7' }}>
        <div />
        <Heading size="lg" css={{ textAlign: 'center' }}>
          Edit Information
        </Heading>
      </Grid>

      <FormProvider {...methods}>
        <form>
          <EditClientInformation />
          <EditSessionNotes sessionData={sessionData} />
          <EditFormConfirmation />
        </form>

        {/* <FormConfirmation /> */}
      </FormProvider>
    </Container>
  );
};
