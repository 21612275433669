import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { PaginationFilters } from '../../../shared/types/CallLogs';
import { usePagination } from '../../../utilities/hooks/usePagination';
import { Flex, IconButton, Typography } from '../../styledComponents';

import './Pagination.css';

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}: PaginationFilters) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Flex justify="center" align="center" gap="2" css={{ width: '100%' }}>
      <Typography>
        {(currentPage - 1) * pageSize} -{' '}
        {Math.min(totalCount, currentPage * pageSize)} of {totalCount}
      </Typography>
      <IconButton
        size="sm"
        variant="ghost"
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        <IoChevronBack size={18} />
      </IconButton>
      {/* {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS || typeof pageNumber === 'string') {
          return <span>&#8230;</span>;
        }

        return (
          <IconButton
            variant={pageNumber === currentPage ? 'raised' : 'ghost'}
            onClick={() => onClickPageNumber(pageNumber)}
          >
            {pageNumber}
          </IconButton>
        ); 
      })} */}
      <IconButton
        variant="ghost"
        size="sm"
        onClick={onNext}
        disabled={currentPage === lastPage}
      >
        <IoChevronForward size={18} />
      </IconButton>
    </Flex>
  );
};

export default Pagination;
