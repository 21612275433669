import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { doPost } from '../../../../shared/service/NetworkClient';

export const useCancelMeeting = (onSuccess: () => void) =>
  useMutation(
    (props: Record<'userId' | 'meetingId', string>) =>
      doPost(
        ApiConfig.api.sessions.cancelMeeting.replace(
          '{{meetingId}}',
          props.meetingId,
        ),
        { userId: Number(props.userId) },
      ).then((res) => res.data.data),
    {
      onSuccess: () => {
        onSuccess();
        toast.success('Meeting cancelled.');
      },
      onError: () => {
        toast.error('An error occurred while cancelling this meeting.');
      },
    },
  );
