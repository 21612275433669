import { styled } from '../../stitches.config';

export const Menu = styled('ul', {
  backgroundColor: 'white',
  zIndex: '$2',
  border: '1px solid $ghostwhite',
  pl: '0',
  br: '$3',
  boxShadow:
    '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
});

export const MenuItem = styled('li', {
  variants: {
    multiple: {
      true: {
        display: 'grid',
        gridTemplateColumns: '1fr 5fr',
        gap: '$1',
      },
    },
  },
  listStyleType: 'none',
  gap: '$1',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: '$3 $4',
  color: '$gray900',
  fontSize: '$3',
  fontWeight: '400',
  cursor: 'pointer',
  lineHeight: '1.2rem',
  '&:hover': {
    backgroundColor: '$gray50',
  },
  '& div span': {
    lineHeight: '1rem',
    textAlign: 'start',
  },
});
