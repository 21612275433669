import { formatDuration, intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';

import { zeroPadTime } from '../common/Time';

export const useTimer = () => {
  const [startTime] = useState<Date>(new Date());
  const [duration, setDuration] = useState<Duration>({});

  useEffect(() => {
    const timer = setInterval(() => {
      setDuration(
        intervalToDuration({
          start: startTime,
          end: new Date(),
        }),
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  const timePassed = formatDuration(duration, {
    format: [...(duration.hours ? ['hours'] : []), 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPadTime(count),
    },
  });

  return {
    hours: duration.hours ?? 0,
    minutes: duration.minutes ?? 0,
    seconds: duration.seconds ?? 0,
    timePassed,
  };
};
