import { useMutation } from '@tanstack/react-query';
// import toast from 'react-hot-toast';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { doGet } from '../../../../shared/service/NetworkClient';
import { CaseNoteAttachment } from '../../../../shared/types/Session';

export const useDownloadCaseNoteAttachment = () =>
  useMutation(
    ({
      attachment,
      sessionId,
    }: {
      attachment: CaseNoteAttachment;
      sessionId: string;
    }) =>
      doGet(
        ApiConfig.api.sessions.getNoteAttachment
          .replace('{{sessionId}}', String(sessionId))
          .replace('{{attachmentId}}', String(attachment?.id)),
        undefined,
        undefined,
        undefined,
        'blob',
      ).then((res) => res),
    {
      onSuccess: (data, { attachment }) => {
        const { fileName } = attachment;

        const blob = new Blob([data?.data], {
          type: 'application/octet-stream',
        });

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(url);
        toast.success('Attachment downloaded');
      },
      onError: () => {
        toast.error('An error occurred while downloading this attachment.');
      },
    },
  );
