import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { SessionTypeRequiredRoles } from '../../../../shared/constants/CareNav';
import { CareNavForm } from '../../../../shared/types/CareNavForm';
import { Credit } from '../../../../shared/types/FetchByEmailUserData';
import { ProviderMeetingDetails } from '../../../../shared/types/Session';
import { theme } from '../../../../stitches.config';
import { redirectToMaverick } from '../../../../utilities/common/Redirection';
import { useGetUserProviderData } from '../../../../utilities/hooks/queryHooks/careNav/UseGetUserProviderData';
import { useGetProviderConfig } from '../../../../utilities/hooks/queryHooks/client/UseGetProviderConfig';
import {
  Grid,
  Label,
  Input,
  Flex,
  Button,
  Box,
  Typography,
} from '../../../styledComponents';

const getRemainingCredits = (credits: Credit[] = [], type: '0' | '1' = '0') => {
  const currentCredits = credits.find((c) => c.type === type);
  const isUnlimited =
    currentCredits?.tier === '1' ||
    (currentCredits?.tier === '0' &&
      (currentCredits?.credit === 0 || currentCredits?.credit === null) &&
      currentCredits?.canBook);
  return currentCredits
    ? isUnlimited
      ? 'Unlimited'
      : `${currentCredits.credit} credits`
    : 'No';
};

const getProvider = (data: any = undefined, type: '0' | '1' | '10' = '0') => {
  const conversation = data?.userMaps.find((c: any) => c.providerRole === type);
  return {
    provider: conversation?.provider.name || 'NA',
    meetings:
      data?.meeting
        .filter((m: ProviderMeetingDetails) => m.providerRole === type)
        .map((m: ProviderMeetingDetails) =>
          format(new Date(+m.scheduledStartTime * 1000), 'MMM dd HH:mm'),
        )
        .join(', ') || 'NA',
  };
};

const getGoals = (goals?: any[], selected?: string) =>
  selected?.split(',').reduce((res: string, goalId) => {
    // eslint-disable-next-line no-param-reassign
    res = `${
      goals?.find((g) => g.id === +goalId)?.areaExpertise || ''
    }, ${res}`;
    return res;
  }, '');

const getCredits = (credits: Credit[], addOns: any) =>
  ['0', '1']
    .reduce((res: string[], cur) => {
      const currentCredits = credits?.find((c: any) => c.type === cur);
      if (currentCredits) {
        const isUnlimited =
          currentCredits?.tier === '1' ||
          (currentCredits?.tier === '0' &&
            (currentCredits?.credit === 0 || currentCredits?.credit === null) &&
            currentCredits?.canBook);
        return [
          ...res,
          `${cur === '0' ? 'Coaching' : 'Clinical'}: ${
            currentCredits
              ? isUnlimited
                ? 'Unlimited'
                : `${currentCredits.credit} credits`
              : 'No'
          }`,
        ];
      }
      return [
        ...res,
        `${cur === '0' ? 'Coaching' : 'Clinical'}: ${
          (cur === '0' ? addOns.coachEnabled : addOns.clinicalEnabled)
            ? !(cur === '0'
                ? addOns.credits.isPerCredit
                : addOns.credits.isClinicalPerCredit)
              ? 'Unlimited'
              : `${
                  cur === '0'
                    ? addOns.credits.coachingCredits
                    : addOns.credits.clinicalCredits
                } credits`
            : 'No'
        }`,
      ];
    }, [])
    .join(' | ');

const getSessionTypes = (credits: Record<string, Credit>, type: '0' | '1') => {
  const typeArray = [];
  const availableSessionTypes = Object.keys(credits);

  if (availableSessionTypes.includes(SessionTypeRequiredRoles[type].virtual)) {
    typeArray.push('Virtual');
  }
  if (availableSessionTypes.includes(SessionTypeRequiredRoles[type].inPerson)) {
    typeArray.push('In-person (Offsite)');
  }

  return typeArray.length ? typeArray.join(' | ') : 'N/A';
};

export const UserDetail = () => {
  const { watch } = useFormContext<CareNavForm>();

  const { data: userProviderData } = useGetUserProviderData(
    watch('userData.userId'),
  );

  const { data: providerConfig } = useGetProviderConfig();

  if (!watch(['id', 'sessionId', 'userData']).every(Boolean)) {
    return null;
  }

  // @ts-ignore
  if (!watch('userData.members')?.length) {
    return <Typography css={{ color: '$darkRed', textAlign: 'center' }}>
      User does not have organisation benefits, reach out to #tech_support on slack
    </Typography>;
  }

  return (
    <Box>
      <Grid columns={2} gapX="5" gapY="1">
        <Label htmlFor="name">
          Name
          <Input
            data-cy="name-input"
            id="name"
            disabled
            value={watch('userData.name')}
          />
        </Label>
        <Label htmlFor="Selected goals">
          Selected goals
          <Input
            id="goals"
            disabled
            value={
              getGoals(
                providerConfig?.goals,
                userProviderData?.preferences?.goals,
              ) || 'NA'
            }
          />
        </Label>
        <Label htmlFor="Organisation">
          Organisation
          <Input
            id="organisation"
            disabled
            value={watch('userData.organisation.name') || 'Not linked'}
          />
        </Label>
        <Label htmlFor="Organisation">
          Offerings
          <Input
            id="organisation"
            disabled
            value={getCredits(
              ...watch(['userData.credits', 'userData.addOns']),
            )}
          />
        </Label>
      </Grid>

      {watch('userData.addOns.coachEnabled') && (
        <Grid
          columns={2}
          gapX="5"
          gapY="1"
          css={{
            borderTop: `1px solid ${theme.colors.gray200.value}`,
            paddingTop: '$3',
          }}
        >
          <Label htmlFor="Coach name">
            Intellect Coaching
            <Input
              id="coach_name"
              disabled
              value={getProvider(userProviderData, '0').provider}
            />
          </Label>
          <Label htmlFor="coach-session-type">
            Coaching session type
            <Input
              disabled
              value={
                userProviderData
                  ? getSessionTypes(userProviderData.credits, '0')
                  : ''
              }
            />
          </Label>
          <Label htmlFor="Coach credits">
            Remaining Credits
            <Input
              id="organisation"
              disabled
              value={getRemainingCredits(watch('userData.credits'), '0')}
            />
          </Label>
          <Label htmlFor="coach-next-session">
            Next session
            <Input
              id="coach-next-session"
              disabled
              value={getProvider(userProviderData, '0').meetings}
            />
          </Label>
        </Grid>
      )}
      {watch('userData.addOns.clinicalEnabled') && (
        <Grid
          align="start"
          columns={2}
          gapX="5"
          gapY="1"
          css={{
            borderTop: `1px solid ${theme.colors.gray200.value}`,
            paddingTop: '$3',
          }}
        >
          <Label htmlFor="clinical-session-type">
            Care session type
            <Input
              id="clinical-session-type"
              disabled
              value={
                userProviderData
                  ? getSessionTypes(userProviderData.credits, '1')
                  : ''
              }
            />
          </Label>
          <Label htmlFor="Clinical credits">
            Remaining Credits
            <Input
              id="clinical_credits"
              disabled
              value={getRemainingCredits(watch('userData.credits'), '1')}
            />
          </Label>
          <Label htmlFor="Clinical name">
            Intellect Care Virtual
            <Input
              id="organisation"
              disabled
              value={getProvider(userProviderData, '1').provider}
            />
          </Label>

          <Label htmlFor="Next session">
            Next session
            <Input
              id="clinical-next-session"
              disabled
              value={getProvider(userProviderData, '1').meetings}
            />
          </Label>
          <Label htmlFor="Clinical name">
            Intellect Care In-person
            <Input
              id="organisation"
              disabled
              value={getProvider(userProviderData, '10').provider}
            />
          </Label>

          <Label htmlFor="Next session">
            Next session
            <Input
              id="clinical-inperson-next-session"
              disabled
              value={getProvider(userProviderData, '10').meetings}
            />
          </Label>
        </Grid>
      )}
      <Flex
        align="center"
        justify="center"
        css={{
          width: '100%',
          mt: '$5',
        }}
      >
        <Button
          size="lg"
          disabled={!watch('sessionId')}
          onClick={() =>
            redirectToMaverick({
              id: watch('userData.userId'),
              email: watch('userData.email'),
              sessionId: watch('sessionId'),
            })
          }
        >
          Match provider
        </Button>
      </Flex>
    </Box>
  );
};
