import { useFormContext, Controller } from 'react-hook-form';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { CaseNoteFormEvent } from '../../../../../../shared/constants/events/CaseNoteForm';
import { LearnMoreRiskAssessmentLink } from '../../../../../../shared/constants/Resources';
import {
  RiskContentData,
  RiskLevels,
} from '../../../../../../shared/constants/Risk';
import { NoOrganisationCode } from '../../../../../../shared/constants/Session';
import { CaseNoteFormTypes } from '../../../../../../shared/types/CaseNoteForm';
import { theme } from '../../../../../../stitches.config';
import { redirectToMaverick } from '../../../../../../utilities/common/Redirection';
import { useCreateClientAndSession } from '../../../../../../utilities/hooks/queryHooks/client/UseCreateClient';
import useTracking from '../../../../../../utilities/hooks/UseTracking';
import { HandHoldingHeartIcon } from './HandHoldingHeartIcon';
import {
  Flex,
  Typography,
  Button,
  Label,
  Tooltip,
  Checkbox,
} from '../../../../../styledComponents';
import { ValueOf } from '../../../../../../shared/types/Common';

const BookEventLabels = {
  [RiskLevels.Low]: 'book_session_low',
  [RiskLevels.Medium]: 'book_session_medium',
  [RiskLevels.High]: 'book_session_high',
  [RiskLevels.HighCritical]: 'book_session_high',
  [RiskLevels.Other]: 'book_session_other',
} satisfies Record<
  ValueOf<typeof RiskLevels>,
  CaseNoteFormEvent['EventLabelType'][number]
>;

export const BookASessionSection = () => {
  const {
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<CaseNoteFormTypes>();
  const { track } = useTracking<CaseNoteFormEvent>();
  const {
    isLoading: isClientAndSessionCreateLoading,
    mutate: mutateClientAndSession,
  } = useCreateClientAndSession({
    onSessionCreateSuccess: (sessionId) =>
      redirectToMaverick({
        id: getValues('userId'),
        email: getValues('email'),
        sessionId,
        phoneNumber: getValues('phone'),
        name: getValues('name') ?? '',
        location: getValues('location') ?? '',
      }),
  });

  const onSessionBookClick = () => {
    const { email, userId, sessionId, phone, id } = getValues();

    if (userId && email && id && sessionId) {
      redirectToMaverick({
        id: userId,
        email,
        sessionId,
        phoneNumber: phone,
        name: getValues('name') ?? '',
        location: getValues('location') ?? '',
      });
    } else {
      // User does not exist, will create client and session
      const { name, location, organisation, tenure, age, employeeId } =
        getValues();
      const nonNullValues = Object.fromEntries(
        Object.entries({
          name,
          email,
          phone,
          location,
          organisation,
          tenure,
          age,
          employeeId,
        }).filter(([_, v]) => !!v),
      );
      mutateClientAndSession(nonNullValues);
    }
  };

  const hasAlreadyBookedSession: boolean = !!watch('meetingData');
  const doesNotWantToBookSession: boolean = watch('doesNotWantToBookSession');
  const anonymous: boolean = watch('clientAnonymous') || watch('orgAnonymous');
  const canBookWhenDependent = () => {
    if (watch('id')) return true;
    if (watch('userId') && watch('coachingOrClinicalEnabled')) return true;
    return false;
  };
  const necessaryInfoFilled: boolean =
    watch(['phone', 'email', 'name', 'organisation']).every(Boolean) &&
    ['phone', 'email', 'name', 'organisation', 'tenure', 'age'].every(
      (val) => val in errors === false,
    ) &&
    // Require tenure and age only for new users
    (watch('id') ? true : watch(['tenure', 'age']).every(Boolean));

  const userIsFromUnknownOrg = watch('organisation') === NoOrganisationCode;

  const riskId = watch('risk');

  const getTooltipLabel = () => {
    if (hasAlreadyBookedSession) {
      return 'You have already booked a session.';
    }
    if (doesNotWantToBookSession) {
      return 'Cannot book session as client does not want to book it.';
    }
    if (anonymous) {
      return 'Cannot book session as client does not want to share further information.';
    }
    if (userIsFromUnknownOrg) {
      return 'Cannot book session as user is not a part of an organisation.';
    }
    if (watch('isDependent') && !canBookWhenDependent()) {
      return 'Cannot book a session as this dependent user is ineligible to book sessions.';
    }
    if (necessaryInfoFilled) {
      return 'Click to schedule a session for the client.';
    }

    return 'Client information is necessary to book a session.';
  };

  const shouldNotAllowToBookSession =
    hasAlreadyBookedSession ||
    doesNotWantToBookSession ||
    !necessaryInfoFilled ||
    anonymous ||
    userIsFromUnknownOrg ||
    (watch('isDependent') && !canBookWhenDependent());

  const handleSessionBookClick = () => {
    const riskEventLabel = BookEventLabels[watch('risk')];
    if (riskEventLabel) {
      track('book_session', {
        eventAction: 'click',
        eventCategory: 'book_session',
        eventLabel: riskEventLabel,
      });
    }

    onSessionBookClick();
  };

  const handleDoesNotWantMeetingChange = () => {
    if (!watch('doesNotWantToBookSession')) {
      track('do_not_book_session', {
        eventAction: 'click',
        eventCategory: 'do_not_book_session',
        eventLabel: 'do_not_book_session',
      });
    }

    setValue('doesNotWantToBookSession', !watch('doesNotWantToBookSession'));
  };

  return (
    <>
      <Flex
        css={{
          border: '1px solid $gray300',
          borderRadius: '$3',
          backgroundColor: 'white',
          padding: '$5',
          marginTop: '$5',
        }}
        align="center"
        gap="6"
      >
        <HandHoldingHeartIcon
          color={theme.colors[RiskContentData[riskId].color.inner].value}
          fill={theme.colors[RiskContentData[riskId].color.outer].value}
        />
        <Typography size="md">
          Get the advice you need. Check the latest Intellect resources for next
          steps.{' '}
          <a // eslint-disable-line react/jsx-no-target-blank
            id={`${RiskContentData[riskId].title.replace(' ', '-')}-resource-link`}
            href={LearnMoreRiskAssessmentLink}
            target="_blank"
            style={{
              color: theme.colors.lightBlue.value,
              fontWeight: 500,
            }}
          >
            Learn more
          </a>
        </Typography>

        {riskId === RiskLevels.HighCritical ? null : (
          <Tooltip label={getTooltipLabel()}>
            <Button
              id={`${RiskContentData[riskId].title.replace(' ', '-')}-book-a-session`}
              type="button"
              size="lg"
              onClick={handleSessionBookClick}
              disabled={shouldNotAllowToBookSession}
              endIcon={<IoArrowForwardCircleOutline size={22} />}
              isLoading={isClientAndSessionCreateLoading}
            >
              Book a session
            </Button>
          </Tooltip>
        )}
      </Flex>

      {riskId === RiskLevels.HighCritical ? null : (
        <>
          {watch('organisation') === NoOrganisationCode && (
            <Typography color="darkRed" size="sm" css={{ mt: '$2' }}>
              Cannot book session as user is not a part of an organisation.
            </Typography>
          )}
          <Controller
            name="doesNotWantToBookSession"
            render={({ field: { value } }) => (
              <Flex gap="2" align="center" css={{ mt: '$1', p: '$5' }}>
                <Checkbox
                  onClick={handleDoesNotWantMeetingChange}
                  checked={value}
                  id="doesNotWantToBookSession"
                  disabled={watch('organisation') === NoOrganisationCode}
                />
                <Label htmlFor="doesNotWantToBookSession" size="md">
                  Client does not want to have a coaching session
                </Label>
              </Flex>
            )}
          />
        </>
      )}
    </>
  );
};
