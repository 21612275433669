import { Navigate, Outlet } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import useAuth from '../utilities/hooks/UseAuth';
import Navbar from './components/navbar/Navbar';
import { Box } from './styledComponents';
import Sidebar from './components/sidebar/Sidebar';
import useTracking from '../utilities/hooks/UseTracking';
import { PathConfig } from '../config/PathConfig';

const ProtectedLayout = () => {
  const { isLoggedIn, user: userDetails } = useAuth();
  const queryClient = useQueryClient();

  const { user } = useTracking();

  React.useEffect(() => {
    if (isLoggedIn) {
      const { id, createdAt, updatedAt } = userDetails;

      user.initialize(id);
      user.setProperties({
        userId: id,
        created: createdAt,
        updated: updatedAt,
      });
    }
  }, [isLoggedIn, user, userDetails]);

  if (isLoggedIn)
    return (
      <Box
        css={{
          display: 'grid',
          height: '100%',
          width: '100%',
          '@sm': {
            gridTemplateColumns: '1fr',
          },
          '@lg': {
            gridTemplateColumns: '1fr 5fr',
          },
          '@xl': {
            gridTemplateColumns: '1fr 6fr',
          },
        }}
      >
        <Sidebar />
        <Box>
          <Navbar />
          <Outlet />
        </Box>
      </Box>
    );

  queryClient.clear();
  return <Navigate to={PathConfig.login} />;
};

export default ProtectedLayout;
