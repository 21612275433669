import { SidebarItem } from '../../../shared/types/SidebarItem';
import { Link } from '../../styledComponents';

export const SidebarLink = ({
  item,
  onClick,
  isCurrentPath,
}: {
  item: SidebarItem;
  onClick: () => void;
  isCurrentPath: boolean;
}) => (
  <Link
    to={item.path}
    id={`sidebar-item-${item.title}`}
    onClick={onClick}
    css={{
      ...(isCurrentPath && { backgroundColor: '$metal200' }),
      borderRadius: '0.5rem',
      display: 'grid',
      gridTemplateColumns: '1fr 4fr',
      gap: '1rem',
      alignItems: 'center',
      padding: '$2 $3',
      outline: 'none',
      transition: 'all 0.2s linear',
      mb: '$2',
      '&:hover': {
        backgroundColor: '$gray300',
        textDecoration: 'none',
      },
    }}
  >
    {item.icon}
    {item.title}
  </Link>
);
