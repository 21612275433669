import mixpanel from 'mixpanel-browser';

import { isProduction } from '../../utilities/common/Path';

const projectKeys = {
  production: '09cb1b98896c769d6da57eb64cbd2d3a',
  development: 'd93980ffe4384221600c6a92b7ac8b78',
  test: '',
};

class Analytics {
  static initialize() {
    mixpanel.init(
      isProduction() ? projectKeys.production : projectKeys.development,
      {
        debug: process.env.NODE_ENV !== 'production',
      },
    );
  }

  static send(event: string, properties: Record<string, string>) {
    mixpanel.track(event, properties);
  }
}

export default Analytics;
