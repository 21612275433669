import { useState } from 'react';
import { IoArrowDown } from 'react-icons/io5';
import { PrsentingIssues } from '../../../shared/constants/PresentingIssues';
import { CaseNoteSession } from '../../../shared/types/Session';
import { useUpdateSession } from '../../../utilities/hooks/queryHooks/caseNotes/UseUpdateSession';
import {
  Button,
  Flex,
  Heading,
  IconButton,
  TextArea,
  Tooltip,
  Typography,
} from '../../styledComponents';
import { useDownloadCaseNoteAttachment } from '../../../utilities/hooks/queryHooks/caseNotes/UseDownloadCaseNoteAttachment';

const CallLogDetails = ({ sessionData }: { sessionData: CaseNoteSession }) => {
  const caseNote = sessionData?.caseNotes?.at(-1);
  const attachment = caseNote?.attachments?.[0];

  const [updatedNote, setUpdatedNote] = useState(caseNote?.note ?? '');

  const { mutate: updateSession } = useUpdateSession();

  const { mutate: downloadAttachment, isLoading } =
    useDownloadCaseNoteAttachment();

  return (
    <>
      <Heading size="sm" css={{ fontWeight: '600', mt: '$4', mb: '$3' }}>
        Call log details
      </Heading>

      <Typography color="gray600" css={{ mb: '$1' }}>
        Presenting issue
      </Typography>
      <Typography css={{ mb: '$2', lineHeight: '$1', whiteSpace: 'pre-line' }}>
        {sessionData.sessionType === 1
          ? sessionData.issue || 'N/A'
          : PrsentingIssues.flatMap((category) => category.options).find(
              (issue) => issue.value === sessionData.issue,
            )?.label || 'N/A'}
      </Typography>
      <Typography color="gray600" css={{ mb: '$1' }}>
        Note
      </Typography>

      {sessionData.sessionType === 1 && sessionData.status === '0' ? (
        <TextArea
          id="care-nav-notes"
          value={updatedNote}
          onChange={(event) => setUpdatedNote(event.target.value)}
        />
      ) : (
        <Typography
          css={{
            my: '$2',
            lineHeight: '$1',
            whiteSpace: 'pre-line',
          }}
        >
          {caseNote?.note ?? 'N/A'}
        </Typography>
      )}

      {attachment && (
        <>
          <Typography color="gray600">Attachment</Typography>
          <Flex justify="start" align="center" gap="1">
            <Typography
              style={{
                maxWidth: '30%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {attachment?.fileName}
            </Typography>
            <Tooltip label="Click to download file">
              <IconButton
                type="button"
                disabled={isLoading}
                onClick={() => {
                  if (attachment) {
                    downloadAttachment({
                      attachment,
                      sessionId: sessionData?.id,
                    });
                  }
                }}
              >
                <IoArrowDown size={16} />
              </IconButton>
            </Tooltip>
          </Flex>
        </>
      )}

      <Flex justify="end" css={{ mt: '$2' }}>
        <Typography size="xs" color="gray500">
          {sessionData.responderName}
        </Typography>
      </Flex>
      {sessionData.sessionType === 1 && sessionData.status === '0' && (
        <Button
          onClick={() => {
            updateSession({
              status: '1',
              clientId: sessionData.clientId,
              sessionId: sessionData.id,
              sessionType: `${sessionData.sessionType}`,
              issue: sessionData.issue || '',
              caseNotes: updatedNote || '',
              startTime: sessionData.startTime || new Date().toISOString(),
              endTime: sessionData.endTime || new Date().toISOString(),
              risk: sessionData.risk,
              meetingConsent: sessionData.meetingConsent || 'no',
            });
          }}
        >
          Mark as done
        </Button>
      )}
    </>
  );
};

export default CallLogDetails;
