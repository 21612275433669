import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ApiConfig from '../../../../config/ApiConfig';
import { PathConfig } from '../../../../config/PathConfig';
import { doPatch } from '../../../../shared/service/NetworkClient';
import { EditSessionFormTypes } from '../../../../shared/types/CaseNoteForm';

export const useEditSession = () => {
  const navigate = useNavigate();
  return useMutation(
    ({
      sessionId,
      name,
      age,
      location,
      tenure,
      employeeId,
      phone,
      risk,
      issue,
      assessment,
    }: EditSessionFormTypes) =>
      doPatch(ApiConfig.api.sessions.edit.replace('{{sessionId}}', sessionId), {
        name,
        age,
        location,
        tenure,
        organisationData: { employeeId },
        phone,
        risk,
        issue,
        assessment,
      }).then((res) => res.data.data),
    {
      onSuccess: (_, variables) => {
        toast.success('Session edited successfully.');
        navigate(`/${PathConfig.callLogs}/${variables.sessionId}`);
      },
      onError: () => {
        toast.error('An error occurred while editing this session.');
      },
    },
  );
};
