import { DefaultToastOptions } from 'react-hot-toast';
import {
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
} from 'react-icons/io5';

import { theme } from '../stitches.config';

export const ToasterStyles: DefaultToastOptions = {
  duration: 4000,
  style: {
    color: '#fff',
    fontSize: theme.fontSizes[3].value,
  },

  // Default options for specific types
  error: {
    icon: <IoAlertCircleOutline fill={theme.colors.darkRed.value} size={32} />,

    iconTheme: {
      primary: theme.colors.lightRed.value,
      secondary: theme.colors.darkRed.value,
    },
    style: {
      color: theme.colors.darkRed.value,
      backgroundColor: theme.colors.lightRed.value,
    },
  },
  success: {
    icon: (
      <IoCheckmarkCircleOutline fill={theme.colors.darkRed.value} size={32} />
    ),
    iconTheme: {
      primary: theme.colors.lightGreen.value,
      secondary: theme.colors.darkGreen.value,
    },
    style: {
      color: theme.colors.darkGreen.value,
      backgroundColor: theme.colors.lightGreen.value,
    },
  },
};
