import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { SessionEvent } from '../../../shared/constants/events/SessionDetail';
import { RiskLevels } from '../../../shared/constants/Risk';
import { EditSessionFormTypes } from '../../../shared/types/CaseNoteForm';
import { useEditSession } from '../../../utilities/hooks/queryHooks/caseNotes/UseEditSession';
import useTracking from '../../../utilities/hooks/UseTracking';
import { Flex, Button } from '../../styledComponents';
import { ConfirmDiscardModal } from './ConfirmDiscardModal';

export const EditFormConfirmation = () => {
  const {
    watch,
    formState: { isDirty },
  } = useFormContext<EditSessionFormTypes>();

  const navigate = useNavigate();

  const { track } = useTracking<SessionEvent>();

  const [showConfirmDiscardModal, setShowConfirmDiscardModal] = useState(false);
  const handleGoBack = () => {
    track('view_detail', {
      eventAction: 'click',
      eventCategory: 'exit_cancel',
      eventLabel: 'exit_cancel',
    });
    if (isDirty) {
      setShowConfirmDiscardModal(true);
    } else {
      navigate(`/${PathConfig.callLogs}/${watch('sessionId')}`);
    }
  };

  const handleConfirmDiscard = () => {
    track('view_detail', {
      eventAction: 'click',
      eventCategory: 'discard_edit',
      eventLabel: 'discard_edit',
    });
    navigate(`/${PathConfig.callLogs}/${watch('sessionId')}`);
  };

  const handleClose = () => {
    track('view_detail', {
      eventAction: 'click',
      eventCategory: 'back',
      eventLabel: 'back',
    });
    setShowConfirmDiscardModal(false);
  };

  const { mutate, isLoading } = useEditSession();

  const handleSubmit = () => {
    track('view_detail', {
      eventAction: 'click',
      eventCategory: 'save_information',
      eventLabel: 'save_information',
    });
    mutate(watch());
  };

  const canConfirmEdit = () => {
    if (
      [RiskLevels.High, RiskLevels.HighCritical].some(
        (r) => r === watch('risk'),
      )
    ) {
      return watch('assessment')
        .filter((answer) => answer.isRequired)
        .every((answer) => answer.response);
    }

    return true;
  };

  return (
    <Flex justify="between" css={{ mt: '$6' }} gap="3">
      <Button
        type="button"
        id="edit-session-button"
        onClick={handleGoBack}
        size="lg"
        variant="outlined"
      >
        Cancel and Exit
      </Button>
      <Button
        type="button"
        isLoading={isLoading}
        onClick={handleSubmit}
        size="lg"
        disabled={!canConfirmEdit()}
      >
        Save Information
      </Button>

      <ConfirmDiscardModal
        open={showConfirmDiscardModal}
        handleClose={handleClose}
        handleSubmit={handleConfirmDiscard}
      />
    </Flex>
  );
};
