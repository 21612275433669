import { IoPersonCircleOutline } from 'react-icons/io5';

import useAuth from '../../../utilities/hooks/UseAuth';
import { Button, Flex, Popover } from '../../styledComponents';
import ResponderContent from './ResponderContent';

const Navbar = () => {
  const {
    user: { name },
  } = useAuth();
  return (
    <Flex
      align="center"
      justify="end"
      css={{
        width: '100%',
        height: '8vh',
        pr: '$5',
        borderBottom: '1px solid',
        borderColor: '$ghostwhite',
      }}
    >
      <Popover
        render={
          <Button
            id="user-profile"
            startIcon={<IoPersonCircleOutline size={26} />}
            variant="ghost"
            size="lg"
            pill={false}
          >
            {name}
          </Button>
        }
      >
        <ResponderContent />
      </Popover>
    </Flex>
  );
};

export default Navbar;
