import { forwardRef, ReactNode } from 'react';
import { CSS, VariantProps } from '../../stitches.config';
import { Typography } from './Typography';

type TextSizeVariants = Pick<VariantProps<typeof Typography>, 'size'>;
type HeadingSizeVariants = 'sm' | 'md' | 'lg' | 'xl';

const textSize: Record<HeadingSizeVariants, TextSizeVariants['size']> = {
  sm: { '@initial': 'lg', '@bp2': 'xl' },
  md: { '@initial': '2xl', '@bp2': '3xl' },
  lg: { '@initial': '3xl', '@bp2': '4xl' },
  xl: { '@initial': '4xl', '@bp2': '5xl' },
};
export type HeadingProps = Pick<VariantProps<typeof Typography>, 'color'> & {
  size?: HeadingSizeVariants;
  children: ReactNode;
  css?: CSS;
};
export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ size = 'md', children, color, css }, ref) => (
    <Typography
      ref={ref}
      as="h1"
      size={textSize[size]}
      color={color}
      css={{ fontWeight: '500', ...css }}
    >
      {children}
    </Typography>
  ),
);
