import { keyframes } from '../../../stitches.config';
import { Box } from '../../styledComponents';
import Container from '../container/Container';

const dotStyle = 'no-repeat radial-gradient(#000 68%, #0000 71%)';

const moveDots = keyframes({
  '0%': { WebkitMaskPosition: '0% 0%, 50% 0%, 100% 0%' },
  '16.67%': { WebkitMaskPosition: '0% 100%, 50% 0%, 100% 0%' },
  '33.33%': { WebkitMaskPosition: '0% 100%, 50% 100%, 100% 0%' },
  '50%': { WebkitMaskPosition: '0% 100%, 50% 100%, 100% 100%' },
  '66.67%': { WebkitMaskPosition: '0% 0%, 50% 100%, 100% 100%' },
  '83.33%': { WebkitMaskPosition: '0% 0%, 50% 0%, 100% 100%' },
  '100%': { WebkitMaskPosition: '0% 0%, 50% 0%, 100% 0%' },
});

const LoadingView = ({ size = 'lg' }: { size?: 'md' | 'lg' }) => (
  <Container
    css={{
      height: '25%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box
      css={{
        width: size === 'lg' ? '5rem' : '3rem',
        aspectRatio: '8/5',
        mask: `${dotStyle},${dotStyle},${dotStyle}`,
        maskSize: '25% 40%',
        WebkitMask: `${dotStyle}, ${dotStyle}, ${dotStyle}`,
        WebkitMaskSize: '25% 40%',
        background: '$gray500',
        animation: `${moveDots} 2s infinite`,
      }}
    />
  </Container>
);

export default LoadingView;
