import { styled } from '../../stitches.config';

export const Caption = styled('caption', {
  textAlign: 'start',
  marginBottom: '$5',
});

export const Tfoot = styled('tfoot', {});

export const Tr = styled('tr', {});

export const Td = styled('td', {
  p: '1rem 1.5rem',
  color: '$gray800',
  borderWidth: '0 0 1px 0', // bottom border for all cells
  borderStyle: 'solid',
  borderColor: '$gray100',
  fontSize: '$3',
  variants: {
    align: {
      start: {
        textAlign: 'start',
      },
      center: {
        textAlign: 'center',
      },
      end: {
        textAlign: 'end',
      },
    },
  },
  defaultVariants: {
    align: 'start',
  },
});

export const Tbody = styled('tbody', {
  width: '100%',
  padding: '0.25rem',

  [`& ${Td}:first-child`]: {
    borderWidth: '0 0 1px 1px', // No right border for leftmost cell
  },
  [`& ${Td}:last-child`]: {
    borderWidth: '0 1px 1px 0', // No left border for rightmost cell
  },
  [`& ${Tr}:last-child`]: {
    [`& ${Td}:first-child`]: {
      borderRadius: '0 0 0 0.5rem', // Curved bottom-left border for last row's leftmost cell
    },
    [`& ${Td}:last-child`]: {
      borderRadius: '0 0 0.5rem 0', // Curved bottom-right border for last row's rightmost cell
    },
  },
  [`& ${Tr}:first-child`]: {
    [`& ${Td}`]: {
      borderWidth: '1px 0px 1px 0px', // No right border for leftmost cell
    },
    [`& ${Td}:first-child`]: {
      borderRadius: '0.5rem 0 0 0', // Curved bottom-left border for last row's leftmost cell
      borderWidth: '1px 0px 1px 1px', // No right border for leftmost cell
    },
    [`& ${Td}:last-child`]: {
      borderRadius: '0 0.5rem 0 0', // Curved bottom-right border for last row's rightmost cell
      borderWidth: '1px 1px 1px 0', // No right border for leftmost cell
    },
  },
});

export const Th = styled('th', {
  fontSize: '$2',
  fontWeight: '500',
  textAlign: 'start',
  p: '0.75rem 1.5rem',
  color: '$gray500',
  backgroundColor: '$gray50',
  borderWidth: '1px 0', // Top and bottom border for header cells
  borderStyle: 'solid',
  borderColor: '$gray100',
  variants: {
    align: {
      start: {
        textAlign: 'start',
      },
      center: {
        textAlign: 'center',
      },
      end: {
        textAlign: 'end',
      },
    },
  },
  defaultVariants: {
    align: 'start',
  },
});

export const Thead = styled('thead', {
  color: '$gray500',
  [`& ${Th}:first-child`]: {
    borderRadius: '0.5rem 0 0', // Curved top-left border for header's leftmost cell
    borderWidth: '1px 0 1px 1px',
  },
  [`& ${Th}:last-child`]: {
    borderRadius: '0 0.5rem 0 0', // Curved top-right border for header's rightmost cell
    borderWidth: '1px 1px 1px 0',
  },
});

export const Table = styled('table', {
  width: '100%',
  tableLayout: 'fixed',
  borderSpacing: 0,
  borderCollapse: 'separate',
  variants: {
    striped: {
      true: {
        [`& ${Tbody}`]: {
          [`& ${Tr}`]: {
            '&:nth-child(odd)': {
              bc: '$gray100',
            },
          },
        },
      },
    },
  },
});
