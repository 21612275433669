import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { RiskColorVariants, RiskLabels } from '../../../shared/constants/Risk';
import { SessionListItem } from '../../../shared/types/Session';
import { SessionsListResponse } from '../../../utilities/hooks/queryHooks/callLogs/useGetSessionsListV2';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import LoadingView from '../../components/loadingView/LoadingView';
import {
  Table,
  Tbody,
  Td,
  Typography,
  Tag,
  Button,
  Tr,
} from '../../styledComponents';

export const CallLogList = ({
  sessionsData,
}: {
  sessionsData: SessionsListResponse;
}) => {
  const { data: orgsList, isLoading: isOrgsListLoading } =
    useGetOrganisationsList();

  if (isOrgsListLoading || !orgsList) {
    return <LoadingView />;
  }

  return sessionsData.sessions.length ? (
    <Table>
      <Tbody>
        {sessionsData.sessions.map((item: SessionListItem) => (
          <Tr key={item.id}>
            <Td>
              {item.client?.name ?? 'Anonymous'}

              <Typography css={{ mt: '$1' }} size="xs" color="gray600">
                {orgsList.find(
                  (org) =>
                    org.id === parseInt(item.client?.organisation || '-1', 10),
                )?.name ?? 'Organisation unavailable'}
              </Typography>
            </Td>
            <Td
              css={{
                fontSize: 'small',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {item.client?.email || 'N/A'}
            </Td>
            <Td>{format(new Date(item.startTime), 'MMM dd, yyyy')}</Td>
            <Td>
              <Tag variant={RiskColorVariants[item.risk]}>
                {RiskLabels[item.risk]}
              </Tag>
            </Td>
            <Td align="end">
              <Link to={`/${PathConfig.callLogs}/${item.id}`}>
                <Button
                  id={`view-session-${item.id}`}
                  key={item.id}
                  variant="outlined"
                  size="md"
                  color="darkMetal"
                >
                  View
                </Button>
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : (
    <Typography size="md" css={{ fontWeight: 500 }}>
      No call logs were found.
    </Typography>
  );
};
