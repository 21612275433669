import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { handleQueryError } from './utilities/common/HandleQueryError';
import { initializeMonitoring } from './utilities/common/InitializeMonitoring';
import Analytics from './shared/service/Analytics';

initializeMonitoring();
Analytics.initialize();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: handleQueryError,
  }),
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
    mutations: {
      onError(error, variables, context) {
        console.log(error, variables, context);
        const { data } = error as AxiosResponse;
        toast.error(
          `Something went wrong: ${data.error.statusCode} - ${data.error.message} `,
        );
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
