import Modal from '../../components/modal/Modal';

export const ConfirmDiscardModal = ({
  open,
  handleClose,
  handleSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) => (
  <Modal
    title="Are you sure you want to stop editing?"
    subtitle="You have unsaved data in the form. Are you sure you want to discard it?"
    confirmButtonColor="red"
    confirmButtonText="Discard"
    open={open}
    handleClose={handleClose}
    handleSubmit={handleSubmit}
  />
);
