import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

import { Flex } from '../../styledComponents';

const MotionFlex = motion(Flex);

const Backdrop = ({
  children,
  open,
  onClick,
}: {
  children: ReactNode;
  open: boolean;
  onClick: () => void;
}) => (
  <AnimatePresence initial={false} mode="wait">
    {open && (
      <MotionFlex
        align="center"
        justify="center"
        onClick={onClick}
        initial={{ background: 'rgba(0, 0, 0, 0)', backdropFilter: 'none' }}
        animate={{
          background: 'rgba(16, 24, 40, 0.8)',
          backdropFilter: 'blur(.25rem)',
        }}
        exit={{ background: 'rgba(0, 0, 0, 0)', backdropFilter: 'none' }}
        transition={{ duration: 0.25 }}
        css={{
          position: 'fixed',
          top: '0',
          left: '0',
          height: '100%',
          width: '100%',
          background: '$gray900',
          overflowY: 'hidden',
          zIndex: '$1',
        }}
      >
        {children}
      </MotionFlex>
    )}
  </AnimatePresence>
);

export default Backdrop;
