import { useLocation } from 'react-router-dom';
import { SidebarItem } from '../../../shared/types/SidebarItem';
import wordmark from '../../../assets/images/wordmark.svg';
import {
  responderSidebarItems,
  careNavSidebarItems,
} from '../../../shared/constants/SidebarItems';
import { Box, Image } from '../../styledComponents';
import { SidebarLink } from './SidebarLink';
import useAuth from '../../../utilities/hooks/UseAuth';
import useTracking from '../../../utilities/hooks/UseTracking';
import { CommonEvent } from '../../../shared/constants/events/Common';

const Sidebar = () => {
  const location = useLocation();
  const { user } = useAuth();

  const { track } = useTracking<CommonEvent>();

  const eventTrackOnClick = (eventString?: SidebarItem['eventString']) => {
    if (eventString) {
      track(eventString, {
        eventAction: 'click',
        eventCategory: eventString,
        eventLabel: eventString,
      });
    }
  };

  return (
    <Box
      css={{
        backgroundColor: '$ghostwhite',
        '@sm': {
          display: 'none',
        },
        '@lg': {
          display: 'block',
        },
      }}
    >
      <Box
        css={{
          height: '8vh',
          borderBottom: '1px solid',
          borderColor: '$gray100',
          display: 'flex',
          pl: '$6',
        }}
      >
        <Image
          alt="Intellect Wordmark"
          src={wordmark}
          css={{
            '@sm': {
              width: '90px',
              maxWidth: '90px',
            },
            '@xl': {
              width: '120px',
              maxWidth: '120px',
            },
          }}
        />
      </Box>
      <Box css={{ padding: '$4', mt: '$7' }}>
        {(user?.addOns?.isCareNavigator
          ? careNavSidebarItems
          : responderSidebarItems
        ).map((item: SidebarItem) => (
          <SidebarLink
            key={item.title}
            onClick={() => eventTrackOnClick(item.eventString)}
            item={item}
            isCurrentPath={location.pathname === item.path}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Sidebar;
