import { Link as RouterLink } from 'react-router-dom';

import { styled } from '../../stitches.config';
import { Typography } from './Typography';

export const Link = styled(RouterLink, {
  alignItems: 'center',
  gap: '$1',
  flexShrink: 0,
  outline: 'none',
  color: 'inherit',
  textDecorationLine: 'none',
  textUnderlineOffset: '3px',
  textDecorationColor: '$gray100',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  lineHeight: 'inherit',

  [`& ${Typography}`]: {
    color: 'inherit',
  },

  variants: {
    noUnderline: {
      true: {},
      false: {
        '@hover': {
          '&:hover': {
            textDecorationLine: 'underline',
          },
        },
      },
    },
  },

  defaultVariants: {
    noUnderline: false,
  },
});
