export const PrsentingIssues = [
  {
    title: 'Work related',
    options: [
      { label: 'Work Life Balance', value: 'Work Life Balance' },
      {
        label: 'Conflict at Work (Supervisor/Peer)',
        value: 'Conflict at Work',
      },
      {
        label: 'Environment (Benefits/Scheduling/Culture/Acclimatization)',
        value: 'Environment',
      },
      {
        label: 'Work Performance/Speed of Work/Quality Targets/KPI',
        value: 'Work Performance/Speed of Work/Quality Targets/KPI',
      },
      {
        label: 'Abuse/ Harassment / Bullying',
        value: 'Abuse/Harassment/Bullying',
      },
      {
        label:
          'Physically and emotionally affected by shift hours (e.g. insufficient breaks, difficulty adapting to shifts)',
        value: 'Physically and emotionally affected by shift hours',
      },
      {
        label: 'Insufficient career development opportunities',
        value: 'Insufficient career development opportunities',
      },
      {
        label: 'Dissatisfaction with management',
        value: 'Dissatisfaction with management',
      },
      {
        label: 'Unfair treatment/Lack of recognition',
        value: 'Unfair treatment/Lack of recognition',
      },
      {
        label: 'Demotivated (e.g. mundane, repetitive)',
        value: 'Demotivated (e.g. mundane, repetitive)',
      },
    ],
  },
  {
    title: 'Content related',
    options: [
      {
        label: 'Affected by content (e.g. violent, abuse, gore, sexual, etc.)',
        value: 'Affected by content',
      },
      {
        label: 'Related to personal issues (e.g. past trauma, abuse, etc.)',
        value: 'Related to personal issues',
      },
      {
        label: 'Long exposure to content',
        value: 'Long exposure to content',
      },
      {
        label: 'Emotional detachment due to content',
        value: 'Emotional detachment due to content',
      },
      {
        label: 'Heightened arousal due to content',
        value: 'Heightened arousal due to content',
      },
    ],
  },
  {
    title: 'School related',
    options: [
      {
        label:
          'Academic - Study skills and habits / Time Management / Learning Style',
        value: 'Academic',
      },
      {
        label:
          'Bullying (Physical, relational, verbal, cyber, emotional)- Perpetrator',
        value: 'Bullying',
      },
      { label: 'Stress', value: 'Stress' },
      { label: 'Anxiety/ Exam anxiety', value: 'Anxiety/ Exam anxiety' },
      { label: 'Adjustment issues', value: 'Adjustment issues' },
      { label: 'Others', value: 'Others' },
    ],
  },
  {
    title: 'Personal',
    options: [
      { label: 'Grief/Loss', value: 'Grief/Loss' },
      { label: 'Financial Coaching', value: 'Financial Coaching' },
      { label: 'Physical Health Concerns', value: 'Physical Health Concerns' },
      {
        label: 'Career Guidance/Management/Planning',
        value: 'Career Guidance/Management/Planning',
      },
      {
        label: 'Family Concerns (Immediate Family)',
        value: 'Family Concerns',
      },
      {
        label: 'Spouse/Significant Other (Relationship Issues)',
        value: 'Spouse/Significant Other',
      },
      {
        label: 'Mood/ Affect (Morale/episodic Issues)',
        value: 'Mood/ Affect',
      },
      {
        label: 'Personal Growth & Enrichment (Individual Growth and Wellbeing)',
        value: 'Personal Growth & Enrichment',
      },
      { label: 'LGBTQIA+', value: 'LGBTQIA+' },
      {
        label: 'Diversity Inclusion & Equality',
        value: 'Diversity Inclusion & Equality',
      },
      { label: 'Children/ Youth related', value: 'Children/ Youth related' },
      {
        label:
          'Trauma related (not diagnosed PTSD/ Stress or trauma related disorders)',
        value: 'Trauma related',
      },
      { label: 'Addiction Issues', value: 'Addiction Issues' },
      { label: 'Substance usage (drugs/alcohol)', value: 'Substance usage' },
      { label: 'Self-harm/ Suicidality', value: 'Self-harm/Suicidality' },
      { label: 'Harm to others', value: 'Harm to others' },
      {
        label: 'Victim of abuse/ Domestic abuse',
        value: 'Victim of abuse/Domestic abuse',
      },
      { label: 'Sexual misconduct', value: 'Sexual misconduct' },
      { label: 'Body Image / Self-esteem', value: 'Body Image/Self-esteem' },
      {
        label: 'Social skills/ Emotional intelligence',
        value: 'Social skills/Emotional intelligence',
      },
    ],
  },
  {
    title: 'Diagnosed Mental Health Conditions/ Disorders',
    options: [
      {
        label:
          'Anxiety disorders (Mutism, phobias, social anxiety, panic, GAD, etc)',
        value: 'Anxiety disorders',
      },
      {
        label: 'Bipolar and related disorders  (BP I, BP II, Cyclothymia)',
        value: 'Bipolar and related disorders',
      },
      {
        label: 'Depressive disorders (Disruptive mood, MDD, Dysthymia, etc)',
        value: 'Depressive disorders',
      },
      {
        label:
          'Disruptive, impulse-control and conduct disorders (ODD, Intermittent explosive disorder, CD, Pyromania, Kleptomania)',
        value: 'Disruptive, impulse-control and conduct disorders',
      },
      {
        label: 'Elimination disorders (Enuresis, Encopresis, etc)',
        value: 'Elimination disorders',
      },
      {
        label:
          'Feeding and eating disorders (Pica, Rumination/Re-chewing, Food intake, AN, BN, Binging eating)',
        value: 'Feeding and eating disorders',
      },
      { label: 'Gender dysphoria', value: 'Gender dysphoria' },
      {
        label:
          'Medication-induced movement disorders and other adverse effects of medication (eg, Tardive dyskinesia, Anti-depressant discontinuation syndrome)',
        value:
          'Medication-induced movement disorders and other adverse effects of medication',
      },
      {
        label:
          'Neurocognitive disorders (Delirium, Alzheimer’s, Traumatic brain injury, Parkinson’s, Huntington’s, Lewy body dementia, Prion disease, etc)',
        value: 'Neurocognitive disorders',
      },
      {
        label:
          'Neurodevelopmental (ID, GDD, ASD, ADHD, Learning disorders, Motor disorders)',
        value: 'Neurodevelopmental',
      },
      {
        label:
          'Obsessive-Compulsive and related disorders (OCD, Body dysmorphia, hoarding, trichotillomania, excoriation)',
        value: 'Obsessive-Compulsive and related disorders',
      },
      {
        label:
          'Other mental disorders (eg. Mental disorders due to medication condition)',
        value: 'Mental disorders due to medication condition',
      },
      {
        label:
          'Paraphilic disorders (Voyeuristic, Exhibitionistic, Frotteuristic, Masochism, Sadism, Pedophilic, Fetishistic, Transvestic, etc)',
        value: 'Paraphilic disorders',
      },
      {
        label:
          'Personality disorders (General, Paranoid, Schizoid, Schizotypal, Antisocial, Borderline, Histrionic, Narcissistic, Avoidant, Dependent, OCPD, medical condition-induced)',
        value: 'Personality disorders',
      },
      {
        label:
          'Sleep-wake disorders (Insomnia, Hypersomnolence, Narcolepsy, Apnoeas and Hyopneas, Hyporventilation, Circadian misalignment, Parasomnias, Nightmares, Restless leg)',
        value: 'Sleep-wake disorders',
      },
      {
        label:
          'Somatic symptom and related disorders (Somatic symptoms, Illness anxiety, Conversion/Psychogenic, Factitious)',
        value: 'Somatic symptom and related disorders',
      },
      {
        label:
          'Substance-related and addictive disorders (Alcohol, Caffeine, Cannabis, Hallucinogen, Inhalant, Opioid, Sedative/Hypnotic/Anxiolytic, Stimulant, Tobacco, Gambling)',
        value: 'Substance-related and addictive disorders',
      },
      {
        label:
          'Trauma and stress related disorders (Reactive attachment, Disinhibition, PTSD, ASD, Adjustment)',
        value: 'Trauma and stress related disorders ',
      },
    ],
  },
  {
    title: '24/7 Helpline Related',
    options: [
      {
        label: 'Invalid call/ called wrongly',
        value: 'Invalid call/ called wrongly',
      },
      {
        label: 'Caller disconnected - called back but no answer',
        value: 'Caller disconnected - called back but no answer',
      },
      {
        label: 'Requesting immediate help (escalation needed)',
        value: 'Requesting immediate help (escalation needed)',
      },
      {
        label:
          'Requesting information about mental health topics/ Intellect services',
        value:
          'Requesting information about mental health topics/Intellect services',
      },
      {
        label: 'Technical support/ App related issue/ booking related',
        value: 'Technical support/App related issue/booking related',
      },
      {
        label:
          'Provider session related eg. reschedule, provider did not turn up etc.',
        value: 'Provider session related',
      },
    ],
  },
];
