import { Flex, Typography } from '../../styledComponents';

const LoginRightBox = () => (
  <Flex
    align="center"
    justify="center"
    css={{
      backgroundColor: '$metal',
      position: 'relative',
      overflow: 'hidden',
      '@sm': {
        display: 'none',
      },
      '@md': {
        display: 'flex',
      },
    }}
  >
    <Flex direction="column">
      <svg
        width="65"
        height="17"
        viewBox="0 0 65 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ alignSelf: 'flex-end' }}
      >
        <path
          d="M0.441406 16.6926H64.4414V0.692627H0.441406V16.6926Z"
          fill="white"
        />
      </svg>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ alignSelf: 'flex-end', marginRight: '5rem' }}
      >
        <rect width="16" height="16" fill="#FCAB32" />
      </svg>
      <svg
        width="40"
        height="36"
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ alignSelf: 'flex-start' }}
      >
        <path
          d="M36.25 18H30V13C30 10.2656 32.1875 8 35 8H35.625C36.6406 8 37.5 7.21875 37.5 6.125V2.375C37.5 1.35938 36.6406 0.5 35.625 0.5H35C28.0469 0.5 22.5 6.125 22.5 13V31.75C22.5 33.8594 24.1406 35.5 26.25 35.5H36.25C38.2812 35.5 40 33.8594 40 31.75V21.75C40 19.7188 38.2812 18 36.25 18ZM13.75 18H7.5V13C7.5 10.2656 9.6875 8 12.5 8H13.125C14.1406 8 15 7.21875 15 6.125V2.375C15 1.35938 14.1406 0.5 13.125 0.5H12.5C5.54688 0.5 0 6.125 0 13V31.75C0 33.8594 1.64062 35.5 3.75 35.5H13.75C15.7812 35.5 17.5 33.8594 17.5 31.75V21.75C17.5 19.7188 15.7812 18 13.75 18Z"
          fill="#FCAB32"
        />
      </svg>
      <Typography
        size={{
          '@sm': 'xl',
          '@md': '2xl',
        }}
        css={{
          color: 'white',
          fontWeight: 'lighter',
          m: '2.5rem',
          lineHeight: '50px',
          maxWidth: '25vw',
        }}
      >
        Intellect is a modern-day mental health company that makes it easy for
        workforces to get an on-demand access to mental wellbeing support.
      </Typography>

      <Flex justify="between" align="center">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15.1899 24.8101V0H0V40H40V24.8101H15.1899Z" fill="white" />
        </svg>
        <Flex align="center">
          <svg
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: '4rem' }}
          >
            <path
              d="M0 49C27.062 49 49 27.062 49 0H31C31 17.1208 17.1208 31 0 31V49Z"
              fill="white"
            />
          </svg>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.558594"
              y="0.092041"
              width="16"
              height="16"
              fill="#FCAB32"
            />
          </svg>
        </Flex>
      </Flex>

      <svg
        width="176"
        height="260"
        viewBox="0 0 176 260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: 'absolute',
          width: '481.69px',
          height: '153px',
          left: '55%',
          bottom: '5%',
        }}
      >
        <path
          opacity="0.05"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M482.811 111.732L69.8023 1.0664C64.2744 -0.414815 58.3762 1.1693 54.3342 5.2208L5.0277 54.6434C0.99996 58.6806 -0.574509 64.5565 0.895023 70.0667L18.8844 137.521C20.3591 143.051 24.6751 147.372 30.203 148.853L443.994 259.728L482.811 111.732Z"
          fill="url(#paint0_linear_1894_38293)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1894_38293"
            x1="18.268"
            y1="-12.5311"
            x2="482.3"
            y2="111.806"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0.01" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="258"
        height="159"
        viewBox="0 0 258 159"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: 'absolute',
          width: '481.69px',
          height: '153px',
          left: '50%',
          bottom: '0',
        }}
      >
        <path
          opacity="0.05"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M482.811 111.732L69.8023 1.06646C64.2744 -0.414754 58.3762 1.16936 54.3342 5.22086L5.0277 54.6434C0.99996 58.6807 -0.574509 64.5565 0.895023 70.0668L18.8844 137.521C20.3591 143.051 24.6751 147.372 30.203 148.853L443.994 259.728L482.811 111.732Z"
          fill="url(#paint0_linear_1894_38292)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1894_38292"
            x1="18.268"
            y1="-12.531"
            x2="482.3"
            y2="111.806"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0.01" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 319 372"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: 'absolute',
          width: '300px',
          height: '786px',
          left: '0%',
          top: '-20%',
        }}
      >
        <path
          opacity="0.15"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-274 372C53.5049 372 319 106.505 319 -221C319 -548.505 53.5049 -814 -274 -814C-601.505 -814 -867 -548.505 -867 -221C-867 106.505 -601.505 372 -274 372ZM-274 172C-491.048 172 -667 -3.95209 -667 -221C-667 -438.048 -491.048 -614 -274 -614C-56.9521 -614 119 -438.048 119 -221C119 -3.95209 -56.9521 172 -274 172Z"
          fill="url(#paint0_linear_1894_38288)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1894_38288"
            x1="319"
            y1="-812.419"
            x2="-863.838"
            y2="-812.419"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.01" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </Flex>
  </Flex>
);

export default LoginRightBox;
