import { IoHomeOutline, IoPeopleOutline } from 'react-icons/io5';
import { PathConfig } from '../../config/PathConfig';

import { SidebarItem } from '../types/SidebarItem';

export const responderSidebarItems = [
  {
    title: 'Home',
    path: PathConfig.responder,
    icon: <IoHomeOutline size={26} />,
    eventString: 'home',
  },
  {
    title: 'Call logs',
    path: `${PathConfig.responder}/${PathConfig.callLogs}`,
    icon: <IoPeopleOutline size={26} />,
    eventString: 'call_logs',
  },
] as SidebarItem[];

export const careNavSidebarItems = [
  {
    title: 'Care Navigator',
    path: PathConfig.careNavigator,
    icon: <IoHomeOutline size={26} />,
  },
  // {
  //   title: 'Clients',
  //   path: pathConfig.clientsHome,
  //   icon: <IoPeopleOutline size={26} />,
  // },
] as SidebarItem[];
