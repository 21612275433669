import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { IoMdCheckmark } from 'react-icons/io';
import { forwardRef } from 'react';

import { CSS, styled, VariantProps } from '../../stitches.config';

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  backgroundColor: 'white',
  width: 14,
  height: 14,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 2px 10px $gray500',
  border: '2px solid $gray600',
  transition: 'all 0.1s linear',
  '&:hover:not(:disabled)': { backgroundColor: '$gray200' },
  '&:focus': { boxShadow: '0 0 0 2px $gray900' },
  "&[aria-checked='true']:not(:disabled)": {
    backgroundColor: '$gray900',
    color: 'White',
    border: '2px solid $gray900',
  },
  '&:disabled': {
    color: 'White',
    cursor: 'not-allowed',
    borderColor: '$gray300',
  },
  "&[aria-checked='true']:disabled": {
    backgroundColor: '$gray500',
    color: 'White',
    border: '2px solid $gray500',
  },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
});

type CheckboxPrimitiveProps = React.ComponentProps<
  typeof CheckboxPrimitive.Root
>;
type CheckboxVariants = VariantProps<typeof StyledCheckbox>;
type CheckboxProps = CheckboxPrimitiveProps & CheckboxVariants & { css?: CSS };

export const Checkbox = forwardRef<
  React.ElementRef<typeof StyledCheckbox>,
  CheckboxProps
>((props, forwardedRef) => (
  <StyledCheckbox {...props} ref={forwardedRef}>
    <StyledIndicator>
      <IoMdCheckmark size={12} fontWeight="bold" />
    </StyledIndicator>
  </StyledCheckbox>
));
