import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ApiConfig from '../../../../config/ApiConfig';
import { PathConfig } from '../../../../config/PathConfig';
import { doPatch } from '../../../../shared/service/NetworkClient';

export const useCancelSession = () => {
  const navigate = useNavigate();
  return useMutation(
    ({ sessionId, reason }: { sessionId: string; reason: string }) =>
      doPatch(
        ApiConfig.api.sessions.cancel.replace('{{sessionId}}', sessionId),
        { reason },
      ).then((res) => res.data.data),
    {
      onSuccess: () => {
        toast.success('Session Cancelled.');
        navigate(PathConfig.responder);
      },
      onError: () => {
        toast.error('An error occurred while cancelling this session.');
      },
    },
  );
};
