import { RiskColorVariants, RiskLabels } from '../../../shared/constants/Risk';
import {
  AgeOptions,
  NoOrganisationCode,
  TenureOptions,
} from '../../../shared/constants/Session';
import { CaseNoteSession } from '../../../shared/types/Session';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { Box, Heading, Grid, Typography, Tag } from '../../styledComponents';

const CallerDetails = ({ sessionData }: { sessionData: CaseNoteSession }) => {
  const { isSuccess: organisationsListSuccess, data: organisationsList } =
    useGetOrganisationsList();

  return (
    <>
      <Heading size="sm" css={{ fontWeight: '600' }}>
        Caller details
      </Heading>
      <Grid gapX="6" gapY="3" columns={4} css={{ p: '$4', mb: '$1' }}>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Company
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {organisationsListSuccess
              ? sessionData.client.organisation === NoOrganisationCode
                ? 'No information'
                : organisationsList.find(
                    (org) => org.id === sessionData.client.organisation,
                  )?.name ?? 'No Information'
              : 'Loading...'}
          </Typography>
        </Box>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Age
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {sessionData.client.age === '0'
              ? 'No information'
              : AgeOptions.find((age) => age.value === sessionData.client.age)
                  ?.label ?? 'No information'}
          </Typography>
        </Box>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Phone number
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {sessionData.client.phone ?? 'No information'}
          </Typography>
        </Box>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Email
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {sessionData.client.email ?? 'No information'}
          </Typography>
        </Box>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Staff ID
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {sessionData.client.employeeId ?? 'No information'}
          </Typography>
        </Box>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Location
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {sessionData.client.location === 'Did not disclose'
              ? 'No information'
              : sessionData.client.location}
          </Typography>
        </Box>

        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Tenure
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {TenureOptions.find(
              (tenure) => tenure.value === sessionData.client.tenure,
            )?.label ?? 'No information'}
          </Typography>
        </Box>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Risk assessment
          </Typography>
          <Tag size="sm" variant={RiskColorVariants[sessionData.risk]}>
            {RiskLabels[sessionData.risk]}
          </Tag>
        </Box>
        {sessionData.client.isDependent && (
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Client Type
            </Typography>
            <Tag size="sm" variant="lavender">
              Dependent
            </Tag>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default CallerDetails;
