import { useFormContext } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';
import { IoCloseOutline } from 'react-icons/io5';
import FormSection from '../../../components/formSection/FormSection';
import { Button, Flex, Input, Label } from '../../../styledComponents';
import {
  ExistingEmailUser,
  NewEmailUser,
} from '../../../../shared/types/FetchByEmailUserData';
import { CareNavForm } from '../../../../shared/types/CareNavForm';
import { useCreateClientAndSession } from '../../../../utilities/hooks/queryHooks/client/UseCreateClient';
import { emailRegex } from '../../../../utilities/common/FormValidatorRegex';
import { useGetUserFromEmail } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetUserFromEmail';
import { CreateClientForm } from './CreateClientForm';

// `${
//   data?.addOns.coachEnabled
//     ? `Coaching: ${
//       data?.addOns.credits.isPerCredit
//         ? data?.addOns.credits.coachingCredits
//         : 'UNLIMITED'
//     }`
//     : 'Coaching: No'
// } / ${
//   data?.addOns.clinicalEnabled
//     ? `Clinical: ${
//       data?.addOns.credits.isClinicalPerCredit
//         ? data?.addOns.credits.clinicalCredits
//         : 'UNLIMITED'
//     }`
//     : 'Clinical: No'
// }`
const FetchDetailsForm = () => {
  const {
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext<CareNavForm>();

  const {
    mutateAsync: createClientAndSession,
    isLoading: isCreateClientAndSessionLoading,
  } = useCreateClientAndSession();

  const onGetUserFromScholarSuccess = (
    data: NewEmailUser | ExistingEmailUser,
  ) => {
    if (
      data.message.some(
        (message) =>
          message.status === 'NO_ORG',
      )
    ) {
      setValue('showCreateClientHandler', true);
    } else if (
      // @ts-ignore
      !data.userId
      && data.message.some(
        (message) =>
          message.status === 'DIFFERENT_ORG',
      )
    ) {
      setValue('membershipData', data.members);
      setValue('showCreateClientHandler', true);
    } else if ('email' in data) {
      reset({
        ...watch(),
        email: data.email,
        userData: { ...data },
      });
      /** if the create client component is open, it means that
       * the data is being fetched from the email after creation
       * of the client, so no need to create it again.
       * */
      if (!watch('showCreateClientHandler')) {
        createClientAndSession({
          name: data.name,
          email: data.email,
          organisation: data.organisation.id,
          phone: '',
          tenure: '',
          age: '',
        });
      }
    }
  };

  const { refetch, isFetching } = useGetUserFromEmail({
    email: watch('email'),
    organisation: null,
    onSuccess: onGetUserFromScholarSuccess,
  });

  const isThereAUserBeingCreated = watch([
    'id',
    'sessionId',
    'userData',
    'showCreateClientHandler',
  ]).some(Boolean);

  return (
    <FormSection
      title="User Information"
      subtitle="Enter your client information to get started with the session"
      defaultOpen
    >
      <Flex align="center" gap="2">
        <Label htmlFor="email" css={{ width: '100%' }}>
          Email
          <Input
            defaultValue={watch('email')}
            id="email"
            disabled={isThereAUserBeingCreated}
            {...register('email', {
              pattern: {
                value: emailRegex,
                message: 'Please enter a valid email.',
              },
            })}
            helperText={
              errors.email?.type === 'required'
                ? 'This is required.'
                : errors.email?.message?.toString()
            }
          />
        </Label>
        {isThereAUserBeingCreated ? (
          <Button
            onClick={() =>
              reset({
                email: '',
                userData: undefined,
                id: undefined,
                sessionId: undefined,
                showCreateClientHandler: false,
              })
            }
            variant="outlined"
            startIcon={<IoCloseOutline size={22} />}
          >
            Clear
          </Button>
        ) : (
          <Button
            onClick={() => refetch()}
            isLoading={isFetching}
            disabled={!watch('email') || 'email' in errors}
          >
            Fetch details
          </Button>
        )}
      </Flex>
      <AnimatePresence>
        {watch('showCreateClientHandler') ? (
          <CreateClientForm
            onSubmit={createClientAndSession}
            isCreateClientLoading={isCreateClientAndSessionLoading}
          />
        ) : null}
      </AnimatePresence>
    </FormSection>
  );
};
export default FetchDetailsForm;
