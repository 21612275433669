import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import { SessionListItem } from '../../../../shared/types/Session';

export const useGetSessionsList = (sessionType: '0' | '1' = '0') =>
  useQuery<SessionListItem[]>(
    [QueryKeys.getSessionsList],
    () =>
      doGet(ApiConfig.api.sessions.list, undefined, { sessionType }).then(
        (res) => res.data.data.sessions,
      ),
    {
      onError: () => {
        toast.error('Unable to get list of sessions.');
      },
    },
  );
