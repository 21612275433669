import { styled } from '../../stitches.config';
import { generateColorPaletteVariants } from '../../utilities/common/GenerateColorPaletteVariants';

export const Typography = styled('span', {
  // Reset
  lineHeight: '110%',
  margin: '0',
  fontWeight: 400,
  fontVariantNumeric: 'tabular-nums',
  display: 'block',
  variants: {
    size: {
      xs: {
        fontSize: '$1',
      },
      sm: {
        fontSize: '$2',
      },
      md: {
        fontSize: '$3',
      },
      lg: {
        fontSize: '$4',
      },
      xl: {
        fontSize: '$5',
        letterSpacing: '-.015em',
      },
      '2xl': {
        fontSize: '$6',
        letterSpacing: '-.016em',
      },
      '3xl': {
        fontSize: '$7',
        letterSpacing: '-.031em',
        textIndent: '-.005em',
      },
      '4xl': {
        fontSize: '$8',
        letterSpacing: '-.034em',
        textIndent: '-.018em',
      },
      '5xl': {
        fontSize: '$9',
        letterSpacing: '-.055em',
        textIndent: '-.025em',
      },
    },
    color: generateColorPaletteVariants({
      color: '$--clr--',
    }),
  },
  defaultVariants: {
    size: 'sm',
  },
});
