import { styled } from '../../stitches.config';
import { Typography } from './Typography';

export const Label = styled('label', Typography, {
  display: 'inline-block',
  verticalAlign: 'middle',
  cursor: 'default',
  fontWeight: '500',
  color: '$gray600',
  'input[disabled] + label': {
    color: 'rgba(0, 0, 0, 0.40)',
    border: '2px solid #d9d9d9',
    cursor: 'not-allowed',
  },
});
