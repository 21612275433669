import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { NoOrganisationCode } from '../../../../shared/constants/Session';
import { doGet } from '../../../../shared/service/NetworkClient';
import { ClientInfoV2 } from '../../../../shared/types/Client';
import { getOrganisation } from '../../../common/FormValidatorRegex';

export const useGetUserFromNumber = (
  phone: string,
  organisation: number | undefined,
  onComplete: (data: ClientInfoV2) => void,
) =>
  useQuery<ClientInfoV2>(
    [QueryKeys.getUserFromNotebook, phone],
    () =>
      doGet(
        `${ApiConfig.api.clients.getDetailFromNumberorEmail}phone=${phone}${
          getOrganisation(organisation) ? `&organisation=${organisation}` : ''
        }`,
      ).then((res) => res.data.data),
    {
      select: (data) => ({
        ...data,
        organisation: Number(data.organisation) || NoOrganisationCode,
        userId: String(data.userId),
      }),
      enabled: false,
      onSuccess: onComplete,
      onError: () =>
        toast.error(
          `No client with that phone number ${organisation ? 'and organisation' : ''} exists. Please try again.`,
        ),
    },
  );
