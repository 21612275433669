import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import { CaseNote } from '../../../../shared/types/Session';

export const useGetSessionComments = (sessionId?: string) =>
  useQuery<CaseNote[]>(
    [QueryKeys.getSessionComments, sessionId],
    () =>
      doGet(
        ApiConfig.api.sessions.getNotes.replace(
          '{{sessionId}}',
          String(sessionId),
        ),
      ).then((res) => res.data.data),
    {
      enabled: !!sessionId,
      select: (data) => data.slice(1),
      onError: () => {
        toast.error("An error occurred while getting the session's comments.");
      },
    },
  );
