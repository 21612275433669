import { ComboboxOption, Option } from '../../shared/types/SelectTypes';

export function convertIntoComboboxOptions(
  list: Record<string, string | number | unknown>[],
  labelKey: string,
  valueKey: string,
): ComboboxOption[] {
  return list.map((item) => ({
    label: String(item[labelKey]),
    value: String(item[valueKey]),
  }));
}

export function convertIntoOptions(
  list: Record<string, string | number>[],
  labelKey: keyof typeof list[number],
  valueKey: string,
): Option[] {
  return list.map((item) => ({
    label: String(item[labelKey]),
    value: item[valueKey],
  }));
}
