import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Fragment } from 'react';
import { PrsentingIssues } from '../../../../shared/constants/PresentingIssues';
import {
  AgeOptions,
  NoOrganisationCode,
  TenureOptions,
} from '../../../../shared/constants/Session';
import {
  RiskColorVariants,
  RiskLabels,
  RiskLevels,
} from '../../../../shared/constants/Risk';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';
import useAuth from '../../../../utilities/hooks/UseAuth';
import useTracking from '../../../../utilities/hooks/UseTracking';
import Modal from '../../../components/modal/Modal';
import {
  Box,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Label,
  Tag,
  Typography,
} from '../../../styledComponents';
import { PathConfig } from '../../../../config/PathConfig';
import { useGetOrganisationsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { useUpdateSession } from '../../../../utilities/hooks/queryHooks/caseNotes/UseUpdateSession';
import { useCreateClientAndSession } from '../../../../utilities/hooks/queryHooks/client/UseCreateClient';
import { useGetAssessmentQuestions } from '../../../../utilities/hooks/queryHooks/session/UseGetAssessmentQuestions';
import { CaseNoteFormEvent } from '../../../../shared/constants/events/CaseNoteForm';
import { useGetClientECP } from '../../../../utilities/hooks/queryHooks/client/UseGetAndCreateECP';
import { UserECPRelations } from '../../../../shared/constants/ECP';
import { ValueOf } from '../../../../shared/types/Common';

const ConfirmEventLabels = {
  [RiskLevels.Low]: 'confirm_low',
  [RiskLevels.Medium]: 'confirm_medium',
  [RiskLevels.High]: 'confirm_high',
  [RiskLevels.HighCritical]: 'confirm_high_crc',
  [RiskLevels.Other]: 'confirm_other',
} satisfies Record<
  ValueOf<typeof RiskLevels>,
  CaseNoteFormEvent['EventLabelType'][number]
>;

const ConfirmationModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { track } = useTracking<CaseNoteFormEvent>();

  const { getValues, watch } = useFormContext<CaseNoteFormTypes>();
  const { isLoading: isUpdateSessionLoading, mutate: mutateUpdateSession } =
    useUpdateSession(() => {
      navigate(`${PathConfig.responder}/${PathConfig.callLogs}`);
    });

  const { data: ecpData, isLoading: isECPDataLoading } = useGetClientECP(
    watch('userId'),
  );

  const { data: organisationsList } = useGetOrganisationsList();

  const meetingData = watch('meetingData');

  const { data: assessmentQuestions, isSuccess } = useGetAssessmentQuestions(
    getValues('risk'),
  );

  const updateSession = () => {
    const {
      id: clientId,
      sessionId,
      startTime,
      risk,
      caseNotes,
      assessment,
      issue,
      doesNotWantToBookSession,
    } = getValues();

    track('mark_complete', {
      eventAction: 'click',
      eventCategory: 'confirm_submit',
      eventLabel: ConfirmEventLabels[watch('risk') || RiskLevels.Low],
    });

    if (clientId) {
      mutateUpdateSession({
        sessionId,
        clientId,
        startTime: startTime.toISOString(),
        endTime: new Date().toISOString(),
        risk,
        caseNotes,
        assessment,
        issue,
        meetingConsent: doesNotWantToBookSession ? 'no' : 'yes',
        status: '2',
        sessionType: '0',
      });
    }
  };

  const {
    isLoading: isCreateClientAndSessionLoading,
    mutate: mutateClientAndSession,
  } = useCreateClientAndSession({
    onSessionCreateSuccess: () => updateSession(),
  });

  const handleSubmit = () => {
    if (watch('sessionId')) {
      updateSession();
    } else {
      // User does not exist, will create client and session
      const {
        name,
        email,
        phone,
        location,
        organisation,
        tenure,
        age,
        employeeId,
      } = getValues();
      const nonNullValues = Object.fromEntries(
        Object.entries({
          name,
          email,
          phone,
          location,
          organisation,
          tenure,
          age,
          employeeId,
        }).filter(([_, v]) => !!v),
      );
      mutateClientAndSession(nonNullValues);
    }
  };

  const getBookingStatusText = () => {
    if (meetingData) {
      if (meetingData.type === 'virtual') {
        return 'You have booked a session for your client';
      }
      return 'You have raised a request for a F2F session for the client';
    }
    if (watch('organisation') === NoOrganisationCode) {
      return 'Cannot book session as user is not a part of an organisation';
    }
    if (getValues('clientAnonymous')) {
      return "Client didn't share further information";
    }
    if (getValues('doesNotWantToBookSession')) {
      return 'Client didn’t want responder to book a coaching session';
    }

    return 'You have not booked a session for your client';
  };

  return (
    <Modal
      title="Complete this session"
      subtitle="Please go through what you’ve written as you won’t be able to change this later."
      open={open ?? true}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      confirmLoading={isUpdateSessionLoading || isCreateClientAndSessionLoading}
    >
      <Box
        css={{
          br: '$2',
          backgroundColor: '$gray50',
          p: '$3',
          border: '1px solid $gray200',
        }}
      >
        <Heading size="sm" css={{ fontWeight: '600' }}>
          Client information
        </Heading>
        <Grid gapX="6" gapY="4" columns={4} css={{ p: '$4' }}>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Client name
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {getValues('name') || 'No information'}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Age
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {AgeOptions.find((age) => age.value === getValues('age'))
                ?.label ?? 'No information'}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Phone number
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {getValues('phone')}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Call Time
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {format(getValues('startTime'), 'dd MMM, yyyy')}{' '}
              {format(getValues('startTime'), 'hh:mm aaa')} {' - '}
              {format(getValues('endTime'), 'hh:mm aaa')}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Company
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {organisationsList && (
                <Typography size="xs" css={{ fontWeight: '500' }}>
                  {organisationsList.find(
                    (org) => org.id === getValues('organisation'),
                  )?.name ?? 'No information'}
                </Typography>
              )}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Staff ID
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {getValues('employeeId') || 'No information'}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Tenure
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {TenureOptions.find(
                (tenure) => tenure.value === getValues('tenure'),
              )?.label ?? 'No information'}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Email
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {getValues('email') ?? 'No information'}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Location
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              {getValues('location') ?? 'No information'}
            </Typography>
          </Box>
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Risk assessment
            </Typography>
            <Typography size="xs" css={{ fontWeight: '500' }}>
              <Tag size="sm" variant={RiskColorVariants[getValues('risk')]}>
                {RiskLabels[getValues('risk')]}
              </Tag>
            </Typography>
          </Box>
        </Grid>
        <Heading size="sm" css={{ fontWeight: '600', my: '$3' }}>
          Risk assessment details
        </Heading>
        {[RiskLevels.High, RiskLevels.HighCritical].some(
          (r) => r === getValues('risk'),
        )
          ? isSuccess && (
              <Grid columns={2} gap="3">
                {getValues('assessment').map((assessment, index) => (
                  <Flex key={assessment.questionId} gap="2" align="center">
                    <Checkbox disabled checked={Boolean(assessment.response)} />
                    <Label>
                      {assessmentQuestions?.[index]?.question ??
                        'Assessment question unavailable'}
                      {assessmentQuestions?.[index]?.isRequired ? (
                        <span style={{ color: 'red' }}>*</span>
                      ) : null}
                    </Label>
                  </Flex>
                ))}
              </Grid>
            )
          : null}

        {[
          RiskLevels.Low,
          RiskLevels.Medium,
          RiskLevels.High,
          RiskLevels.Other,
        ].some((r) => r === getValues('risk')) ? (
          <>
            <Flex
              align="center"
              gap="3"
              css={{
                backgroundColor: '$gray75',
                br: '$2',
                p: '$2',
                border: '1px solid $gray200',
                color: '$gray600',
                mt: '$4',
                fontSize: '$3',
              }}
            >
              <IoInformationCircleOutline size={24} />
              {getBookingStatusText()}
            </Flex>
            {meetingData && meetingData.data && (
              <Grid gap="6" columns={3} css={{ p: '$4' }}>
                <Box>
                  <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                    Coach Name
                  </Typography>
                  <Typography size="xs" css={{ fontWeight: '500' }}>
                    {meetingData.data.providerName ??
                      'Provider name unavailable'}
                  </Typography>
                </Box>
                <Box>
                  <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                    Booking date
                  </Typography>

                  {meetingData.data.meeting.map(
                    ({ scheduledStartTime, scheduledEndTime }) => (
                      <Typography size="xs" css={{ fontWeight: '500' }}>
                        {format(
                          new Date(Number(scheduledStartTime) * 1000),
                          'dd MMM, yy',
                        )}
                        {' - '}
                        {format(
                          new Date(Number(scheduledStartTime) * 1000),
                          'h:mm aaa',
                        )}
                        {' - '}
                        {format(
                          new Date(Number(scheduledEndTime) * 1000),
                          'h:mm aaa',
                        )}
                      </Typography>
                    ),
                  )}
                </Box>
              </Grid>
            )}
          </>
        ) : null}

        {isECPDataLoading || !ecpData ? null : (
          <>
            <Heading size="sm" css={{ fontWeight: '600', mb: '$3', mt: '$5' }}>
              Emergency contact details
            </Heading>
            <Grid columns={3} align="center" gap="3">
              {ecpData.length ? (
                ecpData.map((contact, index) => (
                  <Fragment key={contact.userId}>
                    <Typography
                      size="sm"
                      css={{
                        gridColumn: '1 / span 3',
                        fontWeight: 500,
                      }}
                    >
                      Contact {index + 1}
                    </Typography>
                    <div>
                      <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                        Name
                      </Typography>
                      <Typography size="xs" css={{ fontWeight: '500' }}>
                        {contact.name}
                      </Typography>
                    </div>
                    <div>
                      <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                        Phone number
                      </Typography>
                      <Typography size="xs" css={{ fontWeight: '500' }}>
                        {contact.contact}
                      </Typography>
                    </div>
                    <div>
                      <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                        Relationship
                      </Typography>
                      <Typography size="xs" css={{ fontWeight: '500' }}>
                        {Object.entries(UserECPRelations).find(
                          ([_name, id]) => id === contact.relation,
                        )?.[0] || 'N/A'}
                      </Typography>
                    </div>
                  </Fragment>
                ))
              ) : (
                <Typography>N/A</Typography>
              )}
            </Grid>
          </>
        )}
        <Heading size="sm" css={{ fontWeight: '600', mb: '$3', mt: '$5' }}>
          Call log details
        </Heading>
        <Typography color="gray600" css={{ mb: '$1' }}>
          Presenting issue
        </Typography>
        <Typography
          css={{ mb: '$2', lineHeight: '$1', whiteSpace: 'pre-line' }}
        >
          {PrsentingIssues.flatMap((category) => category.options).find(
            (issue) => issue.value === getValues('issue'),
          )?.label ?? 'N/A'}
        </Typography>
        <Typography color="gray600" css={{ mb: '$1' }}>
          Note
        </Typography>
        <Typography
          css={{ mt: '$1', lineHeight: '$1', whiteSpace: 'pre-line' }}
        >
          {getValues('caseNotes')}
        </Typography>
        <Flex justify="end" css={{ mt: '$2' }}>
          <Typography size="xs" color="gray500">
            {user.name}
          </Typography>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
