import { User } from '../../shared/types/User';
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from '../common/Storage';

interface IUseAuthReturns {
  isLoggedIn: string;
  user: User;
  userToken: string;
  invalidateUser: () => void;
}

const useAuth = (): IUseAuthReturns => {
  const user = getItemFromLocalStorage('userInfo', 'object') as User;
  const isLoggedIn = user && user.id && user.token;

  const invalidateUser = () => {
    removeItemFromLocalStorage('userInfo');
    window.location.reload();
  };

  return { isLoggedIn, user, userToken: user.token, invalidateUser };
};

export default useAuth;
