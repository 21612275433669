import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import { CaseNoteSession } from '../../../../shared/types/Session';

export const useGetSessionData = (
  sessionId?: string,
  initialEnabled: boolean = true,
  onSuccess?: (data: CaseNoteSession) => void,
) =>
  useQuery<CaseNoteSession>(
    [QueryKeys.getSessionData, sessionId],
    () =>
      doGet(ApiConfig.api.sessions.getSessionDataV2 + sessionId).then(
        (res) => res.data.data,
      ),
    {
      enabled: initialEnabled,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      select(data) {
        return {
          ...data,
          client: {
            ...data.client,
            organisation: Number(data.client.organisation),
          },
        };
      },
      onSuccess,
      onError: () => {
        toast.error("An error occurred while getting the session's details.");
      },
    },
  );
