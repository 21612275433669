import { Query } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export const handleQueryError = (error: unknown, query: Query) => {
  if ((error as AxiosResponse).data !== undefined) {
    const { data } = error as AxiosResponse;
    console.error(
      `Something went wrong: ${data.error.statusCode ?? 'Code N/A'} - ${
        data.error.message ?? 'Message N/A'
      }`,
    );
  } else {
    console.error(`Something went wrong. Query hash - ${query.queryHash}`);
  }
};
