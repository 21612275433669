import Modal from '../../../../../components/modal/Modal';
import { Grid, Typography } from '../../../../../styledComponents';
import { ClientECP } from '../../../../../../shared/types/Client';
import { UserECPRelations } from '../../../../../../shared/constants/ECP';

const ViewECPModal = ({
  ecpData = [],
  handleClose,
  open,
}: {
  ecpData: ClientECP[];
  open: boolean;
  handleClose: () => void;
}) => (
  <Modal
    open={open}
    handleClose={handleClose}
    handleSubmit={() => {}}
    title="Emergency contact"
    confirmButtonText="Save"
    hideButtonRow
  >
    {ecpData.map((contact, index) => (
      <Grid
        columns={3}
        align="center"
        gap="3"
        css={{
          br: '$2',
          backgroundColor: '$gray50',
          p: '$5',
          mb: '$2',
          border: '1px solid $gray200',
        }}
      >
        <Typography
          size="md"
          css={{
            gridColumn: '1 / span 3',
          }}
        >
          Contact {index + 1}
        </Typography>
        <div>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Name
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {contact.name}
          </Typography>
        </div>
        <div>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Phone number
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {contact.contact}
          </Typography>
        </div>
        <div>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Relationship
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {Object.entries(UserECPRelations).find(
              ([_name, id]) => id === contact.relation,
            )?.[0] || 'N/A'}
          </Typography>
        </div>
      </Grid>
    ))}
  </Modal>
);

export default ViewECPModal;
