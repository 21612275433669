import { useCallback } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { Flex, Typography } from '../../styledComponents';

interface IFileDropZoneProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  disabled?: boolean;
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number; // in bytes
  minSize?: number; // in bytes
  accept?: Accept;
  css?: Record<string, any>;
  title?: string;
  subTitle?: string;
}

const ItemContains = (dataItem: string | number, filterItem: string | number) =>
  dataItem
    ?.toString()
    ?.trim()
    ?.toLowerCase()
    ?.includes(filterItem?.toString()?.trim()?.toLowerCase());

const FileDropZone = ({
  files,
  setFiles,
  multiple,
  css,
  maxSize,
  minSize,
  title = "Drag 'n' drop some files here, or click to select files",
  subTitle = '',
  disabled = false,
  accept = {},
}: IFileDropZoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const duplicate = acceptedFiles?.filter((newFile: File) =>
        files?.find((item) => ItemContains(item.name, newFile.name)),
      );

      if (duplicate?.length) {
        toast.error('File with same name already exists');
        return;
      }

      setFiles((prev) => [...prev, ...acceptedFiles]);
    },
    [files, setFiles],
  );

  const onDropRejected = useCallback((acceptedFiles: FileRejection[]) => {
    toast.error(
      <div>
        Files not allowed:
        {acceptedFiles
          ?.map((item: any) => item?.file?.name)
          .map((file: string, index: number) => (
            <div key={file}>
              {index + 1}. {file}
            </div>
          ))}
      </div>,
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    multiple,
    disabled,
    accept,
    maxFiles: 5,
    maxSize,
    minSize,
  });

  return (
    <Flex
      align="center"
      justify="center"
      css={{
        padding: '$6',
        border: '1px dashed $gray300',
        borderRadius: '0.5rem',
        cursor: 'pointer',
        ...css,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Flex direction="column" justify="center" align="center">
        <Typography size="md">{title}</Typography>
        <Typography size="sm">{subTitle}</Typography>
      </Flex>
    </Flex>
  );
};

export default FileDropZone;
