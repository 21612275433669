import { FormProvider, useForm } from 'react-hook-form';
import { CallLogsFilterTypes } from '../../../shared/types/CallLogs';
import { useGetSessionsListV2 } from '../../../utilities/hooks/queryHooks/callLogs/useGetSessionsListV2';
import useDebounce from '../../../utilities/hooks/UseDebounce';
import Container from '../../components/container/Container';
import LoadingView from '../../components/loadingView/LoadingView';
import { Heading } from '../../styledComponents';
import { CallLogFilters } from './CallLogFilters';
import { CallLogList } from './CallLogList';

const CallLogs = () => {
  const methods = useForm<CallLogsFilterTypes>({
    defaultValues: {
      organisation: null,
      anonymous: false,
      email: '',
      risk: null,
      page: 1,
      pageSize: 20,
    },
    mode: 'onTouched',
  });

  const debouncedEmail = useDebounce(methods.watch('email'), 500);

  const { data, isLoading: isSessionsListLoading } = useGetSessionsListV2({
    ...methods.watch(),
    email: debouncedEmail,
  });

  return (
    <FormProvider {...methods}>
      <Container>
        <Heading size="xl" css={{ fontWeight: '600', mb: '$5' }}>
          Call logs
        </Heading>

        <CallLogFilters sessionsData={data} />

        {isSessionsListLoading || !data ? (
          <LoadingView />
        ) : (
          <CallLogList sessionsData={data} />
        )}
      </Container>
    </FormProvider>
  );
};

export default CallLogs;
