import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { ContainerVariants } from '../../../shared/constants/Animations';

import { CSS } from '../../../stitches.config';
import { Box } from '../../styledComponents';

const MotionBox = motion(Box);

const Container = ({
  children,
  slide,
  slideUp,
  css,
}: {
  children: ReactNode;
  slide?: boolean;
  slideUp?: boolean;
  css?: CSS;
}) => (
  <MotionBox
    variants={ContainerVariants}
    initial={slide ? 'hiddenX' : slideUp ? 'hiddenY' : 'defaultHidden'}
    animate={slide ? 'slideX' : slideUp ? 'slideY' : 'defaultAnimate'}
    exit={slide ? 'exitX' : slideUp ? 'exitY' : 'defaultExit'}
    css={{ padding: '$8 8rem', ...css }}
  >
    {children}
  </MotionBox>
);
export default Container;
