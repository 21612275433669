import { format } from 'date-fns';
import useAuth from '../../../utilities/hooks/UseAuth';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Typography,
} from '../../styledComponents';

const ResponderContent = () => {
  const { invalidateUser, user } = useAuth();

  return (
    <Box css={{ width: '25vw' }}>
      <Heading size="lg" css={{ fontWeight: 'bold' }}>
        {user.name}
      </Heading>
      {/* <Heading size="sm" css={{ fontWeight: 'bolder', color: '$gray500' }}>
          <em>
            <code>{user.id}</code>
          </em>
        </Heading> */}
      <Grid
        columns="2"
        gapX="5"
        gapY="3"
        align="start"
        css={{
          mt: '$5',
          width: 'fit-content',
        }}
      >
        <Typography color="gray500">Status</Typography>
        <Typography color={user.isActive ? 'darkGreen' : 'darkRed'}>
          {user.isActive ? 'Active' : 'Inactive'}
        </Typography>
        <Typography color="gray500">ID</Typography>
        <Typography>{user.id}</Typography>
        <Typography color="gray500">Email</Typography>
        <Typography>{user.email}</Typography>
        <Typography color="gray500">Date created</Typography>
        <Typography>
          {format(new Date(user.createdAt), 'dd MMMM yyyy')}
        </Typography>
      </Grid>
      <Flex justify="end" css={{ mt: '$2' }}>
        <Button
          id="log-out"
          variant="outlined"
          onClick={() => invalidateUser()}
        >
          Log out
        </Button>
      </Flex>
    </Box>
  );
};

export default ResponderContent;
