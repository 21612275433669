import { IconBase } from 'react-icons';
import { styled } from '../../stitches.config';

export const Tag = styled('span', {
  // Reset
  alignItems: 'center',
  appearance: 'none',
  borderWidth: '0',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexShrink: 0,
  fontFamily: 'inherit',
  fontWeight: '500',
  justifyContent: 'center',
  lineHeight: '1',
  verticalAlign: 'middle',
  outline: 'none',
  padding: '0',
  textDecoration: 'none',
  userSelect: 'none',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  '&:disabled': {
    backgroundColor: '$gray300',
    pointerEvents: 'none',
    color: '$gray500',
  },
  '&::before': {
    boxSizing: 'border-box',
    content: '""', // eslint-disable-line quotes
  },
  '&::after': {
    boxSizing: 'border-box',
    content: '""', // eslint-disable-line quotes
  },

  // Custom
  whiteSpace: 'nowrap',
  fontVariantNumeric: 'tabular-nums',

  variants: {
    shape: {
      sharp: {
        br: '2px',
      },
      rounded: {
        br: '$2',
      },
      pill: {
        br: '$full',
      },
    },
    size: {
      sm: {
        height: '$4',
        px: '$1',
        fontSize: '$1',
      },
      md: {
        height: '$5',
        px: '$2',
        fontSize: '$2',
      },
    },
    variant: {
      success: {
        backgroundColor: '$lightGreen',
        color: '$darkGreen',
        [`${IconBase}`]: {
          color: '$darkGreen',
        },
      },
      warning: {
        backgroundColor: '$lightGold',
        color: '$gold',
        [`${IconBase}`]: {
          color: '$gold',
        },
      },
      error: {
        backgroundColor: '$lightRed',
        color: '$darkRed',
        [`${IconBase}`]: {
          color: '$darkRed',
        },
      },
      gray: {
        backgroundColor: '$gray100',
        color: '$gray500',
        [`${IconBase}`]: {
          color: '$gray500',
        },
      },
      lavender: {
        backgroundColor: '$lightLavender',
        color: '$darkLavender',
        [`${IconBase}`]: {
          color: '$darkLavender',
        },
      },
      purple: {
        backgroundColor: '$lightPurple',
        color: '$darkPurple',
        [`${IconBase}`]: {
          color: '$darkPurple',
        },
      },
    },
    interactive: {
      true: {},
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'success',
    shape: 'sharp',
  },
});
