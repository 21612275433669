import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import { IoCloseOutline, IoSyncOutline } from 'react-icons/io5';
import { ClientInfoV2 } from '../../../../shared/types/Client';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';
import { useGetUserFromNumber } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetUserFromNumber';
import {
  Label,
  Flex,
  Button,
  Box,
  Typography,
} from '../../../styledComponents';
import { AgeOptions } from '../../../../shared/constants/Session';
import { useGetRegionsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetRegionsList';

const PhoneNumberInput = ({ handleReset }: { handleReset: () => void }) => {
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useFormContext<CaseNoteFormTypes>();

  const { data: regionsList } = useGetRegionsList();

  const {
    refetch: numberRefetch,
    isRefetching,
    isInitialLoading,
  } = useGetUserFromNumber(
    watch('phone'),
    getValues().organisation,
    (data: ClientInfoV2) => {
      const { location, age, ...rest } = data;
      reset({
        ...getValues(),
        ...rest,
        ...(AgeOptions.find((ageOption) => ageOption.value === age) && {
          age,
        }),
        ...(regionsList?.find(
          (regionOption) => regionOption.region === location,
        ) && { location }),
        email: data.email,
      });
    },
  );

  return (
    <Flex direction="column">
      <Button
        id="reset-info"
        variant="ghost"
        size="sm"
        type="button"
        onClick={handleReset}
        css={{
          display: watch('id') ? 'block' : 'none',
          alignSelf: 'end',
        }}
        startIcon={<IoCloseOutline size={24} />}
      >
        Reset all information
      </Button>
      <Label htmlFor="phone">Number *</Label>
      <Flex
        justify="between"
        align="center"
        gap={3}
        css={{
          flexGrow: 1,
          border: '1px solid $gray300',
          borderRadius: '0.5rem',
          my: '$1',
          pr: '$1',
          backgroundColor: watch('id') ? '$gray100' : 'inherit',
        }}
      >
        <Box css={{ flexGrow: 1 }}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'This is required.',
              },
              minLength: {
                value: 8,
                message: 'Phone number should have a minimum of 8 digits.',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <PhoneInput
                disabled={!!watch('id')}
                placeholder=""
                inputProps={{
                  ref,
                  required: true,
                }}
                inputClass="phone-input"
                country="sg"
                {...rest}
              />
            )}
          />
        </Box>
        <Button
          id="fetch-by-number"
          variant="ghost"
          size="sm"
          startIcon={<IoSyncOutline />}
          isLoading={isInitialLoading || isRefetching}
          disabled={!!errors.phone || !watch('phone') || !!watch('id')}
          type="button"
          onClick={() => numberRefetch()}
        >
          Fetch details
        </Button>
      </Flex>
      <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
        {errors.phone?.type === 'required'
          ? 'This is required.'
          : errors.phone?.message?.toString()}
      </Typography>
    </Flex>
  );
};

export default PhoneNumberInput;
