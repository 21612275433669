import { useQueryClient, useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doPost } from '../../../../shared/service/NetworkClient';

export const useCreateNoteV2 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      sessionId,
      note,
      file,
    }: {
      sessionId: string;
      note: string;
      file?: File;
    }) => {
      const body = new FormData();

      body.append('note', note);

      if (file) {
        body.append('file', file);
      }

      return doPost(
        ApiConfig.api.sessions.addNoteV2.replace('{{sessionId}}', sessionId),
        body,
      ).then((res) => res.data.data);
    },
    {
      onSuccess: () => {
        toast.success('Session created successfully.');
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.getSessionsList],
        });
        // navigate(pathConfig.recentSessions);
      },
      onError: () => {
        toast.error('An error occurred while completing this session.');
      },
    },
  );
};
