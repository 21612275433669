import { ComponentProps, forwardRef } from 'react';
import { CSS, styled, VariantProps } from '../../stitches.config';
import { Spinner } from './Spinner';

const ButtonBase = styled('button', {
  all: 'unset',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexShrink: 0,
  lineHeight: '1',
  height: '$5',
  px: '$2',
  fontSize: '$2',
  fontWeight: 500,
  borderRadius: '$2',
  transition: 'all 0.2s linear',
  '@hover': {
    '&:hover:not([disabled])': {
      filter: 'brightness(85%)',
      cursor: 'pointer',
    },
  },
  '&:disabled': {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.40)',
    border: '1px solid #d9d9d9',
    cursor: 'not-allowed',
  },
  variants: {
    pill: {
      true: {
        borderRadius: '$full',
      },
    },
    size: {
      sm: {
        height: '$5',
        px: '$2',
        fontSize: '$1',
        lineHeight: '$sizes$5',
      },
      md: {
        height: '$6',
        px: '$3',
        fontSize: '$3',
        lineHeight: '$sizes$6',
      },
      lg: {
        height: '$7',
        px: '$4',
        fontSize: '$4',
        lineHeight: '$sizes$7',
      },
    },
    color: {
      darkMetal: {
        backgroundColor: '$metal',
        color: 'white',
      },
      metal: {
        backgroundColor: '$metal400',
        color: 'white',
      },
      red: {
        backgroundColor: '$lightRed',
        color: '$darkRed',
      },
      green: {
        backgroundColor: '$lightGreen',
        color: '$darkGreen',
      },
    },
    variant: {
      outlined: {
        border: ' 1px solid $gray500',
        backgroundColor: 'white',
      },
      ghost: {
        borderWidth: 0,
        backgroundColor: 'white',
      },
      filled: {
        borderWidth: 0,
        '&:hover:not([disabled])': {
          filter: 'brightness(85%)',
        },
      },
    },
  },
  compoundVariants: [
    {
      color: 'darkMetal',
      variant: 'filled',
      css: {
        color: 'White',
        backgroundColor: '$metal',
      },
    },
    {
      color: 'darkMetal',
      variant: 'outlined',
      css: {
        color: '$metal',
        backgroundColor: 'white',
        borderColor: '$gray800',
      },
    },
    {
      color: 'metal',
      variant: 'filled',
      css: {
        color: 'White',
        backgroundColor: '$metal400',
      },
    },
    {
      color: 'metal',
      variant: 'outlined',
      css: {
        color: '$metal400',
        backgroundColor: 'white',
      },
    },
    {
      color: 'metal',
      variant: 'ghost',
      css: {
        color: '$metal400',
        backgroundColor: 'transparent',
        '&:hover:not([disabled])': {
          backgroundColor: '$metal50',
        },
      },
    },
    {
      color: 'darkMetal',
      variant: 'ghost',
      css: {
        color: '$metal',
        backgroundColor: 'transparent',
        '&:hover:not([disabled])': {
          backgroundColor: '$metal50',
        },
      },
    },
    // {
    //   color: "metal200",
    //   outlined: true,
    //   css: {
    //     color: "metal200",
    //     borderColor: "lightindigo",
    //     "&:hover": {
    //       color: "black",
    //     },
    //   },
    // },
  ],
  defaultVariants: {
    size: 'md',
    color: 'metal',
    variant: 'filled',
    pill: true,
  },
});

// ({
//   variant = "filled",
//   colour = "dark",
//   sx,
//   padding = "0.75rem 1rem",
//   margin = "0.5rem",
//   children,
// }: {
//   variant?: "filled" | "outlined";
//   colour?: "dark" | "metal200" | "red" | "green";
//   sx?: CSSObject;
//   padding?: string | number;
//   margin?: string | number;
//   children: ReactNode;
//   [x: string]: unknown;
// }) => {
//   return (
//     <button css={[buttonStyles(variant, colour, padding, margin), sx]}>
//       {children}
//     </button>
//   );
// };

// export default Button;

// const buttonStyles = (
//   variant: string,
//   colour: string,
//   padding: string | number,
//   margin: string | number
// ) =>
//   css({
//     fontFamily: "inherit",
//     border: "none",
//     borderRadius: 9999,
//     padding,
//     margin,
//     color: "white",
//     backgroundColor: colours[colour],
//     transition: "all 0.2s linear",
//     fontWeight: 600,
//     minWidth: "1rem",
//     cursor: "pointer",
//     "&:hover": {
//       backgroundColor: variant === "filled" ? colours[colour] : "#FFFFFF",
//       filter: variant === "filled" ? "brightness(85%)" : "#FFFFFF",
//     },
//     "&:disabled": {
//       backgroundColor: "#FFFFFF",
//       color: "rgba(0, 0, 0, 0.40)",
//       border: "1px solid #d9d9d9",
//     },
//   });

export type ButtonProps = ComponentProps<typeof ButtonBase> &
  VariantProps<typeof ButtonBase> & {
    children: React.ReactNode;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
    isLoading?: boolean;
    loadingText?: string;
    css?: CSS;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      startIcon,
      endIcon,
      disabled,
      isLoading,
      loadingText,
      css,
      key,
      ...otherProps
    },
    ref,
  ) => (
    <ButtonBase
      ref={ref}
      css={css}
      disabled={disabled || isLoading}
      data-cy={`button-${key || children}`}
      {...otherProps}
    >
      {isLoading ? (
        <Spinner color="gray" size="2" css={{ mr: '$3' }} />
      ) : (
        startIcon
      )}
      <span
        style={{
          marginRight: endIcon ? '0.5rem' : 0,
          marginLeft: startIcon ? '0.5rem' : 0,
        }}
      >
        {isLoading ? loadingText ?? 'Loading...' : children}
      </span>
      {isLoading ? null : endIcon}
    </ButtonBase>
  ),
);
