import { format, parseISO } from 'date-fns';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { SessionEvent } from '../../../shared/constants/events/SessionDetail';
import { useGetSessionData } from '../../../utilities/hooks/queryHooks/session/UseGetSessionData';
import useTracking from '../../../utilities/hooks/UseTracking';
import Container from '../../components/container/Container';
import LoadingView from '../../components/loadingView/LoadingView';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Tooltip,
  Typography,
} from '../../styledComponents';
import CallerDetails from './CallerDetails';
import CallLogDetails from './CallLogDetails';
import Comments from './Comments';
import RiskAssessmentDetails from './RiskAssessmentDetails';

const SessionDetail = () => {
  const { sessionId } = useParams();
  const { track } = useTracking<SessionEvent>();
  const onSessionDataFetchSuccess = () => {
    track('view_detail', {
      eventAction: 'click',
      eventCategory: 'view_detail',
      eventLabel: 'view_detail',
    });
  };

  const { data: sessionData, isSuccess: sessionDataSuccess } =
    useGetSessionData(sessionId, true, onSessionDataFetchSuccess);

  if (!sessionDataSuccess) {
    return <LoadingView />;
  }
  const startTime = parseISO(sessionData.startTime);
  const endTime = sessionData.endTime && parseISO(sessionData.endTime);

  return (
    <Container>
      <Box
        css={{
          br: '$2',
          backgroundColor: '$gray50',
          p: '$5',
          border: '1px solid $gray200',
        }}
      >
        <Heading size="lg" css={{ fontWeight: '600' }}>
          {sessionData.client.name ?? 'Anonymous'}
        </Heading>
        <Flex align="center" gap="1" css={{ mt: '$2', mb: '$5' }}>
          <Typography size="sm">
            Session on {format(startTime, 'dd MMM yy')} &#9679;{' '}
            {format(startTime, 'h:mm aaa')} to{' '}
            {endTime ? format(endTime, 'h:mm aaa') : 'N/A'}
          </Typography>
          {!endTime && (
            <Tooltip label="This session was not completed properly.">
              <IconButton size="sm" css={{ color: '$darkRed' }}>
                <IoInformationCircleOutline />
              </IconButton>
            </Tooltip>
          )}
        </Flex>

        <CallerDetails sessionData={sessionData} />
        <RiskAssessmentDetails sessionData={sessionData} />
        <CallLogDetails sessionData={sessionData} />
      </Box>
      <Comments
        showCommentButton={
          !(sessionData.sessionType === 1 && sessionData.status === '0')
        }
      />
    </Container>
  );
};

export default SessionDetail;
