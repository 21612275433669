import { range } from '../../utilities/common/Array';

export const SessionMaxDurationBeforeWarning: number = 50; // In minutes

export const CaseNoteMinimumCharacters = 30;

export const AgeOptions = [
  { label: 'Did not disclose', value: 'NA' },

  // Generate options for an age range from 12-64
  ...range(53, 12).map((val) => ({ label: String(val), value: String(val) })),

  { label: '65+', value: '66' },
];

export const TenureOptions = [
  { label: 'Did not disclose', value: 'no-disclose' },
  { label: 'Under 1 year', value: '<1' },
  { label: '1-2 years', value: '1-2' },
  { label: '2-3 years', value: '2-3' },
  { label: '3-5 years', value: '3-5' },
  { label: '5+ years', value: '>=5' },
  { label: 'N/A', value: 'N/A' },
];

export const NoOrganisationCode = -1;

export const OrgMessageStatusCodes = [
  'DIFFERENT_ORG',
  'SELECTED_ORG',
  'NO_ORG',
] as const;

export const OrgMessageStatuses = {
  SELECTED_ORG: 'User Found in Selected Organisation',
  DIFFERENT_ORG: 'User Found in Different Organisation',
  NO_ORG: 'No User Found in Selected Organisation',
} satisfies Record<(typeof OrgMessageStatusCodes)[number], string>;

export const SessionTypes = {
  Responder: 0,
  CareNav: 1,
} as const;
