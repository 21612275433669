import {
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  addMinutes,
  isBefore,
  formatISO,
  format,
} from 'date-fns';
import { ClientTimeSlotOption } from '../../shared/types/Session';

export const generateTimeSlots = (
  start: number | Date,
  end: number | Date,
  interval: number,
): ClientTimeSlotOption[] => {
  const setTime = (x: Date, h = 0, m = 0, s = 0, ms = 0): Date =>
    setHours(setMinutes(setSeconds(setMilliseconds(x, ms), s), m), h);

  const from = typeof start === 'number' ? setTime(new Date(), start) : start;
  const to = typeof end === 'number' ? setTime(new Date(), end) : end;
  const step = (x: Date): Date => addMinutes(x, interval);

  const blocks: ClientTimeSlotOption[] = [];

  let cursor = from;

  while (isBefore(cursor, to)) {
    blocks.push({
      title: format(cursor, "hh:mm aaaaa'm'"),
      time: formatISO(cursor),
    });
    cursor = step(cursor);
  }
  return blocks;
};

export const zeroPadTime = (num?: number) => String(num).padStart(2, '0');
