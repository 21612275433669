import { encodeWithBase64 } from './Encryption';
import ApiConfig from '../../config/ApiConfig';

export const redirectToMaverick = (
  body: Partial<
    Record<
      'id' | 'email' | 'sessionId' | 'phoneNumber' | 'name' | 'location',
      string
    >
  >,
) => {
  const encodedData = encodeWithBase64(JSON.stringify(body));
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: `${ApiConfig.base.maverick}/responder-login?user=${encodeURI(
      encodedData,
    )}`,
  }).click();
};
