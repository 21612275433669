import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { isProduction } from './Path';

const APP_DSN =
  'https://5b113f532f4a4877946078339f8e795b@o318413.ingest.sentry.io/4504479080710144';

export const initializeMonitoring = () => {
  if (isProduction()) {
    Sentry.init({
      dsn: APP_DSN,
      integrations: [
        Sentry.replayIntegration({
          maskAllText: true,
          maskAllInputs: true,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }
};
