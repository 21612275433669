import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CaseNoteFormEvent } from '../../../../shared/constants/events/CaseNoteForm';
import {
  CaseNoteMinimumCharacters,
  NoOrganisationCode,
} from '../../../../shared/constants/Session';
import { AssessmentPayload } from '../../../../shared/types/Session';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';
import useTracking from '../../../../utilities/hooks/UseTracking';
import { Button, Flex } from '../../../styledComponents';
import CancellationModal from './CancellationModal';
import ConfirmationModal from './ConfirmationModal';
import { RiskLevels } from '../../../../shared/constants/Risk';

const FormConfirmation = () => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<CaseNoteFormTypes>();

  const { track } = useTracking<CaseNoteFormEvent>();

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showCancellationModal, setShowCancellationModal] =
    useState<boolean>(false);

  const compulsoryValuesFilled =
    watch(['phone', 'issue', 'caseNotes']).every(Boolean) &&
    watch('caseNotes').length >= CaseNoteMinimumCharacters;
  // THT-2604
  // &&
  // // Require tenure and age only for new users
  // (watch('id') ? true : watch(['tenure', 'age']).every(Boolean));

  const clientOptionalValuesFilledOrAnonymous =
    (!!watch('name') &&
      ['phone', 'email', 'name'].every((val) => val in errors === false) &&
      // Ignore age errors if new user
      (watch('id') ? true : !!watch('age'))) ||
    watch('clientAnonymous');

  const orgOptionalValuesFilledOrAnonymous =
    (watch(['organisation', 'location']).every(Boolean) &&
      ['organisation', 'location'].every((val) => val in errors === false) &&
      // Ignore tenure errors if new user
      (watch('id') ? true : !!watch('tenure'))) ||
    watch('orgAnonymous');

  const areRiskAssessmentMeetingConditionsMet = () => {
    if (watch('organisation') === NoOrganisationCode) {
      // If no organisation
      return true;
    }

    return (
      watch('meetingData') ||
      watch('doesNotWantToBookSession') ||
      watch('isDependent') ||
      watch('clientAnonymous') ||
      watch('orgAnonymous')
    );
  };

  const areRiskAssessmentQuestionConditionsMet = () =>
    watch('assessment')
      .filter((answer: AssessmentPayload) => answer.isRequired)
      .every((answer) => answer.response);

  const getIsRiskAssessmentFilled = () => {
    /* If Low, Medium or Other Risk, Either meetingData should exist or the 
    'Client does not want to have a coaching session' or 'anonymous' checkboxes must be checked */

    if (
      [RiskLevels.Low, RiskLevels.Medium, RiskLevels.Other].some(
        (r) => r === watch('risk'),
      )
    ) {
      return areRiskAssessmentMeetingConditionsMet();
    }
    if (watch('risk') === RiskLevels.HighCritical) {
      /** If High Critical Risk, check if all required checkboxes are checked. */

      return areRiskAssessmentQuestionConditionsMet();
    }

    if (watch('risk') === RiskLevels.High) {
      /** If High (Non critical) risk, ensure BOTH the above conditions are true. */

      return (
        areRiskAssessmentMeetingConditionsMet() &&
        areRiskAssessmentQuestionConditionsMet()
      );
    }

    return false;
  };

  const confirmButtonEnabled =
    compulsoryValuesFilled &&
    clientOptionalValuesFilledOrAnonymous &&
    orgOptionalValuesFilledOrAnonymous &&
    getIsRiskAssessmentFilled();

  const handleOpenConfirmationModal = () => {
    setValue('endTime', new Date());
    track('mark_complete', {
      eventAction: 'click',
      eventCategory: 'mark_complete',
      eventLabel: 'mark_complete',
    });
    setShowConfirmationModal(true);
  };

  const handleOpenCancellationModal = () => {
    track('cancel_session', {
      eventAction: 'click',
      eventCategory: 'cancel_session',
      eventLabel: 'cancel_session',
    });

    setShowCancellationModal(true);
  };

  return (
    <>
      <Flex
        direction="column"
        align="center"
        css={{
          width: '100%',
          mt: '5rem',
          backgroundColor: 'white',
        }}
        gap="3"
      >
        <Button
          id="show-confirmation-modal"
          size="lg"
          css={{ width: '40rem' }}
          onClick={handleOpenConfirmationModal}
          disabled={!confirmButtonEnabled}
        >
          Mark as complete
        </Button>
        <Button
          id="show-cancellation-modal"
          size="lg"
          variant="ghost"
          css={{ width: '40rem' }}
          disabled={!watch('phone')}
          onClick={handleOpenCancellationModal}
        >
          Cancel session
        </Button>
      </Flex>
      <CancellationModal
        open={showCancellationModal}
        handleClose={() => setShowCancellationModal(false)}
      />
      <ConfirmationModal
        open={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
};

export default FormConfirmation;
