import { AxiosError, AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';
import { redirect } from 'react-router-dom';

import { removeItemFromLocalStorage } from '../common/Storage';
import { StorageItems } from '../../shared/constants/App';

export const onResponseFulfilled = (response: AxiosRequestConfig) => {
  console.log('%c Response: ', 'background: #0f0; color: #fff', response);

  return response;
};

export const onResponseRejected = (error: AxiosError) => {
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    console.log('%c Error: ', 'background: #f00; color: #fff', error.response);
    if (error.response.status === 403) {
      removeItemFromLocalStorage(StorageItems.UserInfo);
      redirect('/login');
      toast.error('Your session has expired. Please login again.');
    }
  }
  console.error(
    '%c Error: ',
    'background: #f00; color: #fff',
    error.message,
    error.response,
  );
  return Promise.reject(error.response);
};
