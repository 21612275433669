import { useFormContext } from 'react-hook-form';
import { IoSyncSharp } from 'react-icons/io5';
import toast from 'react-hot-toast';
import { CaseNoteSession } from '../../../../../shared/types/Session';
import { CaseNoteFormTypes } from '../../../../../shared/types/CaseNoteForm';
import {
  Flex,
  Heading,
  Typography,
  Box,
  Button,
} from '../../../../styledComponents';
import { useGetSessionData } from '../../../../../utilities/hooks/queryHooks/session/UseGetSessionData';
import {
  RiskContentData,
  RiskLevels,
} from '../../../../../shared/constants/Risk';
import { ECPViewer } from './emergencyContacts/ECPViewer';
import { AssessmentQuestionViewer } from './AssessmentQuestionViewer';
import { MeetingsList } from './bookASession/MeetingsList';
import { BookASessionSection } from './bookASession/BookASessionSection';

const RiskLevel = ({
  startMeetingCancel,
}: {
  startMeetingCancel: (meetingId: string) => void;
}) => {
  const {
    setValue,
    watch,
  } = useFormContext<CaseNoteFormTypes>();
  const meetingData = watch('meetingData');

  const riskId = watch('risk');

  const onMeetingFetchSuccess = (session: CaseNoteSession) => {
    if (session.meetingType) {
      setValue('meetingData', {
        type: session.meetingType,
        ...(session.meeting && { data: session.meeting }),
      });
    } else {
      toast.error(
        'No meeting has been booked yet. Please schedule a meeting and try again.',
      );
    }
  };
  const { refetch: refetchSyncData, isInitialLoading: isSyncLoading } =
    useGetSessionData(watch('sessionId'), false, onMeetingFetchSuccess);

  return (
    <Box
      css={{
        border: '1px solid $gray300',
        borderRadius: '$3',
        backgroundColor: 'white',
        padding: '$5',
        marginTop: '$5',
      }}
    >
      <Flex justify="between" align="start" css={{ mb: '$1' }}>
        <Box>
          <Heading size="md" css={{ fontWeight: 'bold', mb: '$1' }}>
            {RiskContentData[riskId].title}
          </Heading>
          <Typography size="lg" color="gray500">
            Complete the following action
          </Typography>
        </Box>

        {!meetingData && watch('sessionId') && (
          <Button
            id={`${RiskContentData[riskId].title.replace(' ', '-')}-sync`}
            variant="ghost"
            type="button"
            onClick={() => refetchSyncData()}
            isLoading={isSyncLoading}
            disabled={!watch('userId')}
            startIcon={<IoSyncSharp />}
          >
            Sync
          </Button>
        )}
      </Flex>
      {meetingData ? (
        <MeetingsList
          meetingData={meetingData}
          startMeetingCancel={startMeetingCancel}
        />
      ) : (
        <BookASessionSection />
      )}

      {[RiskLevels.High, RiskLevels.HighCritical].some((r) => r === riskId) ? (
        <>
          <ECPViewer />
          <AssessmentQuestionViewer />
        </>
      ) : null}
    </Box>
  );
};

export default RiskLevel;
