import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import { ClientRegionsList } from '../../../../shared/types/Session';

export const useGetRegionsList = () =>
  useQuery<ClientRegionsList[]>(
    [QueryKeys.getRegionsList],
    () =>
      doGet(ApiConfig.api.clients.getClientRegions).then((res) => [
        {
          region: 'Did not disclose',
          translation: 'Did not disclose',
          countryCode: 'NA',
          ageMin: 12,
          ageMax: 21,
        },
        ...res.data.data,
      ]),
    {
      onError: () => {
        toast.error('An error occurred while getting the list of locations.');
      },
      refetchOnWindowFocus: false,
    },
  );
