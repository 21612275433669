import { format } from 'date-fns';
import Container from '../../../components/container/Container';
import {
  Button,
  Flex,
  Heading,
  Link,
  Table,
  Tbody,
  Td,
  Tr,
  Typography,
} from '../../../styledComponents';
import { useGetSessionsList } from '../../../../utilities/hooks/queryHooks/session/UseGetSessionsList';
import { SessionListItem } from '../../../../shared/types/Session';
import LoadingView from '../../../components/loadingView/LoadingView';
import { useGetOrganisationsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { PathConfig } from '../../../../config/PathConfig';

const CareNavOverview = () => {
  const { isSuccess, data: sessionsList } = useGetSessionsList('1');
  const { data: organisationsList } = useGetOrganisationsList();
  return (
    <Container>
      <Flex
        align="center"
        direction="column"
        css={{ height: '20vh', width: '100%' }}
      >
        <Link to={`${PathConfig.careNavigator}/${PathConfig.newCaseNote}`}>
          <Button id="start-session" size="lg">
            Add note
          </Button>
        </Link>
      </Flex>
      <Heading size="xl" css={{ fontWeight: '600', mb: '$5' }}>
        Previous notes
      </Heading>

      <Table>
        <Tbody>
          {isSuccess ? (
            sessionsList.map((item: SessionListItem) => (
              <Tr key={item.id}>
                <Td>
                  {item.client?.name ?? 'Anonymous'}
                  {organisationsList && (
                    <Typography css={{ mt: '$1' }} size="xs" color="gray600">
                      {organisationsList.find(
                        (org) =>
                          org.id ===
                          parseInt(item.client?.organisation || '-1', 10),
                      )?.name ?? 'Organisation unavailable'}
                    </Typography>
                  )}
                </Td>
                <Td>{format(new Date(item.startTime), 'MMM dd, yyyy')}</Td>
                <Td>{item.responderName}</Td>
                <Td align="end">
                  <Link to={`/${PathConfig.callLogs}/${item.id}`}>
                    <Button
                      id={`view-session-${item.id}`}
                      key={item.id}
                      variant="outlined"
                      size="md"
                      color="darkMetal"
                    >
                      {item.status === '0' ? 'Edit' : 'View'}
                    </Button>
                  </Link>
                </Td>
              </Tr>
            ))
          ) : (
            <LoadingView />
          )}
        </Tbody>
      </Table>
    </Container>
  );
};

export default CareNavOverview;
